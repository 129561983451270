import apiCallingInit from '~/httpApi/init';

import * as queryString from 'query-string';
import { CertificationType, StatusType, UsersDetail } from '~/types';
// import { UserDetailResponse } from './getAgency';

// import parseISO from 'date-fns/parseISO';

const apiClientInstance = apiCallingInit();

// type UsersDetailResponse = Pick<
//   UserDetailResponse,
//   'id' | 'name' | 'contactName' | 'contactPhone' | 'registeredDate' | 'enterpriseStatus' | 'status'
// >;

type ListAgenciesInput = {
  token: string;
  keyword?: string;
  status?: StatusType;
  verificationStatus?: CertificationType;
  page: number;
  size?: number;
  area?: string;
};

const PAGE_SIZE = 10; //parseInt(process.env.REACT_APP_AGENCY_LIST_SIZE || "");
if (isNaN(PAGE_SIZE)) {
  throw new Error("REACT_APP_AGENCY_LIST_SIZE in environment variable does't exist");
}

const listAgencies = async ({
  token,
  keyword,
  status,
  verificationStatus,
  page,
  area,
  size,
}: ListAgenciesInput): Promise<{
  agencies: UsersDetail[];
  agenciesCount: number;
}> => {
  const queryParameters = queryString.stringify(
    Object.fromEntries(
      Object.entries({
        keyword,
        status,
        area,
        verificationStatus,
        page: page,
        size: size || PAGE_SIZE || 10,
      }).filter(([_, value]) => !!value),
    ),
  );

  try {
    const listAgenciesResponse = await apiClientInstance.get<{
      agenciesCount: { count: string };
      agencies: UsersDetail[];
    }>(`/agency?${queryParameters}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { agencies, agenciesCount } = listAgenciesResponse.data;

    return {
      agencies: [...agencies],
      agenciesCount: Number(agenciesCount.count) || 0,
    };
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return {
      agenciesCount: 0,
      agencies: [],
    };
  }
};

export default listAgencies;
