import { Box, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { DetailBox } from '.';

const HotelDetailSkeleton = () => {
  return (
    <>
      <DetailBox>
        <Stack w="full" alignItems="left">
          <Skeleton w="50%" height="5" />
          <Skeleton height="8" />
          <Skeleton height="8" />
          <Skeleton height="8" />
        </Stack>
      </DetailBox>
      <HStack spacing={4} alignItems="flex-start">
        <Box flex="5">
          <DetailBox>
            <Stack w="full" alignItems="left">
              <Skeleton w="50%" height="5" />
              <Skeleton height="8" />
              <Skeleton height="8" />
            </Stack>
          </DetailBox>
          <DetailBox>
            <Stack w="full" alignItems="left">
              <Skeleton w="50%" height="5" />
              <Skeleton height="8" />
              <Skeleton height="8" />
            </Stack>
          </DetailBox>
          <DetailBox>
            <Stack w="full" alignItems="left">
              <Skeleton w="50%" height="5" />
              <Skeleton height="8" />
              <Skeleton height="8" />
            </Stack>
          </DetailBox>
        </Box>

        <Box flex="2">
          <DetailBox>
            <Stack w="full" alignItems="left">
              <Skeleton height="8" />
              <Skeleton height="8" />
              <Skeleton height="8" />
            </Stack>
          </DetailBox>
        </Box>
      </HStack>
    </>
  );
};
export default HotelDetailSkeleton;
