import { ComponentStyleConfig } from '@chakra-ui/react';

const Table: ComponentStyleConfig = {
  baseStyle: {
    thead: {
      height: '64px',
    },
    th: {
      height: '64px',
      color: '#859EB9',
      backgroundColor: 'rgba(255, 255, 255, 0.5);',
      fontWeight: 400,
    },
  },
  sizes: {
    md: {
      td: {
        fontSize: 'sm',
      },
    },
  },
  variants: {
    simple: {
      th: {
        borderBottomColor: '#859EB9',
        color: '#859EB9',
        padding: '22px 0 22px 35px',
      },
      tbody: {
        border: '1px solid #859EB9',
        borderBottomColor: '#859EB9',
        '> tr': {
          border: '1px solid #859EB9',
          _hover: {
            '> td': {
              background: '#ebeef5',
            },
          },
        },
        '> tr > td': {
          color: '#002C5E',
          padding: '26px 0 26px 35px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #859EB9',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  },
};

export default Table;
