import { Box, Text } from '@chakra-ui/react';

export const DetailBox = (props: { children: React.ReactNode }) => {
  return (
    <Box bgColor="white" borderRadius="base" boxShadow="base" w="full" px="10" py="4" mb="4">
      {props.children}
    </Box>
  );
};

export const DetailBoxHeader = (props: { children: React.ReactNode }) => {
  return (
    <Box borderBottomWidth="1px" pb="4" borderColor="fill.gray-1">
      {props.children}
    </Box>
  );
};

export const ItemLabel = (props: { text: string }) => {
  return (
    <Text fontSize="sm" color="fill.gray-6" fontWeight="normal">
      {props.text}
    </Text>
  );
};

export const ItemTextValue = (props: { text?: string }) => {
  return (
    <Text fontSize="sm" color="text.blue" fontWeight="normal">
      {props.text || '-'}
    </Text>
  );
};
