import React from 'react';
import SideMenu from '../Menu';
import './index.scss';
const Side = () => {
  return <div className='side'>
    <div className='logo'></div>
    <SideMenu></SideMenu>
  </div>;
};
export default Side
