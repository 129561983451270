export enum Lang {
  zh_CN = 'zh-CN',
  en_US = 'en-US',
}

//User Type

export enum CertificationType {
  UNVERIFIED = 'unverified',
  VERIFYING = 'verifying',
  VERIFIED = 'verified',
}
// DONE = '已认证',
// PROCESS = '认证中',
// UNCOMPLETED = '未认证',

export enum StatusType {
  INACTIVE = 'inactived',
  ACTIVATED = 'actived', 
  LOCKED = 'locked',
}

export type User = {
  id: string;
  name: string;
  contactInfo: {
    name: string;
    phone: string;
  };
  createdAt: Date;
  verificationStatus: CertificationType;
  status: StatusType;
};

export type UserDetail = {
  id: string; // 商户Id
  status: StatusType; // 商户状态，未激活，已激活，锁定
  name: string; // 商户名字
  registeredDate?: Date | null; // 注册日期
  latestLoginTime?: Date | null; //上次登录时间

  enterpriseInfo: Enterprise;

  enterpriseUserNum?: string; // 企业用户数量

  contactName: string; // 联系人姓名
  contactPhone: string; // 联系人电话号码
  contactCountryCode?: string; // 联系人电话国码
  enterprisePassword?: string; // 企业密码

  orderAccount?: number; // 订单总金额
  orderAmount?: number; // 订单数量
  hotelAccount?: number; // 酒店订单总金额
  hotelAmount?: number; // 酒店订单总数量
  tourAccount?: number; // 当地玩乐所有订单总金额
  tourAmount?: number; // 当地玩乐所有订单总数量
};

export type UsersDetail = {
  id: string;
  name: string;
  contactName: string;
  contactPhone: string;
  registeredDate: string;
  enterpriseStatus?: CertificationType | undefined;
  status: StatusType;
  area: string;
};

export type ImageInfo = {
  assetId: string;
  fileName: string;
  url: string;
};

export type LicenseFile = {
  assetId: string;
  fileName: string;
  url: string;
};

export type Enterprise = {
  enterpriseName?: string; // 企业名字
  enterpriseCity?: string; // 企业所在城市
  enterpriseAddress?: string; // 企业地址
  enterpriseUniqueId?: string; // 企业统一证号
  enterpriseLegalPerson?: string; // 法人
  enterpriseLicense?: LicenseFile | null; // 营业执照
  enterprisePermit?: LicenseFile | null; // 经营许可
  enterpriseLiability?: LicenseFile | null; // 旅行社责任险
  enterpriseOtherDocument?: LicenseFile[];
  enterpriseStatus?: CertificationType; // 企业认证状态，未认证，认证中，已认证
  enterpriseCertificatedDate?: Date; // 认证日期
};

export type BusinessInfo = {
  id: string;
  address: string;
  tax: string;
  contact: string;
  phoneNumber: string;
  date: string;
  users: number;
};

export type BusinessCertification = {
  date: string;
  certification: string;
  license: string;
  trip: string;
  else: string;
};

export type OrderTotal = {
  type: string;
  total: number;
  totalPrice: number;
  hotelTotal: number;
  hotelTotalPrice: number;
  localTotal: number;
  localTotalPrice: number;
};

//Order Type
export enum TimeFilter {
  CREATED_AT = 'CREATED_AT',
  CANCELLING_AT = 'CANCELLING_AT',
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
}

// TMIBookingStatus
export enum OrderStatusType {
  PENDING = 'PENDING', // 用户提交订单后，尚未付款
  PROCESSING = 'PROCESSING', // 用户付款后，内部处理中
  IN_BOOKING = 'IN_BOOKING', // 将订单提交给供应商，等待供应商确认订单
  SUCCESS = 'SUCCESS', // 供应商确定订单并接受
  FAILED = 'FAILED', // 供应商拒绝订单
  CANCELLED = 'CANCELLED', // 订单已经成功取消
  CANCELLING = 'CANCELLING', // 订单发起取消，待确认中
  INVALID = 'INVALID', // 用户指定时间内未付款，订单失效
  USED = 'USED', // 订单用户已使用
}

export enum OrderPaymentStatusType {
  UNPAID = 'unpaid',
  PAID = 'paid',
  REFUNDING = 'refunding',
  REFUNDED = 'refunded',
}

// 订单支付方式
export enum OrderPaymentType {
  WECHAT = 'Wechat', // 微信支付
  ALIPAY = 'AliPay', // 支付宝
  CREDITCARD = 'CreditCard', // 信用卡
  BALANCE = 'Balance', // 余额
  OTA = 'OTA',
}

export enum SupplierPaymentType {
  PENDING = 'pending',
  PAID = 'paid',
  NO_NEED = 'no need',
  ALL_REFUNDED = 'all refunded',
  PARTIALLY_REFUNDED = 'partial refunded',
}

export type OrderListItem = {
  id: string; // bookingId,
  hotelname: string;
  guestnames: string; // 过个名字使用“|”进行分割
  createdtime: string;
  checkindate: string;
  checkoutdate: string;
  status: OrderStatusType;
  paymentstatus: SupplierPaymentType;
  suppliercode: SupplierType;
};

export enum PaymentType {
  WECHAT_PAY = 'WECHAT_PAY',
}

export type NameInBooking = {
  lastName: string;
  firstName: string;
};

export type BookingPayment = {
  createdAt?: string;
  paidAt?: string;
  refundAt?: string;
  total?: number;
  isSuccessful: boolean;
  isRefunded?: boolean;
  paymentAppUrlMap: Record<PaymentType, string>;
  transactionId?: string;
  paymentType?: PaymentType;
};

export type RateInBooking = {
  bed: string;
  dailyPrice: number[];
  fees: number;
  sessionCode: string;
  occupancy: number;
  range: string;
  hasBreakfast: boolean;
  rateId: string;
  roomId?: string;
  roomNum: number;
  supplierHotelId: string;
  price: number;
  cancelPolicies: Array<{
    currency: Currency;
    endAt: string;
    percent: number;
    refund: number;
  }>;
  supplier: SupplierType;
  currency: Currency;
  dailyInventory: number[];
  group: {
    adultCount?: number;
    childCount?: number;
    maxOccupancy?: number;
    inventoryCount?: number;
    children?: number[];
    [key: string]: any;
  };
  supplierRateId: string;
  mealCount: number[];
  hotelId: string;
  roomName: Record<Lang, string>;
  meal: string;
  extend: string;
  checkIn: string;
  roomDisplayName: string;
  checkOut: string;
  feesCurrency: Currency;
};

export type RoomInfo = {
  seq?: number;
  adultCount?: number;
  childrenAges?: number[];
  guests: {
    name: {
      firstName: string;
      lastName: string;
    };
    nationality?: string;
    gender?: string;
  }[];
};

export type OrderDetail = {
  id: string; // 订单id
  channel: string; // 订单来源， 如b2b
  status: OrderStatusType | string; // 订单状态
  createdtime?: string; // 订单创建时间
  paymenttime?: string; // 订单支付时间
  paymentstatus: OrderPaymentStatusType | string; // 订单支付状态
  paymenttype: OrderPaymentType; // 支付方式
  remark?: string; // 内部备注
  times: Array<{
    [x: string]: string;
  }>;

  hotelname: string;
  hoteladdress: string;
  hotelroomtype: string;
  hotelbedtype: string;
  checkindate: string;
  checkoutdate: string;
  roomnum: number; // 房间数量

  breakfasts: string; // 早餐数量，每天用"|"进行分割
  cancelpolicy: string; // 退房政策
  specificrequest?: string; //特殊需求
  guestnames: string; // 每个房间入住人姓名，房间用"|"进行分割，fisrtname/lastname
  guestnationalities: string; // 国籍使用“|”进行分隔
  contactname: string; // fisrtname/lastname
  contactphone: string;
  contactemail: string;

  supplierName: string;
  suppliercode: string;
  supplierorderid?: string;
  supplierrateid: string;
  supplierpaymentdate?: string; // 供应商预计结算日期
  supplierpaymentstatus: SupplierPaymentType | string; // 供应商结算状态
  suppliercurrency: string; // 供应商货币
  supplierdaliyprice: string; // 供应商每天房间的单价,以“|”进行分割
  suppliertotalprice: string; // 供应商所有天数roomnum间房间价格（扣除优惠金额）

  agencyid: string; // 商户id
  agencyuserid: string; // 商户的authingUserId
  agencydailyprice: string; // 商户每日房间单价
  agencydiscount: number; // 商户优惠价格
  agencytotalprice: number; // 商户订单总金额（扣除优惠金额）
  agencysumprice: number; // 交易总额
  agencycurrency: string;
  agencyname: string;
  agencyusername: string;
  agencyuserphone: string;

  cancellingtime?: string; // 发起支付取消时间
  refundamount?: number; //退款金额

  adultcount: number; // 成人数量
  children?: string; // 儿童年龄，使用“|”分割，-1表示未知

  ordermargin: number; // 订单利润
  currencyrate: string; // 订单汇率

  log?: string; // 操作记录
  ordercomment?: string;
  ordermessage?: string;
  extend?: string;
};

export type LocalOrderDetail = {
  id: string; // 订单id 拆分#后显示订单号
  status: OrderStatusType | string; // 订单状态;
  paymentstatus: OrderPaymentStatusType | string; // 支付状态;
  paymenttype: OrderPaymentType; // 支付方式;
  cancelpolicy: string; // 取消政策;
  agencycurrency: string; // 支付货币
  productname: string; // 产品名称;
  productday: string; // 产品出行时间;
  tourcontactname: string; // 联系人名称;
  tourcontactphone: string; // 联系电话;
  skucount: number; // sku数量;
  paymentamount: string; // 实际支付金额;
  agencyname: string; // 企业名称;
  agencyid: string; // 企业ID;
  agencyuserphone: string; // 企业联系电话;
  suppliercode: string; // 供应商码; 供应商名称根据供应商编码匹配显示;
  supplierorderid: string; // 供应商单号;
  createdtime: string; // 预订提交时间;
  paymenttime: string; // 支付时间;
  checkindate: string; // 实际出行时间;
  completerefundtime: string; // 完成退款时间;
  refundamount: number; // 退款金额;
  remark: string; // 评论;
  programname: string; // 方案名称;
  travelinfo: string; // 出行信息;
  tourcontactemail: string; // 联系email;
  skuname: string; // sku名称;
  skusingleprice: string; // sku单价;
  tourcurrency: string; // 支付货币;
  tourtotaltransaction: string; // 交易总额;
  tourdiscountamount: string; // 优惠金额;
  cancellingtime: string; // 取消支付时间;

  times: Array<{
    [x: string]: string;
  }>;
};

// export type OrderDetail = OrderDetailResponse;

export enum SupplierType {
  DIDA = 'DIDA',
  RAKUTEN_JAPAN = 'RAKUTEN_JAPAN',
  RAKUTEN_CHINA = 'RAKUTEN_CHINA',
  MEITUAN = 'MEITUAN',
  CTRIP_DIST = 'CTRIP_DIST',
  SHEN_JIE_LV = 'SHEN_JIE_LV',
}

export type OrderRecord = {
  name: string;
  time: string;
  description: string;
};

//============= error handling =======\\
export enum HttpApiErrorCode {
  FORBIDDEN = 'admin forbidden',
  UNAUTHORIZED = 'admin unauthorized',
  MISSING_FIELDS = 'admin missing field',
  NO_SUCH_AGENCY = 'admin agency is not exist',
  NO_SUCH_USER = 'admin user is not exits',
  GATEWAY_ERROR = 'admin gateway error',
  UNEXPECTED_ERROR = 'admin unexpected error',
  CONFLICT = 'admin agency, user already exist',
}

export enum Currency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}
