import { Box, Text, VStack, HStack, SimpleGrid, Grid, GridItem } from '@chakra-ui/react';

import { fakeOrder } from '~/pages/fakeDate';

import SectionContainerFull from '~/components/SectionContainerFull';
import { OrderDetail } from '~/types';

type RecordInfoProps = {
  order: OrderDetail;
};

//日志纪录
export default function RecordInfo({ order: { log = '' } }: RecordInfoProps) {
  return (
    <>
      {log && (
        <SectionContainerFull title="日志纪录">
          <Box py={8}>
            <VStack spacing={2} align="stretch" p={0} borderColor="gray.500" borderWidth="1px">
              <Box w="full" bg="gray.500" py={2} px={4}>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  <GridItem colSpan={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      操作人
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      操作时间
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text fontSize="sm" fontWeight="bold">
                      操作描述
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
              <Grid templateColumns="repeat(4, 1fr)" gap={4} py={2} px={4}>
                {log}
                {/* {operationRecords.map((record) => (
                  <>
                    <GridItem colSpan={1}>
                      <Text fontSize="sm">{record.name}</Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text fontSize="sm">{record.time}</Text>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Text fontSize="sm">{record.description}</Text>
                    </GridItem>
                  </>
                ))} */}
              </Grid>
            </VStack>
          </Box>
        </SectionContainerFull>
      )}
    </>
  );
}
