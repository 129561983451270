import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import './index.scss';
const TopTitle = ({ title, subTitle }: {title: string, subTitle: string}) => {
  return <div className='topTitle'>
    <Breadcrumb separator='-'>
      <BreadcrumbItem>
        <BreadcrumbLink href='#'>{title}</BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink href='#'>{subTitle}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  </div>;
};
export default TopTitle
