import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useBoolean,
  InputGroup,
  Input,
  InputRightAddon,
  Box,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import useAuthingClient from '~/hooks/useAuthingClient';
import { AgencyDetail } from '~/httpApi/agency/fetchAgency';
// import patchAgency from '~/httpApi/agency/patchAgency';
import uploadImages, { ImagesCategory } from '~/httpApi/agency/uploadImages';
// import { LicenseFile } from '~/types';

interface Props {
  detail: AgencyDetail;
  onModifySuccess: () => void;
}

const UploadFile = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useBoolean();
  const [name, setName] = useState<string>('还没选择文件');
  const { getIdToken } = useAuthingClient();
  const fileRef = useRef<HTMLInputElement>(null);

  const handleFile = async (file: File | null) => {
    const { name = '', type = '' } = file || {};
    const arrayBuffer = await file?.arrayBuffer();

    return file && arrayBuffer
      ? {
          fileContent: Buffer.from(arrayBuffer).toString('base64'),
          contentType: type,
          category: ImagesCategory.CERT,
          fileName: name,
        }
      : null;
  };

  const handleClickUpload = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files?.[0]?.name) {
      setName(e.target.files?.[0]?.name);
    }
  };

  const handleClickSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading.on();
    const file = fileRef.current?.files?.[0];
    if (!file) {
      return;
    }

    const data = await handleFile(file);
    if (data === null) {
      return;
    }
    try {
      const token = await getIdToken();
      await uploadImages({
        token,
        agencyId: props.detail.id,
        images: [data],
      });
      setIsLoading.off();
      onClose();
      props.onModifySuccess();
    } catch (error) {
      setIsLoading.off();
    }
  };

  return (
    <>
      <Button
        color="fill.blue-6"
        fontSize="sm"
        fontWeight="normal"
        variant="ghost"
        size="sm"
        px="3"
        onClick={onOpen}
      >
        上传认证
      </Button>

      <Modal size="sm" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
            上传认证
          </ModalHeader>
          <ModalBody py="0">
            <InputGroup>
              <Input
                hidden
                ref={fileRef}
                type="file"
                accept="image/png,image/jpg,image/jpeg,.pdf"
                onChange={handleFileChange}
              />
              <Box
                w="full"
                py="2"
                px="4"
                fontSize="sm"
                h="10"
                borderWidth="1px"
                borderLeftRadius="6px"
                color="text.blue"
                onClick={handleClickUpload}
              >
                {name}
              </Box>
              <InputRightAddon
                cursor="pointer"
                onClick={handleClickUpload}
                children={<Text color="black">上传</Text>}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter py="8" justifyContent="center">
            <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
              取消
            </Button>
            <Button
              isLoading={isLoading}
              variant="solid"
              mx="2"
              px="4"
              py="2.5"
              onClick={handleClickSubmit}
            >
              保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadFile;
