import { AxiosError } from 'axios';

import apiCallingInit from '~/httpApi/init';
import { HttpApiErrorCode } from '~/types';

const apiClientInstance = apiCallingInit();

export default async function memberLogout(input: {
  accessToken: string;
  refreshToken: string;
}): Promise<{
  isLoggedOut: boolean;
  errorCode?: HttpApiErrorCode;
}> {
  const { accessToken, refreshToken } = input;

  try {
    await apiClientInstance.post<{
      accessToken: string;
      refreshToken: string;
    }>(
      `/auth/logout`,
      {
        refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        isLoggedOut: false,
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      isLoggedOut: false,
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return { isLoggedOut: true };
}
