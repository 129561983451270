const payStatus = [
  {
    label: '全部支付状态',
    value: '',
  },
  {
    label: '未付款',
    value: 'unpaid',
  },
  {
    label: '已付款',
    value: 'paid',
  },
  {
    label: '退款中',
    value: 'refunding',
  },
  {
    label: '已退款',
    value: 'refunded',
  },
];

export default payStatus;
