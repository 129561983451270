import axios, { AxiosInstance } from 'axios';

let instance: AxiosInstance | undefined;

const AxiosInit = () => {
  instance = instance
    ? instance
    : axios.create({
        // baseURL: 'https://2pduy1dquj.execute-api.cn-north-1.amazonaws.com.cn',
        baseURL: process.env.REACT_APP_ADMIN_DASHBOARD_HTTP_API_BASE_URL,
        timeout: 15 * 1000,
      });

  return instance;
};

export default AxiosInit;
