import { DetailBox, DetailBoxHeader } from '.';
import SubTitle from './SubTitle';
import { Box, Flex, Text } from '@chakra-ui/react';
import { LocalOrderDetail } from '~/types';
import { getMoneyFlg } from '~/utils/helper';

const BoxOrderSKU = ({ orderDetail }: { orderDetail: LocalOrderDetail }) => {
  const {
    skusingleprice,
    skucount,
    paymentamount,
    agencycurrency = 'CNY',
    skuname,
    tourdiscountamount,
    tourtotaltransaction,
  } = orderDetail;

  const moneyFlag = getMoneyFlg(agencycurrency);
  const price = +skusingleprice || 0;

  return (
    <DetailBox>
      <DetailBoxHeader>
        <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
          订单明细
        </Text>
      </DetailBoxHeader>
      <SubTitle title="SKU" />
      <Box borderBottomWidth="1px">
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Text fontSize="sm" flex="1">
            {skuname}
          </Text>
          <Flex flex="1" alignItems="center">
            <Text fontSize="sm" flex="auto">
              {skusingleprice || '-'} × {skucount}
            </Text>
            <Text fontSize="sm">{price * skucount}</Text>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              支付货币
            </Text>
            <Text color="text.blue" fontSize="sm">
              {agencycurrency}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              交易总额
            </Text>
            <Text color="text.blue" fontSize="sm">
              {tourtotaltransaction ? `${moneyFlag}${tourtotaltransaction}` : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              优惠金额
            </Text>
            <Text color="text.blue" fontSize="sm">
              {tourdiscountamount ? `${moneyFlag}${tourdiscountamount}` : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="md" fontWeight="medium" flex="auto">
              实际支付金额
            </Text>
            <Text color="text.blue" fontSize="lg" fontWeight="medium">
              {paymentamount ? `${moneyFlag}${paymentamount}` : '-'}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </DetailBox>
  );
};

export default BoxOrderSKU;
