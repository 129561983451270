import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const CancelConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  amount,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  amount: number;
}) => {
  return (
    <Modal size="md" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
          退款金额
        </ModalHeader>
        <ModalBody py="0">
          <Text textAlign="center" fontSize="sm">
            输入的退款金额是 <chakra.span color="fill.blue-6">{amount}</chakra.span>
          </Text>
          <Text textAlign="center" fontSize="sm">
            确认完成后将不得更改，请谨慎操作！
          </Text>
        </ModalBody>

        <ModalFooter py="8" justifyContent="center">
          <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
            返回上一步
          </Button>
          <Button variant="solid" mx="2" px="4" py="2.5" onClick={onConfirm}>
            下一步
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelConfirmModal;
