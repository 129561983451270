import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react';
import TopTitle from '~/components/TopTitle';
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from '@chakra-ui/icons';
import moment from 'moment';
import DateRangePicker from '~/components/DateRangePicker';
import { useController, useForm } from 'react-hook-form';
import Select from '~/components/Select';
import Badge from '~/components/Badge';
import Pagination from '~/components/Pagination';
import { ISortTypeEnum, SortTypeEnum, useTableData } from '../hooks';
import formatPriceNum from '~/utils/formatPriceNum';
import { Currency, Lang } from '~/types';
import { useState } from 'react';
import orderStatusMap, { OrderStatusType } from '~/constants/orderStatus';
import payStatusList from '~/constants/payStatus';
import paymentTypeList from '~/constants/paymentType';
import supplierList from '~/constants/supplier';
import { useNavigate } from 'react-router-dom';

const DateTypeEnum = [
  {
    key: '所有日期',
    value: '1',
  },
  {
    key: '预定提交日期',
    value: '2',
  },
  {
    key: '预定出行日期',
    value: '3',
  },
];

export default function Order() {
  const navigate = useNavigate();
  const [createdTimeSort, setCreatedTimeSort] = useState<
    ISortTypeEnum<'CreateTimeAsc' | 'CreatedTimeDesc'>
  >(SortTypeEnum.CreatedTimeDesc);
  const [productDaySort, setProductDaySort] = useState<
    ISortTypeEnum<'ProductDayAsc' | 'ProductDayDesc'>
  >(SortTypeEnum.ProductDayDesc);
  const [priceSort, setPriceSort] = useState<ISortTypeEnum<'PriceAsc' | 'PriceDesc'>>(
    SortTypeEnum.PriceDesc,
  );
  const { loading, tableData, total, getTableData } = useTableData();
  const orderStatusList = Object.entries(orderStatusMap).map(([key, value]) => ({
    label: value.text,
    value: key,
  }));
  const { register, handleSubmit, reset, control, getValues, setValue } = useForm();

  const { field: dateTypeField } = useController({ name: 'dateType', control, defaultValue: '1' }); // 日期类型
  const { field: dateRangeField } = useController({ name: 'dateRange', control }); // 日期时间
  const { field: statusField } = useController({ name: 'status', control }); // 订单状态
  const { field: payStatusField } = useController({ name: 'payStatus', control }); // 支付状态
  const { field: paymentTypeField } = useController({ name: 'paymentType', control }); // 支付方式
  const { field: supplierField } = useController({ name: 'supplier', control }); // 供应商
  const { field: sortField } = useController({ name: 'sort', control }); // 排序方式
  const { field: pageField } = useController({ name: 'page', control }); // 当前页码
  const { field: sizeField } = useController({ name: 'size', control }); // 分页大小

  const getTableDataByPage = (pageNum: number, pageSize: number) => {
    pageField.onChange(pageNum);
    sizeField.onChange(pageSize);
    const { dateType, dateRange, ...rest } = getValues();

    getTableData({
      page: pageNum,
      size: pageSize,
      ...rest,
      dateType: dateType === '2' ? 'Booking' : dateType === '3' ? 'CheckIn' : '',
      dateRange: dateRange && {
        min: (dateRange.startDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss'),
        max: (dateRange.endDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss'),
      },
    });
  };

  const handleDateTypeChange = (dateType: string) => {
    setValue('dateRange', null);
    dateTypeField.onChange(dateType);
  };

  const handleFormSubmit = () => getTableDataByPage(1, 10);

  const handleFormReset = () => {
    // 日期组件，使用reset会有bug，清楚不掉数据，所以使用setValue单独处理
    setValue('dateRange', null);

    reset({
      dateType: '1',
      status: undefined,
      payStatus: undefined,
      paymentType: undefined,
      keyword: '',
    });
    getTableDataByPage(1, 10);
  };

  return (
    <Box>
      <TopTitle title="订单管理" subTitle="当地玩乐"></TopTitle>

      <chakra.form
        style={{ margin: '16px auto', paddingBottom: 16, borderBottom: '1px solid #859EB9' }}
        onSubmit={handleSubmit(handleFormSubmit)}
        onReset={handleFormReset}
      >
        <Grid templateColumns="max-content 1fr" gap="16px" alignItems="center">
          <GridItem>
            <RadioGroup {...dateTypeField} onChange={handleDateTypeChange}>
              <Stack direction="row" spacing="16px">
                {DateTypeEnum.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.key}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem>
            <DateRangePicker
              value={dateRangeField.value}
              onChange={dateRangeField.onChange}
              disabled={dateTypeField.value === '1'}
              endDatePlaceholderText="选择结束日期"
              startDatePlaceholderText="选择开始日期"
              isOutsideRange={
                dateTypeField.value === '3' || dateTypeField.value === '2' ? () => false : undefined
              }
              isDayBlocked={
                dateTypeField.value === '2'
                  ? (day) => day.format('YYYY MM DD') > moment().format('YYYY MM DD')
                  : undefined
              }
            />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(auto-fit, 216px)" gap="16px" style={{ marginTop: 8 }}>
          <GridItem>
            <Select data={orderStatusList} placeholder="全部订单状态" {...statusField} />
          </GridItem>
          <GridItem>
            <Select data={payStatusList} placeholder="全部支付状态" {...payStatusField} />
          </GridItem>
          <GridItem>
            <Select data={paymentTypeList} placeholder="全部支付方式" {...paymentTypeField} />
          </GridItem>
          <GridItem>
            <Select data={supplierList} placeholder="全部供应商" {...supplierField} />
          </GridItem>
        </Grid>
        <Grid templateColumns="1fr 1fr" style={{ marginTop: 8 }}>
          <GridItem style={{ width: 'calc(216px * 3 + 16px * 2 + 9px)' }}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
              <Input placeholder="输入订单号、产品名称、联系人" {...register('keyword')} />
            </InputGroup>
          </GridItem>
          <GridItem justifySelf="self-end">
            <Stack spacing="8px" direction="row">
              <Button type="reset" variant="outline">
                清空
              </Button>
              <Button type="submit">搜索</Button>
            </Stack>
          </GridItem>
        </Grid>
      </chakra.form>

      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          <Thead>
            <Tr>
              <Th>订单号</Th>
              <Th>产品名称/数量</Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      createdTimeSort === SortTypeEnum.CreateTimeAsc
                        ? SortTypeEnum.CreatedTimeDesc
                        : SortTypeEnum.CreateTimeAsc;
                    setCreatedTimeSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  预定提交日期
                  {createdTimeSort === SortTypeEnum.CreateTimeAsc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )}
                </chakra.div>
              </Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      productDaySort === SortTypeEnum.ProductDayAsc
                        ? SortTypeEnum.ProductDayDesc
                        : SortTypeEnum.ProductDayAsc;
                    setProductDaySort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  预定出行日期
                  {productDaySort === SortTypeEnum.ProductDayAsc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )}
                </chakra.div>
              </Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType = SortTypeEnum.PriceAsc
                      ? SortTypeEnum.PriceDesc
                      : SortTypeEnum.PriceAsc;
                    setPriceSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  实际支付金额
                  {priceSort === SortTypeEnum.PriceAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </chakra.div>

                <chakra.div>支付状态</chakra.div>
              </Th>
              <Th>
                <chakra.div>支付方式</chakra.div>
                <chakra.div>订单状态</chakra.div>
              </Th>
              <Th>联系人信息</Th>
              <Th>
                <chakra.div>供应商来源</chakra.div>
                <chakra.div>企业名</chakra.div>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Box
                    cursor="pointer"
                    onClick={() => navigate('/localExperiences/' + encodeURIComponent(item.id))}
                  >
                    {item.id}
                  </Box>
                </Td>
                <Td>
                  {item.productname}
                  <chakra.div>
                    <span style={{ color: '#859EB9' }}>数量</span> {item.skucount}
                  </chakra.div>
                </Td>
                <Td>
                  <chakra.div>{moment(item.createdtime).format('YYYY/MM/DD')}</chakra.div>
                  <chakra.div>
                    {moment(item.createdtime).toISOString().substring(11, 19)}
                  </chakra.div>
                </Td>
                <Td>
                  <chakra.div>{moment(item.productday).format('YYYY/MM/DD')}</chakra.div>
                  <chakra.div>{moment(item.productday).format('HH:mm:ss')}</chakra.div>
                </Td>
                <Td>
                  <chakra.div>
                    {formatPriceNum(+item.agencytotalprice, {
                      lang: Lang.zh_CN,
                      currency: item.tourcurrency || Currency.CNY,
                    }).replace(/(\d+)/, ' $1')}
                  </chakra.div>
                  <chakra.div>
                    {
                      payStatusList.find(
                        (payStatus) =>
                          payStatus.value.toUpperCase() === item.paymentstatus.toUpperCase(),
                      )?.label
                    }
                  </chakra.div>
                </Td>
                <Td>
                  <Grid templateColumns="5em" gap="4px">
                    <GridItem justifySelf="center">
                      {
                        paymentTypeList.find(
                          (paymentType) =>
                            paymentType.value.toUpperCase() === item.paymenttype.toUpperCase(),
                        )?.label
                      }
                    </GridItem>
                    <GridItem>
                      <Badge
                        backgroundColor={orderStatusMap[item.status as OrderStatusType]?.bgColor}
                        fontColor={orderStatusMap[item.status as OrderStatusType]?.color}
                      >
                        {
                          orderStatusList.find(
                            (status) => status.value.toUpperCase() === item.status,
                          )?.label
                        }
                      </Badge>
                    </GridItem>
                  </Grid>
                </Td>
                <Td>
                  <chakra.div>{item.tourcontactname}</chakra.div>
                  <chakra.div>{item.tourcontactphone}</chakra.div>
                </Td>
                <Td>
                  <chakra.div>
                    {supplierList.find((supplier) => supplier.value === item.suppliercode)?.label}
                  </chakra.div>
                  <chakra.div>{item.agencyname}</chakra.div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {loading && (
          <Center height="200px">
            <Spinner></Spinner>
          </Center>
        )}
      </TableContainer>

      <Pagination
        total={total}
        getData={getTableDataByPage}
        currentPage={pageField.value}
        pageSize={sizeField.value}
      />
    </Box>
  );
}
