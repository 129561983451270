import { Collapse } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { menuState, TMenuChildren, TMenuState } from '~/recoil/atom/menu';
import './index.scss'

function replaceItemAtIndex<T = any>(arr: T[], index: number, newValue: T) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}
const SideMenu = () => {
  const [menu, setMenu] = useRecoilState(menuState);
  const location = useLocation();
  const navigate = useNavigate()
  const clickChildrenItem = (e: TMenuChildren, jIndex: number, menuItem: TMenuState, index: number) => {
    navigate(e.path);
    setChildrenItem(e, jIndex, menuItem, index)
  }
  function setOpen(menuItem: TMenuState, index: number) {
    setMenu(() => {
      const newMenu = menu.map((m,j) => {
        if (j === index) {
          return {
            ...m,
            isOpen: true,
            active: true
          }
        }
        return {
          ...m,
          isOpen: false,
          active: false
        }
      })
      return newMenu;
    });
    if (menuItem.path !== '') {
      navigate(menuItem.path);
    }
  }
  function setChildrenItem(e: TMenuChildren, jIndex: number, menuItem: TMenuState, index: number) {
    const children = menuItem.children || []
    const newChildren = children.map((item, k) => {
      if (k === jIndex) {
        return {...item, active: true}
      }
      return {...item, active: false }
    })
    const newItem = {
      ...menuItem,
      children: newChildren
    }
    const newMenu = replaceItemAtIndex<TMenuState>(menu, index, newItem)


    setMenu(newMenu);
  }
  useEffect(() => {
    // TODO: 当路由变化，菜单也要变化
  }, [location]);

  useEffect(() => {
    // console.log(menu)
  }, [menu])

  return <div className='menu'>
    {
      menu.map((menuItem, index) => <div key={menuItem.key} className={`menuItem ${menuItem.active ? 'active' : ''} ${menuItem.isOpen ? 'open' : ''}`}>
          <div className='title' onClick={() => setOpen(menuItem, index)}>
            <i className={'icon ' + menuItem.icon}></i>{menuItem.title}
          </div>
          {
            menuItem.children ? <Collapse in={menuItem.isOpen} animateOpacity>
              <div className='menuChildren'>
                {
                  menuItem.children!.map((e, j) => (<div
                    key={e.key}
                    className={`menuChildrenItem ${e.active ? 'cActive' : ''}`}
                    onClick={() => clickChildrenItem(e, j, menuItem, index)}>{e.title}</div>
                  ))
                }
              </div>
            </Collapse> : null
          }
        </div>
      )
    }
  </div>;
};
export default SideMenu
