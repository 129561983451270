import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import { SectionContainer } from '~/components/SectionContainer';
import type { OrderDetail } from '~/types';

type UserInfoProps = {
  order: OrderDetail;
};

//商戶信息
export default function UserInfo({ order }: UserInfoProps) {
  const { agencyname = '', agencyusername = '' } = order;
  return (
    <SectionContainer title="商户信息">
      <Text fontSize="sm">商户名称</Text>
      <Text fontSize="sm">{agencyname}</Text>
      <Text fontSize="sm">商户用户名</Text>
      <Text fontSize="sm">{agencyusername}</Text>
    </SectionContainer>
  );
}
