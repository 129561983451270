import { Box, Flex } from '@chakra-ui/react';
import { OrderDetail } from '~/types';
import { ItemLabel, ItemTextValue } from '.';
import SubTitle from './SubTitle';

const RoomInfoList = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  const { guestnames = '', guestnationalities = '' } = orderDetail;
  const guestNamesAry = guestnames.split('|');
  const guestnationalitiesAry = guestnationalities.split('|');

  const roomsInfo = guestnames
    ? guestNamesAry.map((guestNames, index) => {
        const guestAry = guestNames.split('&');
        const nationalityAry = guestnationalitiesAry[index].split('&');
        return {
          guests: guestAry.map((guestName, index) => {
            const nameAry = guestName.split('/');
            return {
              name: {
                firstName: nameAry[0],
                lastName: nameAry[1],
              },
              nationality: nationalityAry[index],
            };
          }),
        };
      })
    : [];
  console.log(roomsInfo);
  return (
    <>
      {roomsInfo.map((room, index) => {
        return (
          <Box key={index}>
            <SubTitle title={`房间${index + 1}`} />
            <Box py="4">
              <Flex w="full" alignItems="center">
                {room.guests?.map((guest, idx) => {
                  const { firstName = '', lastName = '' } = guest.name;
                  return (
                    <Box flex="1" key={idx}>
                      <ItemLabel text="入住人1姓名" />
                      <ItemTextValue text={`${firstName}/${lastName}`} />
                    </Box>
                  );
                })}
                {/* fix: 只有一位房客时样式优化 => 为了3等分 */}
                {room.guests.length === 1 ? <Box flex="1" /> : null}

                <Box flex="1">
                  <ItemLabel text="国籍" />
                  <ItemTextValue text={room.guests?.[0]?.nationality} />
                </Box>
              </Flex>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
export default RoomInfoList;
