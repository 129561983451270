import { AxiosError } from 'axios';

import apiCallingInit from '~/httpApi/init';
import { HttpApiErrorCode } from '~/types';

const apiClientInstance = apiCallingInit();

export default async function memberRefreshToken(input: { refreshToken: string }): Promise<{
  accessToken?: string;
  refreshToken?: string;
  errorCode?: HttpApiErrorCode;
}> {
  const { refreshToken } = input;

  try {
    const memberRefreshTokenResponse = await apiClientInstance.post<{
      accessToken: string;
      refreshToken: string;
    }>(`/auth/refreshToken`, {
      refreshToken,
    });

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
      memberRefreshTokenResponse.data;
    return { accessToken: newAccessToken, refreshToken: newRefreshToken };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }
}
