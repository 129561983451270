import { m } from 'framer-motion';

const supplier = [
  {
    label: '全部供应商',
    value: '',
  },
  {
    label: '客路',
    value: 'KLOOK',
  },
  {
    label: '道旅',
    value: 'DIDA',
  },
  {
    label: 'RAKUTEN(日本)',
    value: 'RAKUTEN_JAPAN',
  },
  {
    label: 'RAKUTEN(中国)',
    value: 'RAKUTEN_CHINA',
  },
  {
    label: '美团',
    value: 'MEITUAN',
  },
  {
    label: '携程',
    value: 'CTRIP_DIST',
  },
  {
    label: '深捷旅',
    value: 'SHEN_JIE_LV',
  },
];

export default supplier;
