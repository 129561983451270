import { TimeIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { ItemLabel, ItemTextValue } from '.';

const Line = () => (
  <Center w="8" h="8">
    <Box w="2px" h="8" bgColor="#C4C4C4" />
  </Center>
);

const Icon = () => (
  <Center w="8" h="8">
    <TimeIcon w="5" h="5" color="fill.blue-6" />
  </Center>
);

interface TimeLineProps {
  times: Array<{
    [x: string]: string;
  }>;
}

// createdtime 预定提交时间
// paymenttime 支付时间
// checkindate 入住日期
// checkoutdate; 退房日期
// cancellingtime 取消时间
// completerefundtime 退款完成时间

// 后端返回的 times 数据不太合适，这里需要做一层转换
const formatTime = (time: any) => {
  if (time.hasOwnProperty('createdtime')) {
    return {
      title: '预定提交时间',
      date: time.createdtime,
    };
  }

  if (time.hasOwnProperty('paymenttime')) {
    return {
      title: '支付时间',
      date: time.paymenttime,
    };
  }

  if (time.hasOwnProperty('checkindate')) {
    return {
      title: '入住日期',
      date: time.checkindate,
    };
  }

  if (time.hasOwnProperty('checkoutdate')) {
    return {
      title: '退房日期',
      date: time.checkoutdate,
    };
  }

  if (time.hasOwnProperty('cancellingtime')) {
    return {
      title: '取消时间',
      date: time.cancellingtime,
    };
  }

  if (time.hasOwnProperty('completerefundtime')) {
    return {
      title: '退款完成时间',
      date: time.completerefundtime,
    };
  }

  return null;
};

const TimeLine = (props: TimeLineProps) => {
  if (props.times.length === 0) {
    return (
      <Box py="4">
        <ItemLabel text="暂无记录" />
      </Box>
    );
  }

  return (
    <Box py="4">
      <VStack alignItems="start">
        {props.times.map((time, index) => {
          const timeFormatted = formatTime(time);
          if (!timeFormatted) {
            return <React.Fragment key={index} />;
          }

          return (
            <React.Fragment key={index}>
              <HStack w="full" spacing={2}>
                <Icon />
                <Box flex="auto">
                  <ItemLabel text={timeFormatted.title} />
                  <ItemTextValue
                    text={format(parseISO(timeFormatted.date), 'yyyy-MM-dd HH:mm:ss')}
                  />
                </Box>
              </HStack>
              {index < props.times.length - 1 ? <Line /> : null}
            </React.Fragment>
          );
        })}
      </VStack>
    </Box>
  );
};

export default TimeLine;
