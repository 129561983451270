import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useBoolean,
  useToast,
} from '@chakra-ui/react';

import useAuthingClient from '~/hooks/useAuthingClient';
import { AgencyDetail } from '~/httpApi/agency/fetchAgency';
import patchAgency from '~/httpApi/agency/patchAgency';

interface Props {
  detail: AgencyDetail;
  onModifySuccess: (data: AgencyDetail) => void;
}

const ResetPasswrod = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useBoolean();
  const { getIdToken } = useAuthingClient();
  const toast = useToast();

  const handleResetPassword = async () => {
    if (!props.detail || isLoading) {
      return;
    }

    setIsLoading.on();
    try {
      const token = await getIdToken();
      const data = await patchAgency({
        token,
        agencyId: props.detail.id,
        updateInfo: {
          resetPassword: '1',
        },
      });

      if (data) {
        props.onModifySuccess(data);
        toast({
          title: <Text textAlign="center">密码重置完成</Text>,
          status: 'success',
          isClosable: true,
        });
      }
      setIsLoading.off();
      onClose();
    } catch (error) {
      setIsLoading.off();
    }
  };

  return (
    <>
      <Button
        color="fill.blue-6"
        fontSize="sm"
        fontWeight="normal"
        variant="ghost"
        size="sm"
        px="3"
        onClick={onOpen}
      >
        重置密码
      </Button>

      <Modal size="md" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
            重置密码
          </ModalHeader>
          <ModalBody py="0">
            <Text textAlign="center" fontSize="sm">
              牵涉此帐户的登入权益，是否确定重置密码？
            </Text>
          </ModalBody>

          <ModalFooter py="8" justifyContent="center">
            <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
              取消返回
            </Button>
            <Button
              isLoading={isLoading}
              variant="solid"
              mx="2"
              px="4"
              py="2.5"
              onClick={handleResetPassword}
            >
              确定重置
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetPasswrod;
