import { useCallback, useEffect, useState } from 'react';
import { useRequestByGet } from '~/hooks/useRequest';
import { Currency } from '~/types';

interface IGetTableDataParams {
  page: number; // 当前页码
  size: number; // 分页大小
  status: string; // 订单状态
  payStatus: string; // 支付状态
  paymentType: string; // 支付方式
  suppliercode: string; // 供应商
  sort: string; // 排序方式
  keyword: string; // 关键字
  dateType: string; // 日期类型
  dateRange: { min: string; max: string }; // 日期范围
  token: string;
}

interface ITableData {
  id: string; // 订单号
  productname: string; // 产品名称
  skucount: number; // 数量
  tourcontactname: string; // 联系人名称
  tourcontactphone: string; // 联系人手机号
  agencytotalprice: string; // 实际支付金额
  createdtime: string; // 预定提交日期
  paymentstatus: string; // 支付状态
  status: string; // 订单状态
  paymenttype: string; // 支付方式
  productday: string; // 预定出行日期
  suppliercode: string; // 供应商来源
  tourcurrency: Currency; // 货币类型
  agencyname: string; //企业名
}

export const SortTypeEnum = {
  CreatedTimeDesc: 'createdtime_desc',
  CreateTimeAsc: 'createdtime_asc',
  ProductDayDesc: 'productday_desc',
  ProductDayAsc: 'productday_asc',
  PriceDesc: 'agencytotalprice_desc',
  PriceAsc: 'agencytotalprice_asc',
} as const;
export type ISortTypeEnum<T extends keyof typeof SortTypeEnum> = typeof SortTypeEnum[T];

export function useTableData() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [total, setTotal] = useState(0);
  const getTableDataRequest = useRequestByGet<{ orders: ITableData[]; totalCount: number }>(
    '/tourOrder',
  );

  const getTableData = useCallback(async (params: Partial<IGetTableDataParams>) => {
    try {
      setTableData([]);
      setLoading(true);
      const {
        data: { orders: tableData, totalCount },
      } = await getTableDataRequest({
        ...params,
        sort: params.sort || SortTypeEnum.CreatedTimeDesc,
      });

      setTableData(tableData);
      setTotal(totalCount);
      setLoading(false);
    } catch (error) {
      console.error('getTableData error: ', error);
    }
  }, []);

  useEffect(() => {
    getTableData({ page: 1, size: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, tableData, total, getTableData };
}
