import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';

interface IFormValue {
  value: string;
  isError: boolean;
}

const labelProps: FormLabelProps = {
  fontWeight: 'normal',
  fontSize: 'sm',
  color: 'text.lightBlue',
};

const CancelInputModal = ({
  isOpen,
  onClose,
  onInputFinish,
}: {
  isOpen: boolean;
  onClose: () => void;
  onInputFinish: (amount: number, remark: string) => void;
}) => {
  const [remark, setRemark] = useState<string>('');
  const [amount, setAmount] = useState<IFormValue>({
    value: '',
    isError: false,
  });

  const handleNext = () => {
    console.log(remark, amount);
    if (amount.isError) {
      return;
    }
    onInputFinish(+amount.value, remark);
  };

  return (
    <Modal size="md" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
          取消订单信息
        </ModalHeader>
        <ModalBody py="0">
          <FormControl mb="4" isInvalid={amount.isError}>
            <FormLabel {...labelProps} htmlFor="enterpriseName">
              退款金额*
            </FormLabel>
            <Input
              id="enterpriseName"
              type="number"
              value={amount.value}
              placeholder="请输入退款金额"
              onChange={(e) => {
                const value = e.target.value;
                setAmount({
                  value,
                  isError: value === '',
                });
              }}
            />
            {amount.isError ? <FormErrorMessage>请输入退款金额</FormErrorMessage> : null}
          </FormControl>
          <FormControl>
            <FormLabel {...labelProps} htmlFor="enterpriseName">
              退款备注
            </FormLabel>
            <Textarea
              id="name"
              type="text"
              h={44}
              maxLength={300}
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              placeholder="请输入"
            />
          </FormControl>
        </ModalBody>

        <ModalFooter py="8" justifyContent="center">
          <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
            取消
          </Button>
          <Button variant="solid" mx="2" px="4" py="2.5" onClick={handleNext}>
            下一步
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelInputModal;
