import { Box, Text, VStack, SimpleGrid, Grid } from '@chakra-ui/react';
import React from 'react';
import SectionLabel from '../SectionLable';

export type SectionContainerProps = { children?: React.ReactNode; title: string };

// 區段包裝：把區段標題import進來，跟內容一起包成一個區段
export function SectionContainer({ children, title }: SectionContainerProps) {
  return (
    <SectionLabel title={title}>
      <SimpleGrid
        columns={2}
        align="stretch"
        spacingX={3}
        spacingY={5}
        justify="space-between"
        p={4}
      >
        {children}
      </SimpleGrid>
    </SectionLabel>
  );
}

type SectionContainerRemakeProps = {
  children?: React.ReactNode;
  title: string;
};

export function SectionContainerWithStack({ children, title }: SectionContainerRemakeProps) {
  return (
    <SectionLabel title={title}>
      <VStack p={4} align="stretch" spacing={4}>
        {children}
      </VStack>
    </SectionLabel>
  );
}
