import { BrowserRouter } from 'react-router-dom';
import RouterContainer from './routes';

import { ChakraProvider } from '@chakra-ui/react';
import theme from './customTheme';
import { RecoilRoot } from 'recoil';

const App = () => {
  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <RouterContainer />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  );
};

export default App;
