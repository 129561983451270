import { useCallback, useEffect, useState } from 'react';
import { useRequestByGet } from '~/hooks/useRequest';
import { Currency } from '~/types';

export const SortTypeEnum = {
  CreatedTimeDesc: 'createdtime_desc',
  CreateTimeAsc: 'createdtime_asc',
  CheckInDateDesc: 'checkindate_desc',
  CheckInDateAsc: 'checkindate_asc',
  CheckOutDateDesc: 'checkoutdate_desc',
  CheckOutDateAsc: 'checkoutdate_asc',
  PriceDesc: 'agencytotalprice_desc',
  PriceAsc: 'agencytotalprice_asc',
} as const;
export type ISortTypeEnum<T extends keyof typeof SortTypeEnum> = typeof SortTypeEnum[T];
interface IGetTableDataParams {
  page: number; // 当前页码
  size: number; // 分页大小
  status: string; // 订单状态
  paymentStatus: string; // 支付状态
  paymentType: string; // 支付方式
  supplier: string; // 供应商
  sort: ISortTypeEnum<keyof typeof SortTypeEnum>; // 排序方式
  keyword: string; // 关键字
  createdAtStart: string; // 预订提交日期起始
  createdAtEnd: string; // 预订提交日期结束
  checkInDateStart: string; // 预订入住日期起始
  checkInDateEnd: string; // 预订入住日期结束
  checkOutDateStart: string; // 预订退房日期起始
  checkOutDateEnd: string; // 预订退房日期结束
}

interface ITableData {
  id: string; // 订单号
  hotelname: string; // 酒店名称
  roomnum: string; // 房数
  createdtime: string; // 预定提交日期
  checkindate: string; // 预定入住日期
  checkoutdate: string; // 预定退房日期
  agencytotalprice: string; // 实际支付金额
  paymentstatus: string; // 支付状态
  tourcurrency: Currency; // 货币类型
  status: string; // 订单状态
  paymenttype: string; // 支付方式
  productname: string; // 产品名称
  contactname: string; // 联系人名称
  contactphone: string; // 联系人手机号
  suppliercode: string; // 供应商来源
  agencyname: string; // 企业名
}

export function useTableData() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [total, setTotal] = useState(0);
  const getTableDataRequest = useRequestByGet<{ orders: ITableData[]; count: number }>('/order');

  const getTableData = useCallback(async (params: Partial<IGetTableDataParams>) => {
    try {
      setTableData([]);
      setLoading(true);
      const {
        data: { orders: tableData, count },
      } = await getTableDataRequest({
        ...params,
        channel: 'B2B_HOTEL',
        sort: params.sort || SortTypeEnum.CreatedTimeDesc,
      });

      setTableData(tableData);
      setTotal(count);
      setLoading(false);
    } catch (error) {
      console.error('getTableData error: ', error);
    }
  }, []);

  useEffect(() => {
    getTableData({ page: 1, size: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, tableData, total, getTableData };
}
