import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const CancelModal = ({
  isOpen,
  onClose,
  onNext,
}: {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
}) => {
  return (
    <Modal size="md" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
          确定人工取消
        </ModalHeader>
        <ModalBody py="0">
          <Text textAlign="center" fontSize="sm">
            一旦操作人工取消后，
          </Text>
          <Text textAlign="center" fontSize="sm">
            此订单的状态将无法复原，请谨慎操作！
          </Text>
        </ModalBody>

        <ModalFooter py="8" justifyContent="center">
          <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
            取消
          </Button>
          <Button variant="solid" mx="2" px="4" py="2.5" onClick={onNext}>
            下一步
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelModal;
