import { Navigate } from 'react-router-dom';
import useAuthingClient from '~/hooks/useAuthingClient';

const AuthWrapper = () => {
  const { checkIfLoggedIn } = useAuthingClient();
  const isLoggedIn = checkIfLoggedIn();

  return <Navigate to={isLoggedIn ? '/agency' : '/login'} replace={true} />;
};

export default AuthWrapper;
