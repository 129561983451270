import { useEffect, useState } from 'react';
import { Box, Button, HStack, useBoolean } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import getOrderById from '../../../httpApi/order/getOrderById';
import useAuthingClient from '~/hooks/useAuthingClient';
import { OrderDetail } from '~/types';
import BoxOrderDetail from './components/BoxOrderDetail';
import BoxOrderBill from './components/BoxOrderBill';
import BoxMessageDetail from './components/BoxMessageDetail';
import BoxOrderStatus from './components/BoxOrderStatus';
import { DetailBox } from './components';
import TimeLine from './components/TimeLine';
import HotelDetailSkeleton from './components/HotelDetailSkeleton';

export type TMockOderInfo = {
  status: string;
  payWay: string;
  payState: string;
};

const HotelDetail = () => {
  const { getIdToken } = useAuthingClient();
  const [loading, setLoading] = useBoolean();
  const navigate = useNavigate();
  const params = useParams<'id'>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();

  useEffect(() => {
    params.id && fetchOrder(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleBack = () => {
    navigate('/hotel');
  };

  const fetchOrder = async (orderId: string) => {
    setLoading.on();
    const token = await getIdToken();
    const orderDetail = await getOrderById({
      token,
      orderId,
    });

    if (orderDetail) {
      setOrderDetail(orderDetail);
    }
    setLoading.off();
  };

  const handleSuccess = (updatedOrder: OrderDetail | null) => {
    if (updatedOrder) {
      setOrderDetail(updatedOrder);
    }
  };

  if (loading) {
    return (
      <Box>
        <Button variant="link" mb="1" size="sm" onClick={handleBack}>
          返回上一页
        </Button>
        <HotelDetailSkeleton />
      </Box>
    );
  }

  if (!orderDetail || !orderDetail.id) {
    return (
      <Box>
        <Button variant="link" mb="1" size="sm" onClick={handleBack}>
          返回上一页
        </Button>
        <Box>no record</Box>
      </Box>
    );
  }

  return (
    <Box>
      <Button variant="link" mb="1" size="sm" onClick={handleBack}>
        返回上一页
      </Button>

      <BoxOrderStatus orderDetail={orderDetail} onModifySuccess={handleSuccess} />

      <HStack spacing={4} alignItems="flex-start">
        <Box flex="5">
          <BoxOrderDetail orderDetail={orderDetail} />
          <BoxOrderBill orderDetail={orderDetail} />
          <BoxMessageDetail orderDetail={orderDetail} />
        </Box>

        <Box flex="2">
          <DetailBox>
            <TimeLine times={orderDetail.times} />
          </DetailBox>
        </Box>
      </HStack>
    </Box>
  );
};

export default HotelDetail;
