// import { useNavigate } from 'react-router';
import { Box, Text, VStack, Flex, Spacer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import { STATUS_DISPLAY_MAP, SUPPLIER_DISPLAY_MAP } from '~/displayInfo';

import type { OrderListItem } from '~/types';
type OrderListProps = {
  list: OrderListItem[];
};

export default function OrderList({ list }: OrderListProps) {
  const navigate = useNavigate();

  return (
    <VStack py={8} bg="white" align="stretch">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>订单编号</Th>
            <Th>酒店名称</Th>
            <Th>入住人</Th>
            <Th>入住/退房日期</Th>
            <Th>来源</Th>
            <Th>订单状态</Th>
            <Th>订单创建时间</Th>
          </Tr>
        </Thead>
        <Tbody>
          {list.map(
            ({
              id: bookingId,
              hotelname = '',
              guestnames = '',
              checkindate = null,
              checkoutdate = null,
              suppliercode,
              status,
              createdtime = null,
            }) => (
              <Tr
                key={bookingId}
                _hover={{ bg: 'light', cursor: 'pointer' }}
                onClick={() => navigate(`/orders/b2b/${encodeURIComponent(bookingId)}`)}
              >
                <Td>{bookingId.split('#')?.[1] || bookingId}</Td>
                <Td>
                  <Text>{hotelname.length > 15 ? `${hotelname.slice(0, 15)}...` : hotelname}</Text>
                </Td>
                <Td>
                  {guestnames &&
                    guestnames
                      .split('|')
                      .map((guest) => (
                        <Text>{guest.length > 15 ? `${guest.slice(0, 15)}...` : guest}</Text>
                      ))}
                </Td>
                <Td>
                  <Text>入{checkindate && formatDate(parseISO(checkindate), 'yyyy-MM-dd')}</Text>
                  <Text>退{checkoutdate && formatDate(parseISO(checkoutdate), 'yyyy-MM-dd')}</Text>
                </Td>
                <Td>{SUPPLIER_DISPLAY_MAP[suppliercode]}</Td>
                <Td>{STATUS_DISPLAY_MAP[status]}</Td>
                <Td>{createdtime && formatDate(parseISO(createdtime), 'yyyy-MM-dd HH:mm:ss')}</Td>
              </Tr>
            ),
          )}
        </Tbody>
      </Table>
    </VStack>
  );
}
