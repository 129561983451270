import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  FormLabelProps,
  useBoolean,
} from '@chakra-ui/react';
import { useState } from 'react';
import CitySelect from '~/components/CitySelect';
import useAuthingClient from '~/hooks/useAuthingClient';
import { AgencyDetail } from '~/httpApi/agency/fetchAgency';
import { ICity, IProvince } from '~/httpApi/agency/getCityList';
import patchAgency from '~/httpApi/agency/patchAgency';

interface IFormValue {
  value: string;
  isError: boolean;
}
const labelProps: FormLabelProps = {
  fontWeight: 'normal',
  fontSize: 'sm',
  color: 'text.lightBlue',
};

interface Props {
  detail: AgencyDetail;
  onModifySuccess: (data: AgencyDetail) => void;
}
const ModifyInfo = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useBoolean();
  const { getIdToken } = useAuthingClient();

  const [enterpriseName, setEnterpriseName] = useState<IFormValue>({
    value: props.detail.enterpriseName,
    isError: false,
  });
  const [contactPhone, setContactPhone] = useState<IFormValue>({
    value: props.detail.contactPhone,
    isError: false,
  });
  const [contactName, setContactName] = useState<IFormValue>({
    value: props.detail.contactName,
    isError: false,
  });
  const [enterpriseAddress, setEnterpriseAddress] = useState<IFormValue>({
    value: props.detail.enterpriseAddress,
    isError: false,
  });
  const [enterpriseapplyapi, setEnterpriseApplyapi] = useState<string>(
    props.detail.enterpriseapplyapi,
  );
  const [city, setCity] = useState<{ province: string; city: string }>({ province: '', city: '' });

  const handleSubmit = async () => {
    if (
      enterpriseName.isError ||
      contactPhone.isError ||
      contactName.isError ||
      enterpriseAddress.isError ||
      isLoading
    ) {
      return;
    }

    setIsLoading.on();
    try {
      const token = await getIdToken();
      const data = await patchAgency({
        token,
        agencyId: props.detail.id,
        updateInfo: {
          enterpriseName: enterpriseName.value,
          contactPhone: contactPhone.value,
          contactName: contactName.value,
          enterpriseAddress: enterpriseAddress.value,
          enterpriseapplyapi,
          enterpriseCity: city.city,
          enterpriseProvince: city.province,
        },
      });

      data && props.onModifySuccess(data);
      setIsLoading.off();
      onClose();
    } catch (error) {
      console.log(error);
      setIsLoading.off();
    }
  };

  const handleCityChange = (province?: IProvince, city?: ICity) => {
    setCity({
      province: province?.name || '',
      city: city?.name || '',
    });
  };

  return (
    <>
      <Box
        cursor="pointer"
        fontSize="sm"
        color="fill.blue-6"
        fontWeight="normal"
        px="2.5"
        py="1.5"
        onClick={onOpen}
      >
        编辑信息
      </Box>
      <Modal size="sm" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
            编辑信息
          </ModalHeader>
          <ModalBody py="0">
            <FormControl mb="4" isInvalid={enterpriseName.isError}>
              <FormLabel {...labelProps} htmlFor="enterpriseName">
                企业名称*
              </FormLabel>
              <Input
                id="enterpriseName"
                type="text"
                value={enterpriseName.value}
                onChange={(e) => {
                  const value = e.target.value;
                  setEnterpriseName({
                    value,
                    isError: value === '',
                  });
                }}
              />
              {enterpriseName.isError ? <FormErrorMessage>请输入企业名称</FormErrorMessage> : null}
            </FormControl>

            <FormControl mb="4" isInvalid={contactPhone.isError}>
              <FormLabel {...labelProps} htmlFor="phone">
                联系电话*
              </FormLabel>
              <Input
                id="contactPhone"
                type="text"
                value={contactPhone.value}
                onChange={(e) => {
                  const value = e.target.value;
                  setContactPhone({
                    value,
                    isError: value === '',
                  });
                }}
              />
              {contactPhone.isError ? <FormErrorMessage>请输入联系电话</FormErrorMessage> : null}
            </FormControl>

            <FormControl mb="4" isInvalid={contactName.isError}>
              <FormLabel {...labelProps} htmlFor="contactName">
                联系人*
              </FormLabel>
              <Input
                id="contactName"
                type="text"
                value={contactName.value}
                onChange={(e) => {
                  const value = e.target.value;
                  setContactName({
                    value,
                    isError: value === '',
                  });
                }}
              />
              {contactName.isError ? <FormErrorMessage>请输入联系人</FormErrorMessage> : null}
            </FormControl>

            <FormControl mb="4">
              <FormLabel {...labelProps} htmlFor="enterpriseAddress">
                所在城市
              </FormLabel>
              <CitySelect
                initialProvince="福建省"
                initialCity="福州市"
                onChange={handleCityChange}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel {...labelProps} htmlFor="enterpriseAddress">
                联系地址
              </FormLabel>
              <Input
                id="enterpriseAddress"
                type="text"
                value={enterpriseAddress.value}
                placeholder="请填写"
                onChange={(e) => {
                  const value = e.target.value;
                  setEnterpriseAddress({
                    value,
                    isError: false,
                  });
                }}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel {...labelProps} htmlFor="enterpriseapplyapi">
                申请API接口对接
              </FormLabel>
              <Select
                value={enterpriseapplyapi}
                onChange={(e) => setEnterpriseApplyapi(e.target.value)}
                id="enterpriseapplyapi"
              >
                <option value="1">是</option>
                <option value="0">否</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter py="8" justifyContent="center">
            <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
              取消
            </Button>
            <Button
              isLoading={isLoading}
              variant="solid"
              mx="2"
              px="4"
              py="2.5"
              onClick={handleSubmit}
            >
              保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModifyInfo;
