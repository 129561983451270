import { Flex, Text } from '@chakra-ui/react';

const SubTitle = ({ title }: { title: string }) => {
  return (
    <Flex
      w="full"
      px="1"
      h="10"
      mt="4"
      alignItems="center"
      justify="space-between"
      bgColor="fill.gray-1"
    >
      <Text fontSize="md" color="fill.gray-9" fontWeight="normal" px="0.5">
        {title}
      </Text>
    </Flex>
  );
};

export default SubTitle;
