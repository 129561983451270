import { HStack, Select } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import useAuthingClient from '~/hooks/useAuthingClient';
import getCityList, { ICity, ICityList, IProvince } from '~/httpApi/agency/getCityList';

const CitySelect = ({
  initialProvince = '北京市',
  initialCity = '北京市',
  onChange,
}: {
  initialProvince?: string;
  initialCity?: string;
  onChange: (province?: IProvince, city?: ICity) => void;
}) => {
  const { getIdToken } = useAuthingClient();
  const [cityList, setCityList] = useState<ICityList>({ provinces: [] });
  const [selectedProvince, setSelectedProvince] = useState<IProvince | undefined>();
  const [selectedCity, setSelectedCity] = useState<ICity | undefined>();

  useEffect(() => {
    const fetch = async () => {
      const token = await getIdToken();
      const data = await getCityList({ token });
      if (!data) {
        return;
      }
      setCityList(data);

      // 初始化province和city
      if (!initialProvince) {
        return;
      }
      const findProvince = data.provinces.find((i) => i.name === initialProvince);
      const findCity = findProvince?.sonCitys.find((i) => i.name === initialCity);
      findProvince && setSelectedProvince(findProvince);
      findCity && setSelectedCity(findCity);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(selectedProvince, selectedCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedProvince]);

  const handleProvinceChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const code = event.target.value;
    const province = cityList.provinces.find((i) => i.code === code);
    // // 换了省份
    if (selectedProvince?.code !== code) {
      setSelectedCity(province?.sonCitys[0]);
    }
    setSelectedProvince(province);
  };

  const handleCityChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const code = event.target.value;
    const city = selectedProvince?.sonCitys.find((i) => i.code === code);
    setSelectedCity(city);
  };

  return (
    <HStack>
      <Select value={selectedProvince?.code} onChange={handleProvinceChange}>
        {cityList.provinces.map((province) => {
          return (
            <option key={province.code} value={province.code}>
              {province.name}
            </option>
          );
        })}
      </Select>
      <Select value={selectedCity?.code} onChange={handleCityChange}>
        {selectedProvince?.sonCitys.map((city) => {
          return (
            <option key={city.code} value={city.code}>
              {city.name}
            </option>
          );
        })}
      </Select>
    </HStack>
  );
};
export default CitySelect;
