import apiCallingInit from '~/httpApi/init';

const apiClientInstance = apiCallingInit();

export type ImageFileInfo = {
  fileContent: string; // 上传文件内容
  contentType?: string;
  category?: ImagesCategory; //上传文件类别
  fileName: string;
};

export enum ImagesCategory {
  LICENSE = 'enterpriseLicense',
  PERMIT = 'enterprisePermit',
  LIABILITY = 'enterpriseLiability',
  OTHER = 'enterpriseOtherDocument',
  CERT = 'enterpriseCertifications',
}

type UploadImagesInput = {
  token: string;
  agencyId: string;
  images: ImageFileInfo[];
};

type UploadImagesResponse = {
  dbUpdate: number; // 更新数量
  s3Update: {
    category: ImagesCategory;
    assetId: string;
    fileName: string;
    url: string;
  }[]; // s3 URL文件路径
};

const uploadImages = async ({
  token,
  agencyId,
  images,
}: UploadImagesInput): Promise<UploadImagesResponse | null> => {
  try {
    const uploadImagesResponse = await apiClientInstance.post<UploadImagesResponse>(
      '/imageUpload',
      { agencyId, images },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return uploadImagesResponse.data;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};

export default uploadImages;
