import apiCallingInit from '~/httpApi/init';
import { CertificationType, StatusType, LicenseFile } from '~/types';
import { AgencyDetail } from './fetchAgency';

const apiClientInstance = apiCallingInit();

type UpdateAgencyInput = {
  token: string;
  agencyId: string;
  updateInfo: Partial<{
    name: string; // 商户名称
    status: StatusType; // 商户状态
    enterpriseName: string; // 企业名称
    enterpriseCity: string; // 企业城市
    enterpriseAddress: string; // 企业地址
    remark: string; // 文本备注

    enterpriseUniqueId: string; // 企业统一证号
    enterpriseLegalPerson: string; // 企业法人
    enterpriseStatus: CertificationType; // 企业认证状态
    enterpriseLicense: LicenseFile; // 企业营业执照
    enterprisePermit: LicenseFile; // 经营许可
    enterpriseLiability: LicenseFile; // 旅行社责任险
    contactName: string; // 联系人名称
    contactPhone: string; // 联系电话
    enterpriseapplyapi: string; // 企业申请api接口对接 0否 1是
    enterpriseCertifications: Array<LicenseFile>; // 企业认证
    enterpriseProvince: string; // 所在省份
    resetPassword: string;
  }>;
};

const patchAgency = async ({
  token,
  agencyId,
  updateInfo,
}: UpdateAgencyInput): Promise<AgencyDetail | null> => {
  if (Object.keys(updateInfo).length <= 0) {
    return null;
  }

  try {
    const updateAgencyResponse = await apiClientInstance.patch<AgencyDetail>(
      `/agency/${agencyId}`,
      updateInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return updateAgencyResponse.data;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};

export default patchAgency;
