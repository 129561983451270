import { useEffect, useState } from 'react';

type UseWindowScrollInput = {
  onScrollAtBottom: () => void;
};

export default function useWindowScroll({ onScrollAtBottom }: UseWindowScrollInput) {
  const [scrollBottom, setScrollBottom] = useState<number>(window.innerHeight);

  window.addEventListener('scroll', () => {
    setScrollBottom(window.scrollY + window.innerHeight);
  });

  useEffect(() => {
    console.log({ scrollBottom });

    if (scrollBottom >= document.body.scrollHeight - 100) {
      onScrollAtBottom();
    }
  }, [scrollBottom]);

  return { scrollBottom };
}
