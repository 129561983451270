const paymentType = [
  {
    label: '全部支付方式',
    value: '',
  },
  {
    label: '微信',
    value: 'Wechat',
  },
  {
    label: '支付宝',
    value: 'AliPay',
  },
  {
    label: '信用卡',
    value: 'CreditCard',
  },
  {
    label: '余额',
    value: 'Balance',
  },
  {
    label: 'OTA支付',
    value: 'OTA',
  },
];

export default paymentType;
