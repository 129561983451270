import { AxiosError } from 'axios';

import apiCallingInit from '~/httpApi/init';
import { HttpApiErrorCode, User } from '~/types';

const apiClientInstance = apiCallingInit();

export default async function memberDetail(input: { accessToken: string }): Promise<{
  errorCode?: HttpApiErrorCode;
  user?: User;
}> {
  const { accessToken } = input;

  let user: User;
  try {
    const memberDetailResponse = await apiClientInstance.get<User>(`/auth/member`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    user = memberDetailResponse.data;
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return { user };
}
