import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import useAuthingClient from '~/hooks/useAuthingClient';

const service = axios.create({
  // baseURL: 'https://2pduy1dquj.execute-api.cn-north-1.amazonaws.com.cn',
  baseURL: process.env.REACT_APP_ADMIN_DASHBOARD_HTTP_API_BASE_URL,
  timeout: 15 * 1000,
});

function useRequest<T>(method: 'get' | 'post', requestConfig?: AxiosRequestConfig) {
  const { getIdToken } = useAuthingClient();

  return async function (url: string, params?: any) {
    const token = await getIdToken();

    const paramsType = method === 'get' ? 'params' : 'data';
    const config = {
      method,
      url,
      [paramsType]: params,
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return service(config) as AxiosPromise<T>;
  };
}

function useRequestByGet<T>(url: string) {
  const sponsorRequestByGet = useRequest<T>('get');

  return (params?: any) => sponsorRequestByGet(url, params);
}

async function useRequestByPost(url: string, params: any) {
  const sponsorRequestByPost = useRequest('post');
  const { data } = await sponsorRequestByPost(url, params);

  return data;
}

export { useRequestByGet, useRequestByPost };
