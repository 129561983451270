import { Tag } from '@chakra-ui/react';
import { CertificationType } from '~/types';

const VerifyStatusTag = ({ status }: { status?: CertificationType }) => {
  if (status === CertificationType.UNVERIFIED) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="#C4C4C4" alignItems="center">
        未认证
      </Tag>
    );
  }
  if (status === CertificationType.VERIFYING) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="text.lightBlue" alignItems="center">
        认证中
      </Tag>
    );
  }
  if (status === CertificationType.VERIFIED) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="fill.cyan" alignItems="center">
        已认证
      </Tag>
    );
  }
  // 默认
  return (
    <Tag size="sm" fontSize="xs" variant="solid" bgColor="#C4C4C4" alignItems="center">
      {status}
    </Tag>
  );
};

export default VerifyStatusTag;
