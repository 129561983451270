import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { StatusType } from '~/types';

// const verificationStatusDisplayMapping = {
//   [CertificationType.UNVERIFIED]: '未认证',
//   [CertificationType.VERIFYING]: '认证中',
//   [CertificationType.VERIFIED]: '已认证',
// };

const statusDisplayMapping = {
  [StatusType.INACTIVE]: '未开启',
  [StatusType.ACTIVATED]: '正常',
  [StatusType.LOCKED]: '锁定',
};

const status2Label = (status?: StatusType) => {
  if (!status) {
    return '全部企业状态';
  }
  return statusDisplayMapping[status] || status;
};

const CompanyStatusSelect = (props: {
  minW?: string;
  status?: StatusType;
  onChange: (status?: StatusType) => void;
  isLimit?: boolean;
}) => {
  return (
    <Menu matchWidth>
      <MenuButton minW={props.minW || '200px'}>
        <InputGroup>
          <InputRightElement pointerEvents="none" children={<ChevronDownIcon />} />
          <Input value={status2Label(props.status)} readOnly />
        </InputGroup>
      </MenuButton>
      {props.isLimit ? (
        <MenuList zIndex={10}>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange(StatusType.ACTIVATED)}>
            正常
          </MenuItem>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange(StatusType.LOCKED)}>
            锁定
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange()}>
            所有企业状态
          </MenuItem>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange(StatusType.ACTIVATED)}>
            正常
          </MenuItem>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange(StatusType.LOCKED)}>
            锁定
          </MenuItem>
          <MenuItem onClick={() => props.onChange(StatusType.INACTIVE)}>未开启</MenuItem>
        </MenuList>
      )}
    </Menu>
  );
};
export default CompanyStatusSelect;
