import TopTitle from '~/components/TopTitle';
import {
  Box,
  Button,
  Center,
  chakra,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { SearchIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import moment from 'moment';
import DateRangePicker from '~/components/DateRangePicker';
import { useForm, useController } from 'react-hook-form';
import Select from '~/components/Select';
import orderStatusMap, { OrderStatusType } from '~/constants/orderStatus';
import payStatusList from '~/constants/payStatus';
import paymentTypeList from '~/constants/paymentType';
import supplierList from '~/constants/supplier';
import Pagination from '~/components/Pagination';
import { ISortTypeEnum, SortTypeEnum, useTableData } from '../hooks';
import { Lang, Currency } from '~/types';
import formatPriceNum from '~/utils/formatPriceNum';
import Badge from '~/components/Badge';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DateTypeEnum = [
  {
    key: '所有日期',
    value: '1',
  },
  {
    key: '预定提交日期',
    value: '2',
  },
  {
    key: '预定入住日期',
    value: '3',
  },
  {
    key: '预定退房日期',
    value: '4',
  },
];

export default function Hotel() {
  const navigate = useNavigate();
  const [createdTimeSort, setCreatedTimeSort] = useState<
    ISortTypeEnum<'CreateTimeAsc' | 'CreatedTimeDesc'>
  >(SortTypeEnum.CreatedTimeDesc);
  const [checkInDateSort, setCheckInDateSort] = useState<
    ISortTypeEnum<'CheckInDateAsc' | 'CheckInDateDesc'>
  >(SortTypeEnum.CheckInDateDesc);
  const [checkOutDateSort, setCheckOutDateSort] = useState<
    ISortTypeEnum<'CheckOutDateAsc' | 'CheckOutDateDesc'>
  >(SortTypeEnum.CheckOutDateDesc);
  const [priceSort, setPriceSort] = useState<ISortTypeEnum<'PriceAsc' | 'PriceDesc'>>(
    SortTypeEnum.PriceDesc,
  );
  const { loading, tableData, total, getTableData } = useTableData();
  const orderStatusList = Object.entries(orderStatusMap).map(([key, value]) => ({
    label: value.text,
    value: key,
  }));
  const { register, handleSubmit, reset, control, getValues, setValue } = useForm();

  const { field: dateTypeField } = useController({ name: 'dateType', control, defaultValue: '1' }); // 日期类型
  const { field: dateRangeField } = useController({ name: 'dateRange', control }); // 日期时间
  const { field: statusField } = useController({ name: 'status', control }); // 订单状态
  const { field: payStatusField } = useController({ name: 'paymentStatus', control }); // 支付状态
  const { field: paymentTypeField } = useController({ name: 'paymenttype', control }); // 支付方式
  const { field: supplierField } = useController({ name: 'supplier', control }); // 供应商
  const { field: sortField } = useController({ name: 'sort', control }); // 排序方式
  const { field: pageField } = useController({ name: 'page', control }); // 当前页码
  const { field: sizeField } = useController({ name: 'size', control }); // 分页大小

  const getTableDataByPage = (pageNum: number, pageSize: number) => {
    pageField.onChange(pageNum);
    sizeField.onChange(pageSize);
    const { dateType, dateRange, ...rest } = getValues();

    getTableData({
      page: pageNum,
      size: pageSize,
      ...rest,
      createdAtStart:
        (dateType === '2' &&
          (dateRange?.startDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
      createdAtEnd:
        (dateType === '2' &&
          (dateRange?.endDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
      checkInDateStart:
        (dateType === '3' &&
          (dateRange?.startDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
      checkInDateEnd:
        (dateType === '3' &&
          (dateRange?.endDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
      checkOutDateStart:
        (dateType === '4' &&
          (dateRange?.startDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
      checkOutDateEnd:
        (dateType === '4' &&
          (dateRange?.endDate as moment.Moment)?.format('YYYY-MM-DD HH:mm:ss')) ||
        undefined,
    });
  };

  const handleDateTypeChange = (dateType: string) => {
    setValue('dateRange', null);
    dateTypeField.onChange(dateType);
  };

  const handleFormSubmit = () => getTableDataByPage(1, 10);

  const handleFormReset = () => {
    // 日期组件，使用reset会有bug，清楚不掉数据，所以使用setValue单独处理
    setValue('dateRange', null);

    reset({
      dateType: '1',
      status: undefined,
      payStatus: undefined,
      paymentType: undefined,
      keyword: '',
    });

    getTableDataByPage(1, 10);
  };

  return (
    <Box>
      <TopTitle title="订单管理" subTitle="酒店" />

      <chakra.form
        style={{ margin: '16px auto', paddingBottom: 16, borderBottom: '1px solid #859EB9' }}
        action=""
        onSubmit={handleSubmit(handleFormSubmit)}
        onReset={handleFormReset}
      >
        <Grid templateColumns="max-content 1fr" gap="16px" alignItems="center">
          <GridItem>
            <RadioGroup
              {...dateTypeField}
              value={dateTypeField.value}
              onChange={handleDateTypeChange}
            >
              <Stack direction="row" spacing="16px">
                {DateTypeEnum.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.key}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem>
            <DateRangePicker
              value={dateRangeField.value}
              onChange={dateRangeField.onChange}
              disabled={dateTypeField.value === '1'}
              endDatePlaceholderText="选择结束日期"
              startDatePlaceholderText="选择开始日期"
              isOutsideRange={dateTypeField.value !== '1' ? () => false : undefined}
              isDayBlocked={
                dateTypeField.value === '2'
                  ? (day) => day.format('YYYY MM DD') > moment().format('YYYY MM DD')
                  : undefined
              }
            />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(auto-fit, 216px)" gap="16px" style={{ marginTop: 8 }}>
          <GridItem>
            <Select data={orderStatusList} placeholder="全部订单状态" {...statusField} />
          </GridItem>
          <GridItem>
            <Select data={payStatusList} placeholder="全部支付状态" {...payStatusField} />
          </GridItem>
          <GridItem>
            <Select data={paymentTypeList} placeholder="全部支付方式" {...paymentTypeField} />
          </GridItem>
          <GridItem>
            <Select data={supplierList} placeholder="全部供应商" {...supplierField} />
          </GridItem>
        </Grid>
        <Grid templateColumns="1fr 1fr" style={{ marginTop: 8 }}>
          <GridItem style={{ width: 'calc(216px * 3 + 16px * 2 + 9px)' }}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
              <Input placeholder="输入订单号、产品名称、联系人" {...register('keyword')} />
            </InputGroup>
          </GridItem>
          <GridItem justifySelf="self-end">
            <Stack spacing="8px" direction="row">
              <Button type="reset" variant="outline">
                清空
              </Button>
              <Button type="submit">搜索</Button>
            </Stack>
          </GridItem>
        </Grid>
      </chakra.form>

      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          <Thead>
            <Tr>
              <Th>订单号</Th>
              <Th>酒店名称/房数</Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      createdTimeSort === SortTypeEnum.CreateTimeAsc
                        ? SortTypeEnum.CreatedTimeDesc
                        : SortTypeEnum.CreateTimeAsc;
                    setCreatedTimeSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  预定提交日期
                  {createdTimeSort === SortTypeEnum.CreateTimeAsc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )}
                </chakra.div>
              </Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      checkInDateSort === SortTypeEnum.CheckInDateAsc
                        ? SortTypeEnum.CheckInDateDesc
                        : SortTypeEnum.CheckInDateAsc;
                    setCheckInDateSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  预定入住日期
                  {checkInDateSort === SortTypeEnum.CheckInDateAsc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )}
                </chakra.div>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      checkOutDateSort === SortTypeEnum.CheckOutDateAsc
                        ? SortTypeEnum.CheckOutDateDesc
                        : SortTypeEnum.CheckOutDateAsc;
                    setCheckOutDateSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  预定退房日期
                  {checkOutDateSort === SortTypeEnum.CheckOutDateAsc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )}
                </chakra.div>
              </Th>
              <Th>
                <chakra.div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortType =
                      priceSort === SortTypeEnum.PriceAsc
                        ? SortTypeEnum.PriceDesc
                        : SortTypeEnum.PriceAsc;
                    setPriceSort(sortType);
                    sortField.onChange(sortType);
                    handleFormSubmit();
                  }}
                >
                  实际支付金额
                  {priceSort === SortTypeEnum.PriceAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </chakra.div>
                <chakra.div>支付状态</chakra.div>
              </Th>
              <Th>
                <chakra.div>支付方式</chakra.div>
                <chakra.div>订单状态</chakra.div>
              </Th>
              <Th>联系人信息</Th>
              <Th>
                <chakra.div>供应商来源</chakra.div>
                <chakra.div>企业名</chakra.div>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Box
                    cursor="pointer"
                    onClick={() => navigate('/hotel/' + encodeURIComponent(item.id))}
                  >
                    {item.id}
                  </Box>
                </Td>
                <Td>
                  {item.hotelname}
                  <chakra.div>
                    <chakra.span style={{ color: '#859EB9' }}>房数</chakra.span> {item.roomnum}
                  </chakra.div>
                </Td>
                <Td>
                  <chakra.div>{moment(item.createdtime).format('YYYY/MM/DD')}</chakra.div>
                  <chakra.div>
                    {moment(item.createdtime).toISOString().substring(11, 19)}
                  </chakra.div>
                </Td>
                <Td>
                  <chakra.div>
                    <chakra.span
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        marginRight: '5px',
                        lineHeight: '20px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        color: '#002C5E',
                        backgroundColor: '#EBEEF5',
                        fontSize: '12px',
                      }}
                    >
                      入
                    </chakra.span>
                    {moment(item.checkindate).format('YYYY/MM/DD')}
                  </chakra.div>
                  <chakra.div style={{ marginTop: '5px' }}>
                    <chakra.span
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        marginRight: '5px',
                        lineHeight: '20px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        color: '#fff',
                        backgroundColor: '#002C5E',
                        fontSize: '12px',
                      }}
                    >
                      退
                    </chakra.span>
                    {moment(item.checkoutdate).format('YYYY/MM/DD')}
                  </chakra.div>
                </Td>
                <Td>
                  <chakra.div>
                    {formatPriceNum(+item.agencytotalprice, {
                      lang: Lang.zh_CN,
                      currency: item.tourcurrency || Currency.CNY,
                    }).replace(/(\d+)/, ' $1')}
                  </chakra.div>
                  <chakra.div>
                    {
                      payStatusList.find(
                        (payStatus) =>
                          payStatus.value.toUpperCase() === item.paymentstatus.toUpperCase(),
                      )?.label
                    }
                  </chakra.div>
                </Td>
                <Td>
                  <Grid templateColumns="5em" gap="4px">
                    <GridItem justifySelf="center">
                      {
                        paymentTypeList.find(
                          (paymentType) =>
                            paymentType.value.toUpperCase() === item.paymenttype.toUpperCase(),
                        )?.label
                      }
                    </GridItem>
                    <GridItem>
                      <Badge
                        backgroundColor={orderStatusMap[item.status as OrderStatusType]?.bgColor}
                        fontColor={orderStatusMap[item.status as OrderStatusType]?.color}
                      >
                        {
                          orderStatusList.find(
                            (status) => status.value.toUpperCase() === item.status,
                          )?.label
                        }
                      </Badge>
                    </GridItem>
                  </Grid>
                </Td>
                <Td>
                  <chakra.div>{item.contactname}</chakra.div>
                  <chakra.div>{item.contactphone}</chakra.div>
                </Td>
                <Td>
                  <chakra.div>
                    {supplierList.find((supplier) => supplier.value === item.suppliercode)?.label}
                  </chakra.div>
                  <chakra.div>{item.agencyname}</chakra.div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {loading && (
          <Center height="200px">
            <Spinner></Spinner>
          </Center>
        )}
      </TableContainer>

      <Pagination
        total={total}
        getData={getTableDataByPage}
        currentPage={pageField.value}
        pageSize={sizeField.value}
      />
    </Box>
  );
}
