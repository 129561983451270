import apiCallingInit from '~/httpApi/init';

import { CertificationType, StatusType, LicenseFile } from '~/types';

const apiClientInstance = apiCallingInit();

export type AgencyDetail = {
  id: string; // 商户Id
  status: StatusType; // 商户状态，未激活，已激活，锁定
  name: string; // 商户名字
  registeredDate: string; // 注册日期
  latestLoginTime: string; //上次登录时间
  enterpriseName: string; // 企业名字
  enterpriseCertifications: Array<LicenseFile>; // 企业认证img
  enterpriseProvince: string;
  enterpriseapplyapi: string;
  remark: string;
  enterpriseStatus?: CertificationType; // 企业认证状态，未认证，认证中，已认证
  enterpriseUserNum?: string; // 企业用户数量
  contactName: string; // 管理员联系名称
  contactPhone: string; // 管理员电话
  contactUserId: string; // 管理员id
  enterprisePassword: string; // 企业账号密码
  contactExtend: string; // 联系扩展

  enterpriseCity: string; // 所在城市
  enterpriseAddress: string; // 联系地址
};

type GetAgencyInput = {
  token: string;
  agencyId: string;
};

const fetchAgency = async ({ token, agencyId }: GetAgencyInput): Promise<AgencyDetail | null> => {
  try {
    const listAgenciesResponse = await apiClientInstance.get<AgencyDetail>(`/agency/${agencyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return listAgenciesResponse.data;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};

export default fetchAgency;
