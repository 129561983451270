import { Table, Tbody, Td, Th, Thead, Tr, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import StatusTag from '~/components/StatusTag';
import VerifyStatusTag from '~/components/VerifyStatusTag';
import { UsersDetail } from '~/types';

const ThStyle = {
  color: 'text.lightBlue',
  fontWeight: 'normal',
  fontSize: 'sm',
  borderColor: 'text.lightBlue',
};

const TdStyle = {
  height: '92px',
  color: 'text.blue',
  fontSize: 'sm',
  fontWeight: 'normal',
  borderColor: 'text.lightBlue',
};

const formatTime = (date?: string) => {
  if (!date) {
    return '';
  }
  return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss');
};

const CompanyTable = (props: { list: Array<UsersDetail> }) => {
  const navigate = useNavigate();
  const handleGoDetail = (id: string) => {
    navigate('/agency/' + id);
  };

  return (
    <Table color="black" bgColor="white">
      <Thead bgColor="rgba(247, 249, 254, 1)" h="60px">
        <Tr>
          <Th {...ThStyle}>企业ID</Th>
          <Th {...ThStyle}>企业名称</Th>
          <Th {...ThStyle}>联系电话</Th>
          <Th {...ThStyle}>联系人</Th>
          <Th {...ThStyle}>注册日期</Th>
          <Th {...ThStyle}>地区</Th>
          <Th {...ThStyle} textAlign="center" w="108px">
            状态
          </Th>
          <Th {...ThStyle} textAlign="center" w="108px">
            认证
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.list.map((item: UsersDetail, index) => {
          return (
            <Tr key={index} fontSize="xs" role="group" _hover={{ bgColor: 'fill.gray-1' }}>
              <Td
                {...TdStyle}
                borderLeftWidth="1px"
                cursor="pointer"
                _groupHover={{ color: 'fill.blue-6' }}
              >
                <Box onClick={() => handleGoDetail(item.id)}>{item.id}</Box>
              </Td>
              <Td {...TdStyle}>{item.name}</Td>
              <Td {...TdStyle}>{item.contactPhone}</Td>
              <Td {...TdStyle}>{item.contactName}</Td>
              <Td {...TdStyle}>{formatTime(item.registeredDate)}</Td>
              <Td {...TdStyle}>{item.area}</Td>
              <Td {...TdStyle} textAlign="center">
                <StatusTag status={item.status} />
              </Td>
              <Td {...TdStyle} textAlign="center" borderRightWidth="1px">
                <VerifyStatusTag status={item.enterpriseStatus} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default CompanyTable;
