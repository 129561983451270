import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Center,
  Text,
  VStack,
  Button,
} from '@chakra-ui/react';

type ErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  errMsg?: string;
};

export default function ErrorModal({ errMsg, isOpen, onClose }: ErrorModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent w="290px" borderRadius="lg">
        <ModalHeader pb={1}>
          <Center>
            <Text color="primary" fontSize="2xl">
              系统异常错误
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody pt={2} pb={4}>
          <Center>
            <VStack spacing={4}>
              <Text color="primary" textAlign="center">
                {errMsg || '请重新刷新资料页面'}
              </Text>
              {!errMsg && (
                <Button
                  px={8}
                  py={4}
                  h="auto"
                  bgColor="#6190E8"
                  color="#fff"
                  fontSize="lg"
                  onClick={() => window.location.reload()}
                >
                  刷新整理
                </Button>
              )}
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
