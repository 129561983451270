import { Box, Skeleton, Stack } from '@chakra-ui/react';

const DetailBox = (props: { children: React.ReactNode }) => {
  return (
    <Box bgColor="white" borderRadius="base" boxShadow="base" w="full" px="10" py="4" mb="1">
      {props.children}
    </Box>
  );
};

const CompanyDetailSkeleton = () => {
  return (
    <>
      <DetailBox>
        <Stack w="full" alignItems="left">
          <Skeleton w="50%" height="5" />
          <Skeleton height="8" />
          <Skeleton height="8" />
          <Skeleton height="8" />
        </Stack>
      </DetailBox>
      <DetailBox>
        <Stack w="full" alignItems="left">
          <Skeleton w="50%" height="5" />
          <Skeleton height="8" />
          <Skeleton height="8" />
        </Stack>
      </DetailBox>
      <DetailBox>
        <Stack w="full" alignItems="left">
          <Skeleton w="50%" height="5" />
          <Skeleton height="8" />
        </Stack>
      </DetailBox>
    </>
  );
};
export default CompanyDetailSkeleton;
