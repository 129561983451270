import {
  Box,
  Text,
  VStack,
  Grid,
  GridItem,
  StackDivider,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import SectionContainerFull from '~/components/SectionContainerFull';

import { OrderDetail } from '~/types';

import formatPriceNum from '~/utils/formatPriceNum';

import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

type AgencyQuotationInfoProps = {
  order: OrderDetail;
};

//商户交易详情
export default function AgencyQuotationInfo({ order }: AgencyQuotationInfoProps) {
  const {
    checkindate = null,
    checkoutdate = null,
    hotelroomtype = '',
    adultcount,
    children = '',
    roomnum,
    hotelbedtype = '',
    supplierdaliyprice = '',
    suppliertotalprice = '',
    suppliercurrency = '',
  } = order;

  let parsedHotelBedType: string;
  try {
    parsedHotelBedType = hotelbedtype ? JSON.parse(hotelbedtype) : [];
  } catch (error) {
    parsedHotelBedType = hotelbedtype;
  }
  const bedType = !Array.isArray(parsedHotelBedType)
    ? parsedHotelBedType
    : parsedHotelBedType.map(({ desc, count }: any) => `${desc} x ${count}`).join(', ');

  const checkInDate = checkindate && parseISO(checkindate),
    checkOutDate = checkoutdate && parseISO(checkoutdate);
  const daysBetween = checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : 0;

  const daysToStay: Date[] = [];
  for (let i = 0; i < daysBetween; i++) {
    checkInDate && daysToStay.push(addDays(checkInDate, i));
  }

  const childrenAry = children ? children.split('|') : null;

  return (
    <SectionContainerFull title="商户交易详情">
      <Box pt={8} px={4}>
        <VStack spacing={2} align="stretch" p={0}>
          <Table variant="unstyled">
            <Thead bg="gray.500">
              <Tr>
                <Th fontSize="sm">日期</Th>
                <Th fontSize="sm">房型</Th>
                <Th fontSize="sm">每晚入住人数</Th>
                <Th fontSize="sm">单間单晚报价</Th>
                <Th fontSize="sm">间数</Th>
                <Th isNumeric fontSize="sm" textAlign="end">
                  总金额
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {daysToStay.map((day, index) => (
                <Tr>
                  <Td fontSize="sm">{day && formatDate(day, 'yyyy-MM-dd')}</Td>
                  <Td fontSize="sm">
                    {hotelroomtype} / {bedType}
                  </Td>
                  <Td fontSize="sm">
                    {adultcount} 成人 {childrenAry && `${childrenAry.length} 儿童`}
                  </Td>
                  <Td fontSize="sm">
                    {supplierdaliyprice && formatPriceNum(parseInt(supplierdaliyprice))}
                    /间
                  </Td>
                  <Td fontSize="sm">{roomnum} 间</Td>
                  <Td fontSize="sm" textAlign="end">
                    {suppliertotalprice && formatPriceNum(Number(suppliertotalprice))}
                  </Td>
                </Tr>
              ))}
              <Tr borderTopWidth="1px" borderTopColor="#DEDEDE">
                <Td fontSize="sm"> 供应商货币</Td>
                <Td fontSize="sm">{suppliercurrency && `￥ ${suppliercurrency}`}</Td>
                <Td></Td>
                <Td></Td>
                <Td fontSize="sm" fontWeight="bold">
                  优惠券金額
                </Td>
                <Td fontSize="sm" textAlign="end">
                  {formatPriceNum(0)}
                </Td>
              </Tr>
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td fontSize="sm" fontWeight="bold">
                  商户订单总金额
                </Td>
                <Td fontSize="sm" textAlign="end">
                  {suppliertotalprice && formatPriceNum(Number(suppliertotalprice))}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </VStack>
      </Box>
    </SectionContainerFull>
  );
}
