import RcSelect, { Option } from 'rc-select';
import './index.scss';

export type ISelectData<T = string> = {
  label: string;
  value: T;
};
export interface ISelect {
  placeholder?: string;
  defaultValue?: string;
  data: ISelectData<string>[];
  onChange?: (item: string) => void;
}
const Select = (props: ISelect) => {
  const defaultValue = props.defaultValue;

  return (
    <RcSelect
      {...props}
      listHeight={500}
      className="form-select"
      defaultValue={defaultValue}
      placeholder={props.placeholder}
    >
      {props.data.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </RcSelect>
  );
};

export default Select;
