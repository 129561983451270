import apiCallingInit from '~/httpApi/init';

import type { OrderDetail } from '~/types';

const apiClientInstance = apiCallingInit();

type OrderDetailResponse = {
  message: string;
  order: OrderDetail;
};

type GetOrderInput = {
  token: string;
  orderId: string;
};

export default async ({ token, orderId }: GetOrderInput): Promise<OrderDetail | null> => {
  try {
    const orderResponse = await apiClientInstance.get<OrderDetailResponse>(
      `/order/${encodeURIComponent(orderId)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return orderResponse.data.order;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};
