import { useRoutes, useLocation, RouteObject } from 'react-router-dom';
import Orders from '~/pages/OrderList';
import Order from '~/pages/Order';
// import Users from '~/pages/UserList';
// import User from '~/pages/User';
import CompanyList from '~/pages/CompanyList';

import AuthWrapper from '~/globalWrapper/AuthWrapper';
import Login from '~/pages/Login';
import { BasicLayout } from '~/layouts/BasicLayout';
import NoMatch from '~/pages/noMatch';

import Hotel from '~/pages/hotel';
import HotelOrder from '~/pages/hotel/order';
import HotelOrderDetail from '~/pages/hotel/detail';
import Trip from '~/pages/localExperiences';
import TripOrder from '~/pages/localExperiences/order';
import TripOrderDetail from '~/pages/localExperiences/detail';
import Theme from '~/pages/theme';
import { useEffect } from 'react';
import CompanyDetail from '~/pages/CompanyList/CompanyDetail';

const routes: Array<RouteObject> = [
  {
    path: '/',
    element: <AuthWrapper />,
    children: [],
  },
  {
    path: '/',
    element: <BasicLayout />,
    children: [
      {
        path: '/orders',
        element: <Orders />,
      },
      {
        path: '/orders/:type/:orderId',
        element: <Order />,
      },
      {
        path: '/agency',
        element: <CompanyList />,
      },
      {
        path: '/agency/:id',
        element: <CompanyDetail />,
      },
      {
        path: '/hotel',
        element: <Hotel />,
        children: [
          {
            index: true,
            element: <HotelOrder />,
          },
          {
            path: '/hotel/:id',
            element: <HotelOrderDetail />,
          },
        ],
      },
      {
        path: '/localExperiences',
        element: <Trip />,
        children: [
          {
            index: true,
            element: <TripOrder />,
          },
          {
            path: '/localExperiences/:id',
            element: <TripOrderDetail />,
          },
        ],
      },
      {
        path: '/theme',
        element: <Theme />,
      },
      { path: '*', element: <NoMatch /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
];

const headerTitleMap = {
  '/agency': '商户管理｜多会儿旅行',
  '/localExperiences': '当地玩乐订单管理｜多会儿旅行',
  '/hotel': '酒店订单管理｜多会儿旅行',
};

const RouterContainer = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    document.title =
      Object.entries(headerTitleMap).find(([key]) => pathname.includes(key))?.[1] || '多会儿旅行';
  }, [location]);

  const element = useRoutes(routes);
  return element;
};

export default RouterContainer;
