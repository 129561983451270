import { Box, Flex, Text } from '@chakra-ui/react';
import { LocalOrderDetail } from '~/types';
import { DetailBox, DetailBoxHeader, ItemLabel, ItemTextValue } from '.';
import SubTitle from './SubTitle';

const BoxOrderDetail = ({ orderDetail }: { orderDetail: LocalOrderDetail }) => {
  return (
    <DetailBox>
      <DetailBoxHeader>
        <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
          订单详情
        </Text>
      </DetailBoxHeader>
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="产品名称" />
            <ItemTextValue text={orderDetail.productname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="方案名称" />
            <ItemTextValue text={orderDetail.programname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="出行时间" />
            <ItemTextValue text={orderDetail.productday} />
          </Box>
        </Flex>
      </Box>
      <SubTitle title="出行信息" />
      <Box py="4">TODO</Box>

      <SubTitle title="联系信息" />
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="联系人名称" />
            <ItemTextValue text={orderDetail.tourcontactname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="手机号" />
            <ItemTextValue text={orderDetail.tourcontactphone} />
          </Box>
          <Box flex="1">
            <ItemLabel text="邮箱" />
            <ItemTextValue text={orderDetail.tourcontactemail} />
          </Box>
        </Flex>
      </Box>
    </DetailBox>
  );
};
export default BoxOrderDetail;
