import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Side from './components/Side';

export const BasicLayout: React.FC = () => {
  return (
    <Flex minH="100vh" minW="1440px">
      <Side />
      <Box px="8" py="6" flex="auto">
        <Outlet />
      </Box>
    </Flex>
  );
};
