import { Button, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import useAuthingClient from '~/hooks/useAuthingClient';
import updateOrder from '~/httpApi/order/updateOrder';
import { OrderDetail, OrderPaymentStatusType } from '~/types';
import CancelConfirmModal from './CancelConfirmModal';
import CancelInputModal from './CancelInputModal';
import CancelModal from './CancelModal';

const OrderCancelButton = (props: {
  onModifySuccess: (order: OrderDetail | null) => void;
  orderDetail: OrderDetail;
}) => {
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { isOpen: isOpenInput, onOpen: onOpenInput, onClose: onCloseInput } = useDisclosure();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const { getIdToken } = useAuthingClient();
  const [params, setParams] = useState<Partial<OrderDetail>>({});

  const handleNext = () => {
    onCloseCancel();
    onOpenInput();
    setParams({});
  };

  const handleInputFinish = (amount: number, remark: string) => {
    setParams({
      refundamount: amount,
      remark,
      paymentstatus: OrderPaymentStatusType.REFUNDING,
    });
    onOpenConfirm();
    onCloseInput();
  };

  const handleUpdateOrder = async () => {
    const token = await getIdToken();
    const order = await updateOrder({
      token,
      type: '',
      orderId: props.orderDetail.id,
      updateInfo: params,
    });
    props.onModifySuccess(order);
    onCloseConfirm();
  };

  const handleBackInputModal = () => {
    onCloseConfirm();
    onOpenInput();
  };

  return (
    <>
      <Button
        variant="outline"
        borderColor="fill.blue-6"
        color="fill.blue-6"
        px="4"
        size="sm"
        fontSize="xs"
        onClick={onOpenCancel}
      >
        人工取消
      </Button>
      <CancelModal isOpen={isOpenCancel} onClose={onCloseCancel} onNext={handleNext} />
      <CancelInputModal
        isOpen={isOpenInput}
        onClose={onCloseInput}
        onInputFinish={handleInputFinish}
      />
      <CancelConfirmModal
        isOpen={isOpenConfirm}
        onClose={handleBackInputModal}
        amount={params.refundamount || 0}
        onConfirm={handleUpdateOrder}
      />
    </>
  );
};

export default OrderCancelButton;
