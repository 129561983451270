import {
  Box,
  Text,
  VStack,
  StackDivider,
  Flex,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import { OrderDetail } from '~/types';

import formatPriceNum from '~/utils/formatPriceNum';

import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

type QuotationDetailInfoProps = {
  order: OrderDetail;
};

//報價日期以及金額詳情
export default function QuotationDetailInfo({ order }: QuotationDetailInfoProps) {
  const {
    checkindate = null,
    checkoutdate = null,
    roomnum,
    hotelroomtype = '',
    hotelbedtype = '',
    adultcount,
    children = '',
    suppliercurrency = '',
    supplierdaliyprice = '',
    suppliertotalprice = '',
  } = order;

  let parsedHotelBedType: string;
  try {
    parsedHotelBedType = hotelbedtype ? JSON.parse(hotelbedtype) : [];
  } catch (error) {
    parsedHotelBedType = hotelbedtype;
  }
  const bedType = !Array.isArray(parsedHotelBedType)
    ? parsedHotelBedType
    : parsedHotelBedType.map(({ desc, count }: any) => `${desc} x ${count}`).join(', ');

  const checkInDate = checkindate && parseISO(checkindate),
    checkOutDate = checkoutdate && parseISO(checkoutdate);
  const daysBetween = checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : 0;

  const daysToStay: Date[] = [];
  for (let i = 0; i < daysBetween; i++) {
    checkInDate && daysToStay.push(addDays(checkInDate, i));
  }

  // console.log({ daysToStay });

  const childrenAry = children ? children.split('|') : null;

  return (
    <VStack spacing={2} align="stretch" p={0} mt={8}>
      <Table variant="unstyled">
        <Thead bg="gray.500">
          <Tr>
            <Th fontSize="sm">日期</Th>
            <Th fontSize="sm">房型</Th>
            <Th fontSize="sm">每晚入住人数</Th>
            <Th fontSize="sm">单間单晚报价</Th>
            <Th fontSize="sm">间数</Th>
            <Th isNumeric fontSize="sm" textAlign="end">
              总金额
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {daysToStay.map((day, index) => (
            <Tr key={formatDate(day, 'yyyy-MM-dd')}>
              <Td fontSize="sm">{formatDate(day, 'yyyy-MM-dd')}</Td>
              <Td fontSize="sm">
                {hotelroomtype} / {bedType}
              </Td>
              <Td fontSize="sm">
                {adultcount}成人 {childrenAry && `${childrenAry.length}儿童`}
              </Td>
              <Td fontSize="sm">
                {supplierdaliyprice && formatPriceNum(parseInt(supplierdaliyprice))}/间
              </Td>
              <Td fontSize="sm">{roomnum} 间</Td>
              <Td fontSize="sm" textAlign="end">
                {supplierdaliyprice && formatPriceNum(parseInt(supplierdaliyprice))}
              </Td>
            </Tr>
          ))}
          <Tr borderTopWidth="1px" borderTopColor="#DEDEDE">
            <Td fontSize="sm">供应商货币</Td>
            <Td fontSize="sm">{suppliercurrency && `￥ ${suppliercurrency}`}</Td>
            <Td></Td>
            <Td></Td>
            <Td fontSize="sm" fontWeight="bold">
              供应商订单总金额
            </Td>
            <Td fontSize="sm" textAlign="end">
              {suppliertotalprice && formatPriceNum(Number(suppliertotalprice))}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </VStack>
  );
}
