import { useEffect, useRef } from 'react';

type UseFetchTimeout = (isLoading: boolean, handler: () => void) => void;

const useFetchTimeout: UseFetchTimeout = (isLoading, handler) => {
  const loadingRef = useRef<boolean>();
  const timeRef = useRef<NodeJS.Timeout | null>(null);

  loadingRef.current = isLoading;
  useEffect(() => {
    if (!loadingRef.current) return;
    timeRef.current = setTimeout(() => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
        timeRef.current = null;
      }
      if (!loadingRef.current) return;
      console.error('loading timeout');
      handler();
    }, 1000 * 60 * 10);
    return () => {
      if (!loadingRef.current && timeRef.current) {
        clearTimeout(timeRef.current);
        timeRef.current = null;
      }
    };
  }, [isLoading]);
};

export default useFetchTimeout;
