import { SearchIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  useBoolean,
  Select,
  Skeleton,
} from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination';

import listAgenciesApiCalling from '~/httpApi/agency/listAgencies';
import CompanyStatusSelect from '~/pages/CompanyList/components/CompanyStatusSelect';
import CompanyVerifySelect from '~/pages/CompanyList/components/CompanyVerifySelect';
import CompanyTable from './components/CompanyTable';
import useAuthingClient from '~/hooks/useAuthingClient';
import { CertificationType, StatusType, UsersDetail } from '~/types';

const CompanyList = () => {
  const { getIdToken } = useAuthingClient();
  const [agencies, setAgencies] = useState<Array<UsersDetail>>([]);
  const [agenciesCount, setAgenciesCount] = useState<number>(0);
  // const [pageSize, setPageSize] = useState<{ page: number; size: number }>({ page: 1, size: 10 });
  const [keyword, setKeyword] = useState<string>('');
  const [area, setArea] = useState<string>('');
  const [status, setStatus] = useState<StatusType>();
  const [verificationStatus, setVerificationStatus] = useState<CertificationType>();
  const [searchLoading, setSearchLoading] = useBoolean(false);
  const [pageLoading, setPageLoading] = useBoolean(true);

  const { currentPage, pageSize, offset, setCurrentPage, setPageSize, pagesCount, pages } =
    usePagination({
      total: agenciesCount,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        currentPage: 1,
        pageSize: 10,
      },
    });

  React.useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const handleChangeStatus = (value?: StatusType) => {
    setStatus(value);
  };

  const handleChangeVerifyStatus = (value?: CertificationType) => {
    setVerificationStatus(value);
  };

  const handleSearch = async () => {
    setSearchLoading.on();

    const token = await getIdToken();
    const { agencies, agenciesCount } = await listAgenciesApiCalling({
      token,
      keyword,
      status,
      area,
      verificationStatus,
      page: currentPage,
      size: pageSize,
    });
    setAgencies(agencies);
    setAgenciesCount(agenciesCount);

    setSearchLoading.off();
    setPageLoading.off();
  };

  const handleClear = () => {
    setArea('');
    setKeyword('');
    setStatus(undefined);
    setVerificationStatus(undefined);
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  return (
    <Box>
      <Heading color="text.blue" as="h2" fontSize="2xl">
        企业管理
      </Heading>
      <Box borderBottomWidth="1px" borderColor="fill.blue-1" py="4">
        <Flex align="center" justify="space-between">
          <HStack>
            <InputGroup w="full">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="fill.gray-6" />}
              />
              <Input
                value={keyword}
                onChange={(e) => setKeyword(e.target.value.trim())}
                variant="outline"
                placeholder="输入企业ID、企业名称、联系电话、联系人"
              />
            </InputGroup>
            <CompanyStatusSelect status={status} onChange={handleChangeStatus} />
            <CompanyVerifySelect status={verificationStatus} onChange={handleChangeVerifyStatus} />

            <InputGroup w="full">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="fill.gray-6" />}
              />
              <Input
                value={area}
                onChange={(e) => setArea(e.target.value.trim())}
                placeholder="所有地区"
              />
            </InputGroup>
          </HStack>
          <HStack>
            <Button onClick={handleClear}>清空</Button>
            <Button variant="solid" isLoading={searchLoading} onClick={handleSearch}>
              搜索
            </Button>
          </HStack>
        </Flex>
      </Box>
      {pageLoading ? (
        <Box pt="4">
          <Skeleton height="60px" mb="1" />
          <Skeleton height="92px" mb="1" />
          <Skeleton height="92px" mb="1" />
          <Skeleton height="92px" mb="1" />
        </Box>
      ) : (
        <Box pt="4">
          <CompanyTable list={agencies} />
        </Box>
      )}

      <Box pt="4" pb="2">
        <Flex alignItems="center" justify="space-between">
          <HStack>
            <Text fontSize="md" color="fill.gray-6">
              {offset + 1}-{offset + pageSize}笔
            </Text>
            <Text fontSize="lg" color="fill.gray-9">
              共有{agenciesCount}个
            </Text>
          </HStack>

          <HStack spacing={4}>
            <Select
              onChange={handlePageSizeChange}
              bgColor="white"
              border="none"
              color="#859EB9"
              h="32px"
              borderRadius="2px"
              fontSize="sm"
            >
              <option value="10">每页10笔订单</option>
              <option value="20">每页20笔订单</option>
              <option value="50">每页50笔订单</option>
            </Select>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            >
              <PaginationContainer align="center" justify="space-between">
                <PaginationPrevious
                  bgColor="white"
                  color="#859EB9"
                  mr="10px"
                  w="30px"
                  h="32px"
                  p="0"
                  borderRadius="2px"
                  minW="30px"
                >
                  <ChevronLeftIcon />
                </PaginationPrevious>
                <PaginationPageGroup
                  isInline
                  align="center"
                  spacing="10px"
                  separator={
                    <PaginationSeparator
                      fontSize="sm"
                      w="30px"
                      h="32px"
                      borderRadius="2px"
                      bgColor="white"
                      color="#859EB9"
                    />
                  }
                >
                  {pages.map((page: number) => (
                    <PaginationPage
                      w="30px"
                      h="32px"
                      borderRadius="2px"
                      key={`pagination_page_${page}`}
                      page={page}
                      fontSize="sm"
                      bgColor="white"
                      color="#859EB9"
                      _current={{
                        bgColor: '#002C5E',
                        color: 'white',
                      }}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext
                  bgColor="white"
                  color="#859EB9"
                  ml="10px"
                  w="30px"
                  h="32px"
                  p="0"
                  borderRadius="2px"
                  minW="30px"
                >
                  <ChevronRightIcon />
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
            <HStack spacing={1}>
              <Text fontSize="sm" color="text.lightBlue" whiteSpace="nowrap">
                跳至
              </Text>
              <Input
                w="30px"
                h="32px"
                border="none"
                borderRadius="2px"
                px={0}
                fontSize="sm"
                textAlign="center"
                type="number"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    const value = (e.target as any).value;
                    if (+value > 0) {
                      setCurrentPage(Math.floor(+value));
                    }
                  }
                }}
              />
              <Text fontSize="sm" color="text.lightBlue">
                页
              </Text>
            </HStack>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default CompanyList;
