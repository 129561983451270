// import { useNavigate } from 'react-router';
import {
  Box,
  Icon,
  Heading,
  HStack,
  Text,
  VStack,
  Flex,
  Spacer,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Divider,
  Button,
  Center,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';

import OrderList from './OrderList';

import { IoSearchOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { IoIosArrowDown } from 'react-icons/io';
import { BtnVariant } from '~/customTheme/components/Button';

import {
  OrderListItem,
  OrderPaymentStatusType,
  OrderStatusType,
  SupplierPaymentType,
  SupplierType,
  TimeFilter,
} from '~/types';
import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import {
  ORDER_PAYMENT_STATUS_LIST,
  ORDER_STATUS_LIST,
  SUPPLIER_LIST,
  SUPPLIER_PAYMENT_STATUS_LIST,
  TIME_FILTER_CONTENT,
} from '~/displayInfo';
import listOrders from '~/httpApi/order/listOrders';
import useAuthingClient from '~/hooks/useAuthingClient';
import useWindowScroll from '~/hooks/useWindowScroll';
import useFetchTimeout from '~/hooks/useFetchTimeout';

import parseDate from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import formatISO from 'date-fns/formatISO';
import BackToTopButton from '~/components/BackToTopButton';
import ErrorModal from '~/components/ErrorModal';

type OrderFilterInput = {
  selectedTimeType: TimeFilter;
  searchInput: string | undefined;
  selectedStartTime: string | undefined;
  selectedEndTime: string | undefined;
  selectedOrderType: OrderStatusType | '';
  selectedPaymentType: OrderPaymentStatusType | '';
  selectedSupplierType: SupplierPaymentType | '';
  selectedSupplier: SupplierType | '';
  page?: number;
  size?: number;
};

// 訂單列表：訂單列表的總頁面，可用在這邊的selector對訂單進行篩選
const Orders = () => {
  // 先利用假資料顯示畫面
  const [data, setData] = useState<Array<OrderListItem>>([]);
  //訂單數量
  const [count, setCount] = useState<number>(0);

  // Radio 篩選時間的方式
  const [selectedTimeType, setSelectedTimeType] = useState<TimeFilter>(TimeFilter.CREATED_AT);

  // 輸入搜尋的input
  const [searchInput, setSearchInput] = useState<string>();
  // 輸入搜尋頁數
  const [searchPage, setSearchPage] = useState<number>(1);

  //選擇開始時間
  const [selectedStartTime, setSelectedStartTime] = useState<string>();
  //選擇結束時間
  const [selectedEndTime, setSelectedEndTime] = useState<string>();
  //訂單狀態
  const [selectedOrderType, setSelectedOrderType] = useState<OrderStatusType | ''>('');
  //支付狀態
  const [selectedPaymentType, setSelectedPaymentType] = useState<OrderPaymentStatusType | ''>('');
  //供應商結算狀態
  const [selectedSupplierType, setSelectedSupplierType] = useState<SupplierPaymentType | ''>('');
  //供應商名稱
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierType | ''>('');

  const {
    isOpen: isErrorModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const [isOrderQueryFinished, setOrderQueryFinished] = useState<boolean>(false);
  const [isLoadingOrders, setLoadingOrders] = useState<boolean>(false);

  useFetchTimeout(isLoadingOrders, onOpenErrorModal);

  const { getIdToken } = useAuthingClient();

  //按下重置
  const onClickReset = () => {
    setSelectedTimeType(TimeFilter.CREATED_AT);
    setSearchInput('');
    setSelectedStartTime('');
    setSelectedEndTime('');
    setSelectedOrderType('');
    setSelectedPaymentType('');
    setSelectedSupplierType('');
    setSelectedSupplier('');
  };

  //按下刷新
  const onClickRefresh = () => {
    //TODO:重新刷新表單內容
    handleGetListOrders(true, {
      selectedTimeType,
      searchInput,
      selectedStartTime,
      selectedEndTime,
      selectedOrderType,
      selectedPaymentType,
      selectedSupplierType,
      selectedSupplier,
      page: 1,
    });
  };

  //按下導出
  const onClickExport = () => {
    //TODO:下載excel報表
  };

  const handleGetListOrders = useCallback(
    async (
      forceRefresh: boolean = false,
      {
        selectedTimeType,
        searchInput,
        selectedStartTime,
        selectedEndTime,
        selectedOrderType,
        selectedPaymentType,
        selectedSupplierType,
        selectedSupplier,
        page = 1,
        size = 20,
      }: OrderFilterInput,
    ) => {
      console.log({
        selectedTimeType,
        searchInput,
        selectedStartTime,
        selectedEndTime,
        selectedOrderType,
        selectedPaymentType,
        selectedSupplierType,
        selectedSupplier,
        page,
        size,
      });

      setLoadingOrders(true);
      if (forceRefresh) {
        setData([]);
        setCount(0);
        setOrderQueryFinished(false);
      }

      const now = new Date();

      const token = await getIdToken();
      const { orders, count } = await listOrders({
        token,
        keyword: searchInput,
        createdAtStart:
          selectedTimeType === TimeFilter.CREATED_AT && selectedStartTime
            ? formatISO(startOfDay(parseDate(selectedStartTime, 'yyyy-MM-dd', now)))
            : undefined,
        createdAtEnd:
          selectedTimeType === TimeFilter.CREATED_AT && selectedEndTime
            ? formatISO(endOfDay(parseDate(selectedEndTime, 'yyyy-MM-dd', now)))
            : undefined,
        cancellingAtStart:
          selectedTimeType === TimeFilter.CANCELLING_AT && selectedStartTime
            ? formatISO(startOfDay(parseDate(selectedStartTime, 'yyyy-MM-dd', now)))
            : undefined,
        cancellingAtEnd:
          selectedTimeType === TimeFilter.CANCELLING_AT && selectedEndTime
            ? formatISO(endOfDay(parseDate(selectedEndTime, 'yyyy-MM-dd', now)))
            : undefined,
        checkInDateStart:
          selectedTimeType === TimeFilter.CHECK_IN && selectedStartTime
            ? selectedStartTime
            : undefined,
        checkInDateEnd:
          selectedTimeType === TimeFilter.CHECK_IN && selectedEndTime ? selectedEndTime : undefined,
        checkOutDateStart:
          selectedTimeType === TimeFilter.CHECK_OUT && selectedStartTime
            ? selectedStartTime
            : undefined,
        checkOutDateEnd:
          selectedTimeType === TimeFilter.CHECK_OUT && selectedEndTime
            ? selectedEndTime
            : undefined,
        paymentStatus: selectedPaymentType || undefined,
        status: selectedOrderType || undefined,
        supplierPaymentStatus: selectedSupplierType || undefined,
        supplier: selectedSupplier || undefined,
        size,
        page: forceRefresh ? 1 : page,
      });

      setData((prev) => (forceRefresh ? orders : [...prev, ...orders]));
      setCount(count?.count || 0);
      setSearchPage(forceRefresh ? 1 : page);
      if (orders.length <= 0) {
        setOrderQueryFinished(true);
      }
      setLoadingOrders(false);
    },
    [],
  );

  useWindowScroll({
    onScrollAtBottom: () => {
      console.log('data', data);
      console.log({ isLoadingOrders, isOrderQueryFinished });
      if (isLoadingOrders || isOrderQueryFinished) {
        return;
      }

      handleGetListOrders(false, {
        selectedTimeType,
        searchInput,
        selectedStartTime,
        selectedEndTime,
        selectedOrderType,
        selectedPaymentType,
        selectedSupplierType,
        selectedSupplier,
        page: searchPage + 1,
      });
    },
  });

  const handleDebounceSearch = useCallback(
    debounce(
      (
        selectedTimeType,
        searchInput,
        selectedStartTime,
        selectedEndTime,
        selectedOrderType,
        selectedPaymentType,
        selectedSupplierType,
        selectedSupplier,
      ) => {
        handleGetListOrders(true, {
          selectedTimeType,
          searchInput,
          selectedStartTime,
          selectedEndTime,
          selectedOrderType,
          selectedPaymentType,
          selectedSupplierType,
          selectedSupplier,
        });
      },
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (searchInput) {
      handleDebounceSearch(
        selectedTimeType,
        searchInput,
        selectedStartTime,
        selectedEndTime,
        selectedOrderType,
        selectedPaymentType,
        selectedSupplierType,
        selectedSupplier,
      );
      return;
    }

    handleGetListOrders(true, {
      selectedTimeType,
      searchInput,
      selectedStartTime,
      selectedEndTime,
      selectedOrderType,
      selectedPaymentType,
      selectedSupplierType,
      selectedSupplier,
    });
  }, [
    selectedTimeType,
    searchInput,
    selectedStartTime,
    selectedEndTime,
    selectedOrderType,
    selectedPaymentType,
    selectedSupplierType,
    selectedSupplier,
    handleDebounceSearch,
    handleGetListOrders,
  ]);

  return (
    <>
      <VStack align="stretch" spacing={6}>
        <VStack p={8} bg="white" align="stretch" spacing={3}>
          <Heading size="md">酒店订单列表</Heading>
          <Flex align="center">
            <InputGroup bg={searchInput ? 'white' : 'gray.300'} width="24rem" borderRadius="5px">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={IoSearchOutline} color="fontColorDark" />}
              />
              <Input
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                borderWidth="1px"
                borderColor="gray.300"
                type="text"
                _placeholder={{
                  color: 'fontColorDark',
                }}
                placeholder="支持搜索订单编号，入住人，酒店名称等"
              />
              <InputRightElement>
                {searchInput && (
                  <Icon
                    onClick={() => setSearchInput('')}
                    as={IoCloseCircleOutline}
                    color="gray.700"
                    boxSize="1.25rem"
                    cursor="pointer"
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <Spacer />
            <HStack bg="white" spacing={5}>
              <Button variant={BtnVariant.OUTLINE} onClick={onClickReset}>
                重置
              </Button>
            </HStack>
          </Flex>
          <Divider />

          <HStack spacing={9}>
            <RadioGroup
              defaultValue="订单创建时间"
              value={selectedTimeType}
              onChange={(e) => setSelectedTimeType(e as TimeFilter)}
            >
              <HStack spacing={2} align="center">
                {TIME_FILTER_CONTENT.map(({ value, display }) => (
                  <Radio value={value}>
                    <Text fontSize="sm">{display}</Text>
                  </Radio>
                ))}
              </HStack>
            </RadioGroup>
            <HStack spacing={4}>
              <Text>开始时间</Text>
              <Box position="relative" mr={9}>
                <Input
                  w={40}
                  value={selectedStartTime}
                  type="date"
                  color="fontColorDark"
                  borderColor="#d6d6d6"
                  pl={7}
                  sx={{
                    '&::-webkit-calendar-picker-indicator': calendarPickerIndicatorStyle,
                  }}
                  onChange={(e) => setSelectedStartTime(e.target.value)}
                />
                <Icon as={IoIosArrowDown} position="absolute" top={3} right={3} />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Text>结束时间</Text>
              <Box position="relative">
                <Input
                  w={40}
                  value={selectedEndTime}
                  type="date"
                  color="fontColorDark"
                  borderColor="#d6d6d6"
                  pl={7}
                  sx={{
                    '&::-webkit-calendar-picker-indicator': calendarPickerIndicatorStyle,
                  }}
                  onChange={(e) => setSelectedEndTime(e.target.value)}
                />
                <Icon as={IoIosArrowDown} position="absolute" top={3} right={3} />
              </Box>
            </HStack>
          </HStack>

          <Flex>
            <HStack spacing={4} flex={8}>
              <Select
                value={selectedOrderType}
                placeholder="全部订单状态"
                flex={3}
                color="fontColorDark"
                borderColor="gray.500"
                onChange={(e) => setSelectedOrderType(e.target.value as OrderStatusType | '')}
              >
                {ORDER_STATUS_LIST.map(({ value, display }) => (
                  <option value={value}>{display}</option>
                ))}
              </Select>
              <Select
                value={selectedPaymentType}
                placeholder="全部支付状态"
                flex={3}
                color="fontColorDark"
                borderColor="gray.500"
                onChange={(e) =>
                  setSelectedPaymentType(e.target.value as OrderPaymentStatusType | '')
                }
              >
                {ORDER_PAYMENT_STATUS_LIST.map(({ value, display }) => (
                  <option value={value}>{display}</option>
                ))}
              </Select>
              <Select
                value={selectedSupplierType}
                placeholder="供应商全部结算状态"
                flex={4}
                color="fontColorDark"
                borderColor="gray.500"
                onChange={(e) =>
                  setSelectedSupplierType(e.target.value as SupplierPaymentType | '')
                }
              >
                {SUPPLIER_PAYMENT_STATUS_LIST.map(({ value, display }) => (
                  <option value={value}>{display}</option>
                ))}
              </Select>
              <Select
                value={selectedSupplier}
                placeholder="全部供应商来源"
                flex={4}
                color="fontColorDark"
                borderColor="gray.500"
                onChange={(e) => setSelectedSupplier(e.target.value as SupplierType | '')}
              >
                {SUPPLIER_LIST.map(({ value, display }) => (
                  <option value={value}>{display}</option>
                ))}
              </Select>
            </HStack>
            <Spacer />
          </Flex>
        </VStack>

        <VStack py={4} px={8} bg="white" align="stretch" spacing={1}>
          <Flex align="center">
            <Text fontSize="sm">{isLoadingOrders ? '' : `共${count}个结果`}</Text>
            <Spacer />
            <HStack bg="white" spacing={5} justify="flex-end">
              <Button variant={BtnVariant.OUTLINE} onClick={onClickRefresh}>
                刷新
              </Button>
              {/* <Button variant={BtnVariant.OUTLINE} onClick={onClickExport}>
                导出
              </Button> */}
            </HStack>
          </Flex>
          {data && <OrderList list={data} />}
          {!isOrderQueryFinished && (
            <Center>
              <Spinner size="lg" color="primary" />
            </Center>
          )}
        </VStack>
        <BackToTopButton aria-label="back-to-top" position="fixed" bottom={8} right={8} />
      </VStack>
      <ErrorModal isOpen={isErrorModalOpen} onClose={onCloseErrorModal} />
    </>
  );
};

export default Orders;

const calendarPickerIndicatorStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  margin: 'auto',
  opacity: 0,
};
