import { Box, Text, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import SectionLabel from '../SectionLable';

export type SectionContainerFullProps = { children?: React.ReactNode; title: string };

// 區段包裝：把區段標題import進來，跟內容一起包成一個區段
export default function SectionContainerFull({ children, title }: SectionContainerFullProps) {
  return <SectionLabel title={title}>{children}</SectionLabel>;
}
