import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
  Grid,
  Image,
  GridItem,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import fetchAgency, { AgencyDetail } from '~/httpApi/agency/fetchAgency';
import useAuthingClient from '~/hooks/useAuthingClient';
import StatusTag from '~/components/StatusTag';
import VerifyStatusTag from '~/components/VerifyStatusTag';

import ModifyStatus from './components/ModifyStatus';
import ModifyInfo from './components/ModifyInfo';
import ModifyRemark from './components/ModifyRemark';
import UploadFile from './components/UploadFile';
import CompanyDetailSkeleton from './components/CompanyDetailSkeleton';
import fileIcon from '~/assets/icons/file.png';
import ResetPasswrod from './components/ResetPassword';

const formatIsUseApi = (value: string) => {
  if (value === '0') {
    return '否';
  }
  if (value === '1') {
    return '是';
  }
  return value;
};

const DetailBox = (props: { children: React.ReactNode }) => {
  return (
    <Box bgColor="white" borderRadius="base" boxShadow="base" w="full" px="10" py="4" mb="1">
      {props.children}
    </Box>
  );
};

const DetailBoxHeader = (props: { children: React.ReactNode }) => {
  return (
    <Box borderBottomWidth="1px" pb="4" borderColor="fill.gray-1">
      {props.children}
    </Box>
  );
};

const HeaderTitle = (props: { text: string }) => {
  return (
    <Heading color="#333333" fontSize="2xl" fontWeight="medium">
      {props.text}
    </Heading>
  );
};

const ItemLabel = (props: { text: string }) => {
  return (
    <Text fontSize="sm" color="fill.gray-6" fontWeight="normal">
      {props.text}
    </Text>
  );
};

const ItemTextValue = (props: { text?: string }) => {
  return (
    <Text fontSize="sm" color="text.blue" fontWeight="normal">
      {props.text || '-'}
    </Text>
  );
};

const CompanyDetail = () => {
  const { getIdToken } = useAuthingClient();
  const [detail, setDetail] = useState<AgencyDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const params = useParams<'id'>();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      fetch(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetch = async (id: string) => {
    setLoading(true);
    try {
      const token = await getIdToken();
      const agency = await fetchAgency({
        agencyId: id,
        token,
      });
      setDetail(agency);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/agency');
  };

  if (loading) {
    return (
      <Box>
        <Button variant="link" mb="1" size="sm" onClick={handleBack}>
          返回上一页
        </Button>
        <CompanyDetailSkeleton />
      </Box>
    );
  }

  if (detail === null) {
    return (
      <Box>
        <Button variant="link" mb="1" size="sm" onClick={handleBack}>
          返回上一页
        </Button>
        <DetailBox>
          <Text>no record...</Text>
        </DetailBox>
      </Box>
    );
  }

  return (
    <Box>
      <Button variant="link" mb="1" size="sm" onClick={handleBack}>
        返回上一页
      </Button>
      <DetailBox>
        <DetailBoxHeader>
          <HStack spacing={6}>
            <HeaderTitle text={detail.enterpriseName} />
            <HStack spacing={1}>
              <ItemLabel text="企业状态" />
              <StatusTag status={detail.status} />
            </HStack>
            <HStack spacing={1}>
              <ItemLabel text="企业认证" />
              <VerifyStatusTag status={detail.enterpriseStatus} />
            </HStack>
            <ModifyStatus
              detail={detail}
              onModifySuccess={(newDetail: AgencyDetail) => setDetail(newDetail)}
            />
          </HStack>
        </DetailBoxHeader>
        <VStack py="4" spacing={4} w="full">
          <Flex w="full" alignItems="center">
            <Box flex="1">
              <ItemLabel text="企业ID" />
              <ItemTextValue text={detail.id} />
            </Box>
            <Box flex="1">
              <ItemLabel text="注册日期" />
              <ItemTextValue
                text={
                  detail.registeredDate
                    ? format(parseISO(detail.registeredDate), 'yyyy-MM-dd HH:mm:ss')
                    : '-'
                }
              />
            </Box>
            <Box flex="1"></Box>
          </Flex>

          <Flex w="full" alignItems="center" justify="space-between" bgColor="fill.gray-1">
            <Text fontSize="md" color="fill.gray-9" fontWeight="normal" px="0.5">
              企业信息
            </Text>
            <ModifyInfo
              detail={detail}
              onModifySuccess={(newDetail: AgencyDetail) => setDetail(newDetail)}
            />
          </Flex>
          <Flex w="full" alignItems="center">
            <Box flex="1">
              <ItemLabel text="企业名称" />
              <ItemTextValue text={detail.enterpriseName} />
            </Box>
            <Box flex="1">
              <ItemLabel text="联系电话" />
              <ItemTextValue text={detail.contactPhone} />
            </Box>
            <Box flex="1">
              <ItemLabel text="联系人" />
              <ItemTextValue text={detail.contactName} />
            </Box>
          </Flex>
          <Flex w="full" alignItems="center">
            <Box flex="1">
              <ItemLabel text="联系地址" />
              <ItemTextValue text={detail.enterpriseAddress} />
            </Box>
            <Box flex="2">
              <ItemLabel text="所在城市" />
              <ItemTextValue text={detail.enterpriseCity} />
            </Box>
          </Flex>
          <Flex w="full" alignItems="center">
            <Box flex="auto">
              <ItemLabel text="申请API接口对接" />
              <ItemTextValue text={formatIsUseApi(detail.enterpriseapplyapi)} />
            </Box>
          </Flex>

          <Flex w="full" alignItems="center" justify="space-between" bgColor="fill.gray-1">
            <Text fontSize="md" color="fill.gray-9" fontWeight="normal" px="0.5">
              文本备注
            </Text>
            <ModifyRemark
              id={detail.id}
              onModifySuccess={(newDetail: AgencyDetail) => setDetail(newDetail)}
            />
          </Flex>
          <Flex w="full" alignItems="center">
            <Box flex="auto">
              <ItemTextValue text={detail.remark} />
            </Box>
          </Flex>
        </VStack>
      </DetailBox>
      <DetailBox>
        <DetailBoxHeader>
          <HeaderTitle text="账户信息" />
        </DetailBoxHeader>
        <Flex py="4" w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="账户" />
            <ItemTextValue text={detail.contactUserId} />
          </Box>
          <Box flex="1">
            <ItemLabel text="密码" />
            <HStack spacing={1}>
              <ItemTextValue text={detail.enterprisePassword} />
              <ResetPasswrod
                detail={detail}
                onModifySuccess={(newDetail: AgencyDetail) => setDetail(newDetail)}
              />
            </HStack>
          </Box>
          <Box flex="1">
            <ItemLabel text="最后登入时间" />
            <ItemTextValue text={format(parseISO(detail.latestLoginTime), 'yyyy-MM-dd HH:mm:ss')} />
          </Box>
        </Flex>
      </DetailBox>
      <DetailBox>
        <DetailBoxHeader>
          <Flex alignItems="center" justify="space-between">
            <HeaderTitle text="企业认证" />
            <UploadFile detail={detail} onModifySuccess={() => params.id && fetch(params.id)} />
          </Flex>
        </DetailBoxHeader>
        <Box py="4" w="full">
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {detail.enterpriseCertifications.map((certItem, idx) => {
              return (
                <GridItem key={`${certItem.assetId}-${idx}`} w="100%">
                  <Flex alignItems="center">
                    <Image src={fileIcon} w="18px" h="18px" mr="1" />
                    <Link href={certItem.url} target="_blank">
                      <Text
                        fontSize="sm"
                        color="text.blue"
                        fontWeight="normal"
                        textDecoration="underline"
                      >
                        {certItem.fileName}
                      </Text>
                    </Link>
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </DetailBox>
    </Box>
  );
};

export default CompanyDetail;
