import { Box, Text, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

export type SectionLabelProps = { children?: React.ReactNode; title: string };

// 區段標題：把區段標題import進來，跟內容一起包成一個區段
export default function SectionLabel({ children, title }: SectionLabelProps) {
  return (
    <Box spacing={2} align="stretch">
      <Text fontSize="sm">{title}</Text>
      <Box w="full" mt={3} h="1px" bg="#333" />
      {children}
    </Box>
  );
}
