import { Box, Flex, Text } from '@chakra-ui/react';
import { LocalOrderDetail } from '~/types';
import { DetailBox, DetailBoxHeader, ItemLabel, ItemTextValue } from '.';
import SubTitle from './SubTitle';

const BoxMessageDetail = ({ orderDetail }: { orderDetail: LocalOrderDetail }) => {
  return (
    <DetailBox>
      <DetailBoxHeader>
        <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
          信息详情
        </Text>
      </DetailBoxHeader>
      <SubTitle title="企业信息" />
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="企业名称" />
            <ItemTextValue text={orderDetail.agencyname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="企业ID" />
            <ItemTextValue text={orderDetail.agencyid} />
          </Box>
          <Box flex="1">
            <ItemLabel text="联系电话" />
            <ItemTextValue text={orderDetail.agencyuserphone} />
          </Box>
        </Flex>
      </Box>
      <SubTitle title="供应商信息" />
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="供应商名称" />
            <ItemTextValue text={orderDetail.agencyname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="供应商马甲" />
            <ItemTextValue text={orderDetail.suppliercode} />
          </Box>
          <Box flex="1">
            <ItemLabel text="供应商单号" />
            <ItemTextValue text={orderDetail.supplierorderid} />
          </Box>
        </Flex>
      </Box>
    </DetailBox>
  );
};

export default BoxMessageDetail;
