import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import { RoomInfo } from '~/types';

export type RoomDetailProps = {
  index: number;
  roomInfo: {
    guests: {
      name: {
        firstName: string;
        lastName: string;
      };
      nationality: string;
    }[];
  };
};

//房間入住人詳細資料
export default function RoomDetail({ index, roomInfo }: RoomDetailProps) {
  const { guests } = roomInfo;

  return (
    <VStack
      key={`room-detail-${index}`}
      spacing={0}
      align="stretch"
      p={0}
      borderColor="gray.500"
      borderWidth="1px"
    >
      <Box w="full" bg="gray.500" py={2} px={4}>
        <Text fontSize="sm" fontWeight="bold">
          房间 {index + 1}
        </Text>
      </Box>
      <VStack spacing={2} align="stretch">
        <SimpleGrid
          columns={1}
          align="stretch"
          p={4}
          spacingX={28}
          spacingY={5}
          justify="space-between"
        >
          {guests.map(({ name: { lastName, firstName }, nationality = 'CN' }, index) => (
            <SimpleGrid
              key={`room-detail-guests-${index}`}
              columns={2}
              align="stretch"
              spacing={28}
              spacingY={5}
              justify="space-between"
            >
              <SimpleGrid columns={2} align="stretch" spacingX={3} justify="space-between">
                <Text fontSize="sm">入住人姓名 {index + 1}</Text>
                <Text fontSize="sm">
                  {firstName}/{lastName}
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={2} align="stretch" spacingX={3} justify="space-between">
                <Text fontSize="sm">国籍</Text>
                <Text fontSize="sm">{nationality}</Text>
              </SimpleGrid>
            </SimpleGrid>
          ))}
        </SimpleGrid>
      </VStack>
    </VStack>
  );
}
