import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import { fakeOrder } from '~/pages/fakeDate';

import SectionContainerFull from '~/components/SectionContainerFull';
import { OrderDetail } from '~/types';

type ContactInfoProp = {
  order: OrderDetail;
};

//聯繫人信息
export default function ContactInfo({ order }: ContactInfoProp) {
  const { contactname = '', contactphone = '', contactemail = '' } = order;
  const contactnameAry = contactname.split('/');
  const firstName = contactnameAry[0];
  const lastName = contactnameAry[1];

  return (
    <SectionContainerFull title="联系人信息">
      <SimpleGrid columns={2} align="stretch" spacing={28} p={4} justify="space-between">
        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">订单联系人</Text>
          <Text fontSize="sm">
            {firstName}
            {lastName}
          </Text>
          <Text fontSize="sm">邮箱</Text>
          <Text fontSize="sm">{contactemail}</Text>
        </SimpleGrid>

        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">联系方式</Text>
          <Text fontSize="sm">{contactphone}</Text>
        </SimpleGrid>
      </SimpleGrid>
    </SectionContainerFull>
  );
}
