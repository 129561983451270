import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import formatPriceNum from '~/utils/formatPriceNum';
import { SectionContainerWithStack } from '~/components/SectionContainer';
import { OrderDetail } from '~/types';

type QuotationDetailInfoProps = {
  order: OrderDetail;
};

//交易详情
export default function QuotationDetailInfo({ order }: QuotationDetailInfoProps) {
  const { ordermargin, currencyrate = '' } = order;

  return (
    <SectionContainerWithStack title="交易详情">
      <SimpleGrid columns={2} align="stretch" spacing={8} p={4} justify="space-between">
        <HStack>
          <Text fontSize="sm" minW="8rem">
            利润
          </Text>
          <Text fontSize="sm">{ordermargin && formatPriceNum(ordermargin)}</Text>
        </HStack>

        <HStack>
          <Text fontSize="sm" minW="8rem">
            订单汇率
          </Text>
          <Text fontSize="sm">{currencyrate}</Text>
        </HStack>
      </SimpleGrid>
    </SectionContainerWithStack>
  );
}
