import { Lang, Currency } from '~/types';
export default function formatPriceNum(
  price: number,
  format: {
    lang: Lang;
    currency: Currency;
  } = { lang: Lang.zh_CN, currency: Currency.CNY },
): string {
  return price.toLocaleString(format.lang, {
    style: 'currency',
    currency: format.currency,
    minimumFractionDigits: 0,
  });
}
