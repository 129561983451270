import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { ORDER_PAYMENT_STATUS_DISPLAY_MAP, ORDER_PAYMENT_TYPE_DISPLAY_MAP } from '~/displayInfo';
import { LocalOrderDetail, OrderPaymentStatusType, OrderStatusType } from '~/types';
import { findOrderId, getMoneyFlg } from '~/utils/helper';
import { DetailBox, DetailBoxHeader, ItemLabel, ItemTextValue } from '.';

import OrderCancelButton from './OrderCancelButton';
import OrderConfirmFailButton from './OrderConfirmFailButton';
import OrderStatusButton from './OrderStatusButton';

const formatCancelPolicy = (policyStr: string, moneyFlag: string) => {
  try {
    const policy = JSON.parse(policyStr);
    if (policy?.[0]) {
      const amount = policy[0]?.Amount;
      const date = policy[0]?.FromDate;
      return `${date} 之前扣除 ${moneyFlag}${amount}`;
    } else {
      return policyStr;
    }
  } catch (error) {
    return policyStr;
  }
};

const BoxOrderStatus = ({
  orderDetail,
  onModifySuccess,
}: {
  orderDetail: LocalOrderDetail;
  onModifySuccess: (order: LocalOrderDetail | null) => void;
}) => {
  const { paymentstatus, paymenttype, cancelpolicy, agencycurrency, status } = orderDetail;
  const moneyFlag = getMoneyFlg(agencycurrency);

  // 当订单状态’已取消’支付状态’退款中’时，人员需有核对订单退款金额并完成步骤 => 【确认失败】按钮
  const isShowConfirmFailButton =
    status === OrderStatusType.CANCELLED && paymentstatus === OrderPaymentStatusType.REFUNDING;

  const isShowCancelButton =
    status === OrderStatusType.PENDING ||
    status === OrderStatusType.PROCESSING ||
    status === OrderStatusType.SUCCESS;

  return (
    <DetailBox>
      <DetailBoxHeader>
        <Flex align="center" justify="space-between">
          <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
            订单号 {findOrderId(orderDetail.id)}
          </Text>

          <HStack>
            <OrderStatusButton status={status} />
            {isShowConfirmFailButton && (
              <OrderConfirmFailButton orderDetail={orderDetail} onModifySuccess={onModifySuccess} />
            )}
            {isShowCancelButton && (
              <OrderCancelButton orderDetail={orderDetail} onModifySuccess={onModifySuccess} />
            )}
          </HStack>
        </Flex>
      </DetailBoxHeader>
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="支付状态" />
            <ItemTextValue
              text={ORDER_PAYMENT_STATUS_DISPLAY_MAP[paymentstatus] || paymentstatus}
            />
          </Box>
          <Box flex="1">
            <ItemLabel text="支付方式" />
            <ItemTextValue text={ORDER_PAYMENT_TYPE_DISPLAY_MAP[paymenttype] || paymenttype} />
          </Box>
          <Box flex="1">
            <ItemLabel text="取消政策" />
            <ItemTextValue text={formatCancelPolicy(cancelpolicy, moneyFlag)} />
          </Box>
        </Flex>
      </Box>
    </DetailBox>
  );
};
export default BoxOrderStatus;
