import { useMemo } from 'react';
import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import { fakeOrder } from '~/pages/fakeDate';

import SectionContainerFull from '~/components/SectionContainerFull';
import formatPriceNum from '~/utils/formatPriceNum';
import { Currency, OrderDetail } from '~/types';

import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parseDate from 'date-fns/parse';
import differenceInDays from 'date-fns/differenceInDays';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

type HotelInfoProps = {
  order: OrderDetail;
};

type cancelPolicy = {
  currency?: Currency;
  endAt?: number | string;
  refund?: number;
  percent?: number;
  Amount?: number;
  FromDate?: string;
}[];

//酒店信息
export default function HotelInfo({ order }: HotelInfoProps) {
  const {
    hotelname = '',
    hoteladdress = '',
    specificrequest = '',
    checkindate = null,
    checkoutdate = null,
    cancelpolicy = '',
    breakfasts = '',
    agencytotalprice,
  } = order;

  const daysBetween =
    checkindate && checkoutdate
      ? differenceInDays(parseISO(checkoutdate), parseISO(checkindate))
      : '';

  const parseCancelPolicy = cancelpolicy ? JSON.parse(cancelpolicy) : null;
  console.log('parseCancelPolicy', parseCancelPolicy);

  return (
    <SectionContainerFull title="酒店信息">
      <SimpleGrid columns={2} align="stretch" spacing={28} justify="space-between" p={4}>
        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">酒店名称</Text>
          <Text fontSize="sm">{hotelname}</Text>
          <Text fontSize="sm">酒店地址</Text>
          <Text fontSize="sm">{hoteladdress}</Text>
          <Text fontSize="sm">特殊需求</Text>
          <Text fontSize="sm">{specificrequest}</Text>
          <Text fontSize="sm">退房政策</Text>
          <VStack align="flex-start">
            {parseCancelPolicy &&
              (parseCancelPolicy as cancelPolicy).map((policy, index) => {
                const cancelDate = policy.FromDate
                  ? parseDate(policy.FromDate, 'yyyy-MM-dd HH:mm:ss', new Date())
                  : typeof policy.endAt === 'number'
                  ? new Date(policy.endAt)
                  : policy.endAt
                  ? parseISO(policy.endAt)
                  : new Date();
                const cancelFee = policy.refund || policy.Amount || agencytotalprice;
                return (
                  <Text fontSize="sm" key={`cancel-policy-${index}`}>{`${formatDate(
                    cancelDate,
                    'yyyy-MM-dd HH:mm',
                  )}之前${
                    cancelFee === 0 ? '可免費取消' : `取消扣除${formatPriceNum(cancelFee)}`
                  }`}</Text>
                );
              })}
          </VStack>
        </SimpleGrid>

        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">入住日期</Text>
          <Text fontSize="sm">
            {checkindate && formatDate(parseISO(checkindate), 'yyyy-MM-dd')}
          </Text>
          <Text fontSize="sm">退房日期</Text>
          <Text fontSize="sm">
            {checkoutdate && formatDate(parseISO(checkoutdate), 'yyyy-MM-dd')}
          </Text>
          <Text fontSize="sm">入住晚数</Text>
          <Text fontSize="sm">{daysBetween}</Text>
          <Text fontSize="sm">早餐</Text>
          <Text fontSize="sm">
            {breakfasts && (parseInt(breakfasts) === 0 ? '不含早餐' : `含早餐 x ${breakfasts}`)}
          </Text>
        </SimpleGrid>
      </SimpleGrid>
    </SectionContainerFull>
  );
}
