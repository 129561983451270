import moment from 'moment';
import { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.scss';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { DateRangePicker, DateRangePickerShape, FocusedInputShape } from 'react-dates';

type IDateRangePicker = Omit<
  DateRangePickerShape,
  | 'endDate'
  | 'endDateId'
  | 'startDate'
  | 'startDateId'
  | 'focusedInput'
  | 'onFocusChange'
  | 'onDatesChange'
  | 'renderMonthText'
> & {
  value?: { startDate: moment.Moment | null; endDate: moment.Moment | null };
  onChange?: (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;
};

const WeekDaysMap = {
  Su: '日',
  Mo: '一',
  Tu: '二',
  We: '三',
  Th: '四',
  Fr: '五',
  Sa: '六',
};
type Weekdays = keyof typeof WeekDaysMap;

function DateRangePickerWrapper(props: IDateRangePicker) {
  const { value, onChange, ...rest } = props;
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const handleFocusChange = (focusedInput: FocusedInputShape | null) =>
    setFocusedInput(focusedInput);

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    props.onChange?.({ startDate, endDate });
  };

  function renderDayContents(day: moment.Moment) {
    return (
      <div className="day-wrapper">
        <span className="selected-background" />
        <span className="day-content">{day.format('D')}</span>
      </div>
    );
  }

  return (
    <div className={'dateRangePicker' + (focusedInput ? ' focused' : '')}>
      <DateRangePicker
        {...rest}
        noBorder
        daySize={48}
        endDate={props.value?.endDate || null}
        startDate={props.value?.startDate || null}
        endDateId="endDateId"
        startDateId="startDateId"
        hideKeyboardShortcutsPanel
        focusedInput={focusedInput}
        monthFormat="YYYY [年] MM [月]"
        onFocusChange={handleFocusChange}
        onDatesChange={handleDatesChange}
        displayFormat="YYYY[年]MM[月]DD[日]"
        renderDayContents={renderDayContents}
        renderWeekHeaderElement={(day) => (
          <span style={{ color: '#B9C1CF' }}>{WeekDaysMap[day as Weekdays]}</span>
        )}
        navPrev={
          <div className="navIcon prev">
            <ChevronLeftIcon w="24px" h="24px" color="#002C5E" />
          </div>
        }
        navNext={
          <div className="navIcon next">
            <ChevronRightIcon w="24px" h="24px" color="#002C5E" />
          </div>
        }
        customArrowIcon={
          <div style={{ width: '15px', height: '1px', backgroundColor: '#859EB9' }} />
        }
      />
    </div>
  );
}

export default DateRangePickerWrapper;
