import { DetailBox, DetailBoxHeader } from '.';
import SubTitle from './SubTitle';
import { Box, Flex, Text } from '@chakra-ui/react';
import { OrderDetail } from '~/types';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { getMoneyFlg } from '~/utils/helper';
import formatPriceNum from '~/utils/formatPriceNum';

const calculateDayList = (checkindate: string, checkoutdate: string) => {
  const list = eachDayOfInterval({
    start: parseISO(checkindate),
    end: parseISO(checkoutdate),
  });
  if (list.length > 1) {
    return list.slice(0, list.length - 1);
  } else {
    return list;
  }
};

const BoxOrderBill = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  const {
    checkindate,
    checkoutdate,
    agencydiscount,
    agencycurrency,
    agencytotalprice,
    agencysumprice,
    supplierdaliyprice,
    roomnum,
  } = orderDetail;

  const daysList = calculateDayList(checkindate, checkoutdate);
  const moneyFlag = getMoneyFlg(agencycurrency);
  const price = supplierdaliyprice
    ? formatPriceNum(parseInt(supplierdaliyprice))
    : supplierdaliyprice;

  return (
    <DetailBox>
      <DetailBoxHeader>
        <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
          订单明细
        </Text>
      </DetailBoxHeader>
      <SubTitle title="日期" />
      <Box borderBottomWidth="1px">
        {daysList.map((day, idx) => {
          return (
            <Flex pl="1" pr="2" color="text.blue" my="2.5" key={idx} alignItems="center" w="full">
              <Text fontSize="sm" flex="1">
                {format(day, 'yyyy-MM-dd')}
              </Text>
              <Flex flex="1" alignItems="center">
                <Text fontSize="sm" flex="auto">
                  {price} × {roomnum}
                </Text>
                <Text fontSize="sm">{price}</Text>
              </Flex>
            </Flex>
          );
        })}
      </Box>
      <Box>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              支付货币
            </Text>
            <Text color="text.blue" fontSize="sm">
              {agencycurrency}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              交易总额
            </Text>
            <Text color="text.blue" fontSize="sm">
              {agencysumprice ? `${moneyFlag}${agencysumprice}` : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="sm" flex="auto">
              优惠金额
            </Text>
            <Text color="text.blue" fontSize="sm">
              {agencydiscount ? `${moneyFlag}${agencydiscount}` : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex pl="1" pr="2" color="text.blue" my="2.5" alignItems="center" w="full">
          <Box flex="1" />
          <Flex flex="1" alignItems="center">
            <Text color="fill.gray-6" fontSize="md" fontWeight="medium" flex="auto">
              实际支付金额
            </Text>
            <Text color="text.blue" fontSize="lg" fontWeight="medium">
              {agencytotalprice ? `${moneyFlag}${agencytotalprice}` : '-'}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </DetailBox>
  );
};

export default BoxOrderBill;
