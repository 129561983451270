const colors = {
  primary: "#3E4D97",

  gray: {
    300: "#F5F5F5",
    500: "#E4E4E4",
    700: "#8D8D8D",
  },
  light: "#EFF3FC",
  fontColor: "#000000",
  fontColorDark: "#7F7F7F",
  fontColorError: "#FE5D6D",
  text: {
    white: "#ffffff",
    blue: "#002C5E",
    lightBlue: "#859EB9",
    red: "#FE5D6D",
  },
  fill: {
    "gray-1": "#EBEEF5",
    "gray-3": "#D7D7D7",
    "gray-6": "#8B8B8B",
    "gray-9": "#4A4A4A",
    "blue-1": "#B9C1CF",
    "blue-3": "#859EB9",
    "blue-6": "#2D6CDF",
    white: "#ffffff",
    yellow: "#F0D43A",
    cyan: "#59CFD5",
    red: "#FE5D6D",
  },
};

export default colors;
