import {
  Text,
  VStack,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Button,
} from '@chakra-ui/react';

type NotcieModalProps = {
  isOpen: boolean;
  title: string;
  message: string;
  buttonText: string;
  onClose: () => void;
  onClick: () => void;
};

export default function NoticeModal({
  isOpen,
  title,
  message,
  buttonText,
  onClick,
  onClose,
}: NotcieModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent w="290px" borderRadius="lg">
        <ModalHeader pb={1}>
          <Center>
            <Text color="#3F4E98" fontSize="2xl">
              {title}
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody pt={2} pb={4}>
          <Center>
            <VStack spacing={4}>
              <Text color="primary" textAlign="center">
                {message}
              </Text>
              <Button
                px={8}
                py={4}
                h="auto"
                bgColor="#6090E7"
                color="#fff"
                fontSize="lg"
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
