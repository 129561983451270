import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum BtnVariant {
  SOLID = 'solid',
  OUTLINE = 'outline',
  LINK = 'link',
}

const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'md',
    paddingInlineStart: '16px',
    paddingInlineEnd: '16px',
    paddingInline: '16px',
    _focus: {
      boxShadow: 'unset',
    },
  },
  sizes: {
    sm: {
      px: '2rem',
    },
    md: {
      px: '2rem',
    },
    lg: {
      px: '2rem',
    },
    xs: {
      px: '2rem',
    },
  },
  variants: {
    [BtnVariant.OUTLINE]: {
      bg: 'transparent',
      borderColor: '#B9C1CF',
      color: '#495C8C',
      fontWeight: 'normal',
      fontSize: 'sm',

      _hover: {
        bgColor: 'white',
        borderColor: '#495C8C',
        color: '#495C8C',
      },
      _active: {
        bgColor: '#E3E6F5;',
        color: '#495C8C',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
    [BtnVariant.SOLID]: {
      bgColor: 'text.blue',
      color: 'white',
      fontWeight: 'normal',
      fontSize: 'sm',

      _hover: {
        bgColor: '#495C8C',
        color: 'white',
        _disabled: {
          bgColor: '#495C8C',
        },
      },
      _active: {
        bgColor: 'fill.blue-6',
        color: 'white',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
    [BtnVariant.LINK]: {
      color: 'fill.gray-6',
      fontSize: 'sm',
      fontWeight: 'normal',
      height: '36px',
      _hover: {
        color: 'fill.gray-6',
      },
      _active: {
        color: 'fill.gray-9',
      },
    },
  },
};

export default Button;
