import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum InputVariants {
  OUTLINE = 'outline',
}

const Input: ComponentSingleStyleConfig = {
  baseStyle: {
    field: {
      borderColor: '#859EB9',
      border: '1px solid #859EB9',
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        color: '#8B8B8B',
      },
    },
  },
  variants: {
    outline: {
      focusBorderColor: 'primary',
      field: {
        color: 'primary',
        borderColor: '#859EB9',
        border: '1px solid #859EB9',
        backgroundColor: 'white',
        _hover: {
          borderColor: 'text.blue',
        },
        _focus: {
          borderColor: 'text.blue',
        },
      },
    },
  },

  defaultProps: {
    variant: 'outline',
    focusBorderColor: 'primary',
  },
};

export default Input;
