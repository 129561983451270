import {
  Pagination as AjnaPagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Input, Text, Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

interface IPaginationProps {
  total: number;
  currentPage: number;
  pageSize: number;
  getData: (pageNum: number, pageSize: number) => void;
}
export default function Pagination(props: IPaginationProps) {
  const { total, getData, currentPage, pageSize = 10 } = props;
  const { offset, pagesCount, pages, setPageSize, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      currentPage: 1,
      pageSize: 10,
    },
  });

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
    setCurrentPage(1);
    getData(1, pageSize);
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(+nextPage);
    getData(nextPage, pageSize);
  };

  return (
    <Box pt="4" pb="2">
      <Flex alignItems="center" justify="space-between">
        <HStack>
          <Text fontSize="md" color="fill.gray-6">
            {offset + 1}-{offset + pageSize}条
          </Text>
          <Text fontSize="lg" color="fill.gray-9">
            共有{total}条
          </Text>
        </HStack>

        <HStack spacing={4}>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            bgColor="white"
            border="none"
            color="#859EB9"
            h="32px"
            borderRadius="2px"
            fontSize="sm"
          >
            <option value="10">每页10条</option>
            <option value="20">每页20条</option>
            <option value="50">每页50条</option>
          </Select>
          <AjnaPagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          >
            <PaginationContainer align="center" justify="space-between">
              <PaginationPrevious
                bgColor="white"
                color="#859EB9"
                mr="10px"
                w="30px"
                h="32px"
                p="0"
                borderRadius="2px"
                minW="30px"
              >
                <ChevronLeftIcon />
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                spacing="10px"
                separator={
                  <PaginationSeparator
                    fontSize="sm"
                    w="30px"
                    h="32px"
                    borderRadius="2px"
                    bgColor="white"
                    color="#859EB9"
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w="30px"
                    h="32px"
                    borderRadius="2px"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="sm"
                    bgColor="white"
                    color="#859EB9"
                    _current={{
                      bgColor: '#002C5E',
                      color: 'white',
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                bgColor="white"
                color="#859EB9"
                ml="10px"
                w="30px"
                h="32px"
                p="0"
                borderRadius="2px"
                minW="30px"
              >
                <ChevronRightIcon />
              </PaginationNext>
            </PaginationContainer>
          </AjnaPagination>
          <HStack spacing={1}>
            <Text fontSize="sm" color="text.lightBlue" whiteSpace="nowrap">
              跳至
            </Text>
            <Input
              w="30px"
              h="32px"
              border="none"
              borderRadius="2px"
              px={0}
              fontSize="sm"
              textAlign="center"
              type="number"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  const currentPage = +(e.target as any).value;

                  setCurrentPage(currentPage);
                  getData(currentPage, pageSize);
                }
              }}
            />
            <Text fontSize="sm" color="text.lightBlue">
              页
            </Text>
          </HStack>
        </HStack>
      </Flex>
    </Box>
  );
}
