import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import CompanyStatusSelect from '~/pages/CompanyList/components/CompanyStatusSelect';
import CompanyVerifySelect from '~/pages/CompanyList/components/CompanyVerifySelect';
import useAuthingClient from '~/hooks/useAuthingClient';
import { AgencyDetail } from '~/httpApi/agency/fetchAgency';
import patchAgency from '~/httpApi/agency/patchAgency';
import { CertificationType, StatusType } from '~/types';

const ModifyStatus = (props: {
  detail: AgencyDetail;
  onModifySuccess: (data: AgencyDetail) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState<StatusType | undefined>(props.detail.status);
  const [verifyStatus, setVerifyStatus] = useState<CertificationType | undefined>(
    props.detail.enterpriseStatus,
  );
  const { getIdToken } = useAuthingClient();

  const handleChangeStatus = (status?: StatusType) => {
    setStatus(status);
  };

  const handleChangeVerifyStatus = (status?: CertificationType) => {
    setVerifyStatus(status);
  };

  const handleSave = async () => {
    try {
      const token = await getIdToken();
      const data = await patchAgency({
        token,
        agencyId: props.detail.id,
        updateInfo: {
          status,
          enterpriseStatus: verifyStatus,
        },
      });
      if (data) {
        props.onModifySuccess(data);
      }
      onClose();
    } catch (error) {}
  };

  return (
    <>
      <Button
        color="fill.blue-6"
        fontSize="sm"
        fontWeight="normal"
        variant="ghost"
        size="sm"
        px="3"
        onClick={onOpen}
      >
        修改状态
      </Button>
      <Modal size="sm" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
            编辑状态
          </ModalHeader>
          <ModalBody py="0">
            <VStack spacing="4" w="full">
              <VStack spacing="2" w="full" alignItems="start">
                <Text>企业状态</Text>
                <CompanyStatusSelect
                  isLimit
                  minW="full"
                  status={status}
                  onChange={handleChangeStatus}
                />
              </VStack>
              <VStack spacing="2" w="full" alignItems="start">
                <Text>企业状态</Text>
                <CompanyVerifySelect
                  isLimit
                  minW="full"
                  status={verifyStatus}
                  onChange={handleChangeVerifyStatus}
                />
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter py="8" justifyContent="center">
            <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
              取消
            </Button>
            <Button variant="solid" mx="2" px="4" py="2.5" onClick={handleSave}>
              保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModifyStatus;
