export const findOrderId = (id: string) => {
  if (!id) {
    return '';
  }
  return id.split('#')?.[1] || id;
};

export const getMoneyFlg = (type: string) => {
  if (type?.toLocaleUpperCase() === 'CNY') {
    return '￥';
  }
  if (type?.toLocaleUpperCase() === 'USD') {
    return '$';
  }
  return '';
};
