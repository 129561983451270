import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  FormControl,
  FormErrorMessage,
  Textarea,
  useBoolean,
} from '@chakra-ui/react';
import { useState } from 'react';
import useAuthingClient from '~/hooks/useAuthingClient';
import { AgencyDetail } from '~/httpApi/agency/fetchAgency';
import patchAgency from '~/httpApi/agency/patchAgency';

interface IFormValue {
  value: string;
  isError: boolean;
}
const initialFormValue = {
  value: '',
  isError: false,
};

interface Props {
  id: string;
  onModifySuccess: (data: AgencyDetail) => void;
}

const ModifyRemark = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [remark, setRemark] = useState<IFormValue>(initialFormValue);
  const [isLoading, setIsLoading] = useBoolean();
  const { getIdToken } = useAuthingClient();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setRemark({
      value,
      isError: value === '',
    });
  };

  const handleSubmit = async () => {
    if (remark.isError || isLoading) {
      return;
    }

    setIsLoading.on();
    try {
      const token = await getIdToken();
      const data = await patchAgency({
        token,
        agencyId: props.id,
        updateInfo: {
          remark: remark.value,
        },
      });

      console.log(data);

      data && props.onModifySuccess(data);
      setIsLoading.off();
      onClose();
    } catch (error) {
      console.log(error);
      setIsLoading.off();
    }
  };

  return (
    <>
      <Box
        cursor="pointer"
        fontSize="sm"
        color="fill.blue-6"
        fontWeight="normal"
        px="2.5"
        py="1.5"
        onClick={onOpen}
      >
        编辑备注
      </Box>

      <Modal size="sm" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="lg" fontWeight="medium" pt="8">
            编辑备注
          </ModalHeader>
          <ModalBody py="0">
            <Text color="text.lightBlue" fontSize="sm" mb="1">
              最多输入300个字
              {remark.value.length > 0 ? `，已输入 ${remark.value.length}` : ''}
            </Text>
            <FormControl isInvalid={remark.isError}>
              <Textarea
                id="name"
                type="text"
                h={44}
                maxLength={300}
                value={remark.value}
                onChange={handleChange}
                placeholder="请输入"
              />
              {remark.isError ? <FormErrorMessage>请输入文本备注</FormErrorMessage> : null}
            </FormControl>
          </ModalBody>

          <ModalFooter py="8" justifyContent="center">
            <Button variant="outline" mx="2" px="4" py="2.5" onClick={onClose}>
              取消
            </Button>
            <Button
              isLoading={isLoading}
              variant="solid"
              mx="2"
              px="4"
              py="2.5"
              onClick={handleSubmit}
            >
              保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModifyRemark;
