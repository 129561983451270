import {
  Box,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Radio,
  RadioGroup,
  Input,
  Flex,
  Button,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import isEditAtom from '~/recoil/atom/isEdit';

import { Controller, useForm } from 'react-hook-form';

import type { OrderDetail } from '~/types';
import { OrderPaymentStatusType } from '~/types';

import { fakeOrder } from '~/pages/fakeDate';
import { SectionContainer } from '~/components/SectionContainer';

import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import {
  STATUS_DISPLAY_MAP,
  ORDER_PAYMENT_STATUS_DISPLAY_MAP,
  ORDER_PAYMENT_STATUS_LIST,
} from '~/displayInfo';

type OrderInfoProps = {
  order: OrderDetail;
  onUpdateOrder: (updateInfo: Partial<OrderDetail>) => void;
};

//訂單信息
export default function OrderInfo({ order, onUpdateOrder }: OrderInfoProps) {
  const [isEditPaymentType, setIsEditPaymentType] = useState<boolean>(false);
  const [isEditRefundNote, setIsEditRefundNote] = useState<boolean>(false);
  const [isEditNote, setIsEditNote] = useState<boolean>(false);
  const setIsEdit = useSetRecoilState(isEditAtom);

  //支付狀態
  const [paymentType, setPaymentType] = useState<OrderPaymentStatusType | string>('');
  //退款金額備註
  const [refundNote, setRefundNote] = useState<string>();
  //內部備註
  const [note, setNote] = useState<string>();

  const [isRefundNoteInvalid, setRefundNoteInvalid] = useState<boolean>(false);

  //修改 : 支付狀態
  const handleEditPaymentType = () => {
    setIsEditPaymentType(!isEditPaymentType);
  };

  //儲存 : 支付狀態
  const handleSavePaymentType = () => {
    onUpdateOrder({ paymentstatus: paymentType });

    setIsEditPaymentType(!isEditPaymentType);
  };

  //修改 : 退款金額備註
  const handleEditRefundNote = () => {
    setIsEditRefundNote(!isEditRefundNote);
  };

  //儲存 : 退款金額備註
  const handleSaveRefundNote = async () => {
    // check if refund not is a valid number
    const refundPrice = parseInt(refundNote || '0');
    if (isNaN(refundPrice)) {
      setRefundNoteInvalid(true);
      return;
    }
    setRefundNoteInvalid(false);

    onUpdateOrder({ refundamount: refundPrice });

    setIsEditRefundNote(!isEditRefundNote);
  };

  //修改 : 內部備註
  const handleEditNote = () => {
    setIsEditNote(!isEditNote);
  };

  //儲存 : 內部備註
  const handleSaveNote = async () => {
    onUpdateOrder({ remark: note });

    setIsEditNote(!isEditNote);
  };

  const {
    status = '',
    createdtime = null,
    channel = '',
    supplierorderid = '',
    paymenttime = null,
    paymentstatus = '',
    cancellingtime = null,
    refundamount = null,
    remark = '',
  } = order || {};

  useEffect(() => {
    if (isEditPaymentType || isEditRefundNote || isEditNote) {
      setIsEdit(true);
      return;
    }
    setIsEdit(false);
  }, [isEditPaymentType, isEditRefundNote, isEditNote, setIsEdit]);

  useEffect(() => {
    if (!order) {
      return;
    }

    const { paymentstatus, refundamount, remark } = order;
    setPaymentType(paymentstatus);
    setRefundNote(refundamount?.toString() || '');
    setNote(remark);
  }, [order]);

  return (
    <>
      {order && (
        <SectionContainer title="订单信息">
          <Text fontSize="sm">订单状态</Text>
          <Text fontSize="sm">{STATUS_DISPLAY_MAP[status]}</Text>
          <Text fontSize="sm">创建时间</Text>
          <Text fontSize="sm">
            {createdtime
              ? formatDate(
                  utcToZonedTime(parseISO(createdtime), 'Asia/Shanghai'),
                  'yyyy-MM-dd HH:mm:ss',
                )
              : null}
          </Text>
          <Text fontSize="sm">订单来源</Text>
          <Text fontSize="sm">{channel}</Text>
          <Text fontSize="sm">供应商单号</Text>
          <Text fontSize="sm">{supplierorderid}</Text>
          <Text fontSize="sm">支付时间</Text>
          <Text fontSize="sm">
            {paymenttime
              ? formatDate(
                  utcToZonedTime(parseISO(paymenttime), 'Asia/Shanghai'),
                  'yyyy-MM-dd HH:mm:ss',
                )
              : null}
          </Text>
          <Text fontSize="sm">支付状态</Text>
          {isEditPaymentType ? (
            <HStack align="center">
              <RadioGroup
                defaultValue={paymentstatus}
                value={paymentType}
                onChange={(e: OrderPaymentStatusType) => setPaymentType(e)}
              >
                <HStack spacing={2} align="center">
                  {ORDER_PAYMENT_STATUS_LIST.filter(
                    ({ value }) =>
                      value !== OrderPaymentStatusType.UNPAID &&
                      value !== OrderPaymentStatusType.PAID,
                  ).map(({ value, display }) => (
                    <Radio key={value} value={value}>
                      <Text fontSize="sm">{display}</Text>
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
              <Text
                color="primary"
                fontSize="xs"
                whiteSpace="nowrap"
                _hover={{ cursor: 'pointer' }}
                onClick={handleSavePaymentType}
              >
                保存
              </Text>
              <Text
                color="gray"
                fontSize="xs"
                whiteSpace="nowrap"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditPaymentType}
              >
                取消
              </Text>
            </HStack>
          ) : (
            <HStack align="center">
              <Text fontSize="sm">
                {paymentType ? ORDER_PAYMENT_STATUS_DISPLAY_MAP[paymentType] : ''}
              </Text>
              <Text
                color="primary"
                fontSize="xs"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditPaymentType}
              >
                编辑
              </Text>
            </HStack>
          )}
          <Text fontSize="sm">发起取消时间</Text>
          <Text fontSize="sm">
            {cancellingtime
              ? formatDate(
                  utcToZonedTime(parseISO(cancellingtime), 'Asia/Shanghai'),
                  'yyyy-MM-dd HH:mm:ss',
                )
              : null}
          </Text>
          <Text fontSize="sm">退款金额</Text>
          {isEditRefundNote ? (
            <HStack>
              <Input
                w="60%"
                size="xs"
                maxLength={10}
                value={refundNote}
                onChange={(e) => setRefundNote(e.target.value)}
                {...(isRefundNoteInvalid ? { borderColor: 'fontColorError' } : {})}
              />
              <Text
                color="primary"
                fontSize="xs"
                _hover={{ cursor: 'pointer' }}
                onClick={handleSaveRefundNote}
              >
                保存
              </Text>
              <Text
                color="gray"
                fontSize="xs"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditRefundNote}
              >
                取消
              </Text>
            </HStack>
          ) : (
            <HStack align="center">
              {refundNote && <Text fontSize="sm">{refundNote}</Text>}
              <Text
                color="primary"
                fontSize="xs"
                whiteSpace="nowrap"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditRefundNote}
              >
                编辑
              </Text>
            </HStack>
          )}
          <Text fontSize="sm">内部备注</Text>
          {isEditNote ? (
            <HStack>
              <Textarea
                w="60%"
                size="xs"
                value={note}
                noOfLines={4}
                maxLength={100}
                onChange={(e) => setNote(e.target.value)}
              ></Textarea>
              <Text
                color="primary"
                fontSize="xs"
                _hover={{ cursor: 'pointer' }}
                onClick={handleSaveNote}
              >
                保存
              </Text>
              <Text
                color="gray"
                fontSize="xs"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditNote}
              >
                取消
              </Text>
            </HStack>
          ) : (
            <HStack align="center">
              {note && (
                <Text fontSize="sm" wordBreak="break-all">
                  {note.split('\n').map((remarkToken) => (
                    <>
                      {remarkToken}
                      <br />
                    </>
                  ))}
                </Text>
              )}
              <Text
                color="primary"
                fontSize="xs"
                whiteSpace="nowrap"
                _hover={{ cursor: 'pointer' }}
                onClick={handleEditNote}
              >
                编辑
              </Text>
            </HStack>
          )}
        </SectionContainer>
      )}
    </>
  );
}
