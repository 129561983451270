import { Box, Text, VStack, HStack, SimpleGrid, Radio, RadioGroup, Input } from '@chakra-ui/react';

import QuotationDetail from './QuotationDetail';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import isEditAtom from '~/recoil/atom/isEdit';
import { OrderDetail, OrderPaymentStatusType, SupplierPaymentType } from '~/types';

import { SectionContainerWithStack } from '~/components/SectionContainer';
import { SUPPLIER_PAYMENT_STATUS_DISPLAY_MAP, SUPPLIER_PAYMENT_STATUS_LIST } from '~/displayInfo';

import arrayChunk from 'lodash/chunk';

import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import formatDateTz from 'date-fns-tz/format';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

type SupplierQuotationInfoProps = {
  order: OrderDetail;
  onUpdateOrder: (updateInfo: Partial<OrderDetail>) => void;
};

//供应商交易详情
export default function SupplierQuotationInfo({
  order,
  onUpdateOrder,
}: SupplierQuotationInfoProps) {
  const { supplierrateid = '', supplierpaymentdate = null, supplierpaymentstatus } = order;

  //控制编辑開關
  const [isEditDate, setIsEditDate] = useState<boolean>(false);
  const [isEditSupplierStatusType, setIsEditSupplierStatusType] = useState<boolean>(false);
  const setIsEdit = useSetRecoilState(isEditAtom);

  //预计結算日期
  const [selectedTime, setSelectedTime] = useState<string>(
    supplierpaymentdate
      ? formatDateTz(parseISO(supplierpaymentdate), 'yyyy-MM-dd', {
          timeZone: 'Asia/Shanghai',
        })
      : '',
  );

  //供应商结算状态
  const [supplierStatusType, setSupplierStatusType] = useState<SupplierPaymentType | string>('');

  useEffect(() => {
    if (isEditDate || isEditSupplierStatusType) {
      setIsEdit(true);
      return;
    }
    setIsEdit(false);
  }, [isEditDate, isEditSupplierStatusType, setIsEdit]);

  useEffect(() => {
    const { supplierpaymentdate, supplierpaymentstatus } = order;
    setSelectedTime(
      supplierpaymentdate
        ? formatDateTz(parseISO(supplierpaymentdate), 'yyyy-MM-dd', {
            timeZone: 'Asia/Shanghai',
          })
        : '',
    );
    setSupplierStatusType(supplierpaymentstatus);
  }, [order]);

  //修改 : 预计結算日期
  const handleEditDate = () => {
    setIsEditDate(!isEditDate);
  };

  //儲存 : 预计結算日期
  const handleSaveDate = () => {
    const utcDate = zonedTimeToUtc(selectedTime, 'Asia/Shanghai');

    onUpdateOrder({ supplierpaymentdate: formatISO(utcDate) });

    setIsEditDate(!isEditDate);
  };

  //修改 : 供应商结算状态
  const handleEditSupplierStatusType = () => {
    setIsEditSupplierStatusType(!isEditSupplierStatusType);
  };

  //儲存 : 供应商结算状态
  const handleSaveSupplierStatusType = () => {
    if (!supplierStatusType) {
      return;
    }

    onUpdateOrder({ supplierpaymentstatus: supplierStatusType });

    setIsEditSupplierStatusType(!isEditSupplierStatusType);
  };

  return (
    <SectionContainerWithStack title="供应商交易详情">
      <VStack align="stretch" spacing={5} pb={2}>
        <HStack w="full" spacing={28}>
          <HStack flex="1" spacing={3}>
            <Text fontSize="sm" flex="1">
              供应商Rate ID
            </Text>
            <Text fontSize="sm" flex="1">
              {supplierrateid}
            </Text>
          </HStack>
          <HStack flex="1" align="center" spacing={3}>
            <Text fontSize="sm" minW="8rem" flex="1">
              预计結算日期
            </Text>
            {isEditDate ? (
              <HStack flex="1" justify="flex-start">
                <Input
                  w="10rem"
                  value={selectedTime}
                  type="date"
                  border="1px solid #f7f7f7"
                  onChange={(e) => setSelectedTime(e.target.value)}
                />
                <Text
                  color="primary"
                  fontSize="xs"
                  whiteSpace="nowrap"
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleSaveDate}
                >
                  保存
                </Text>
                <Text
                  color="gray"
                  fontSize="xs"
                  whiteSpace="nowrap"
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleEditDate}
                >
                  取消
                </Text>
              </HStack>
            ) : (
              <HStack flex="1">
                {selectedTime && <Text fontSize="sm">{selectedTime}</Text>}
                <Text
                  color="primary"
                  fontSize="xs"
                  whiteSpace="nowrap"
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleEditDate}
                >
                  编辑
                </Text>
              </HStack>
            )}
          </HStack>
        </HStack>

        <HStack w="full" spacing={28}>
          <HStack flex="1" spacing={3}>
            <Text fontSize="sm" flex="1">
              供应商结算状态
            </Text>
            {isEditSupplierStatusType ? (
              <HStack flex="1" ml={30}>
                <RadioGroup
                  defaultValue={supplierpaymentstatus}
                  value={supplierStatusType}
                  onChange={(e: SupplierPaymentType) => setSupplierStatusType(e)}
                >
                  <VStack>
                    {arrayChunk(SUPPLIER_PAYMENT_STATUS_LIST, 3).map(
                      (supplierPaymentStatusChunk, index) => (
                        <HStack w="full" justifyContent="left">
                          {supplierPaymentStatusChunk.map(({ value, display }, index) => (
                            <Radio value={value}>
                              <Text fontSize="sm" whiteSpace="nowrap">
                                {display}
                              </Text>
                            </Radio>
                          ))}
                          {index === arrayChunk(SUPPLIER_PAYMENT_STATUS_LIST, 3).length - 1 && (
                            <HStack>
                              <Text
                                color="primary"
                                fontSize="xs"
                                whiteSpace="nowrap"
                                _hover={{ cursor: 'pointer' }}
                                onClick={handleSaveSupplierStatusType}
                              >
                                保存
                              </Text>
                              <Text
                                color="gray"
                                fontSize="xs"
                                whiteSpace="nowrap"
                                _hover={{ cursor: 'pointer' }}
                                onClick={handleEditSupplierStatusType}
                              >
                                取消
                              </Text>
                            </HStack>
                          )}
                        </HStack>
                      ),
                    )}
                  </VStack>
                </RadioGroup>
              </HStack>
            ) : (
              <HStack flex="1" align="center">
                <Text fontSize="sm">{SUPPLIER_PAYMENT_STATUS_DISPLAY_MAP[supplierStatusType]}</Text>
                <Text
                  color="primary"
                  fontSize="xs"
                  whiteSpace="nowrap"
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleEditSupplierStatusType}
                >
                  编辑
                </Text>
              </HStack>
            )}
          </HStack>
          <HStack flex="1"></HStack>
        </HStack>
      </VStack>
      <QuotationDetail order={order} />
    </SectionContainerWithStack>
  );
}
