import apiCallingInit from '~/httpApi/init';

import type { LocalOrderDetail } from '~/types';

const apiClientInstance = apiCallingInit();

type OrderUpdateResponse = {
  message: string;
  order: LocalOrderDetail;
};

type UpdateOrderInput = {
  token: string;
  type: string;
  orderId: string;
  updateInfo: Partial<LocalOrderDetail>;
};

const updateLocalOrder = async ({
  token,
  orderId,
  updateInfo,
}: UpdateOrderInput): Promise<LocalOrderDetail | null> => {
  try {
    const orderResponse = await apiClientInstance.patch<OrderUpdateResponse>(
      `/tourOrder/${encodeURIComponent(orderId)}`,
      updateInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return orderResponse.data.order;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};

export default updateLocalOrder;
