import apiCallingInit from '~/httpApi/init';

const apiClientInstance = apiCallingInit();

export interface IProvince {
  code: string;
  name: string;
  sonCitys: Array<ICity>;
}

export interface ICity {
  code: string;
  name: string;
}

export type ICityList = {
  provinces: IProvince[];
};

type GetAgencyInput = {
  token: string;
};

const getCityList = async ({ token }: GetAgencyInput): Promise<ICityList | null> => {
  try {
    const response = await apiClientInstance.get<ICityList>(`/queryCityList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);

    return null;
  }
};

export default getCityList;
