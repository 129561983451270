import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { CertificationType } from '~/types';

const verificationStatusDisplayMapping = {
  [CertificationType.UNVERIFIED]: '未认证',
  [CertificationType.VERIFYING]: '认证中',
  [CertificationType.VERIFIED]: '已认证',
};

const status2Label = (status?: CertificationType) => {
  if (!status) {
    return '所有企业认证';
  }
  return verificationStatusDisplayMapping[status] || status;
};

const CompanyVerifySelect = (props: {
  minW?: string;
  status?: CertificationType;
  onChange: (status?: CertificationType) => void;
  isLimit?: boolean;
}) => {
  return (
    <Menu matchWidth>
      <MenuButton minW={props.minW || '200px'}>
        <InputGroup>
          <InputRightElement pointerEvents="none" children={<ChevronDownIcon />} />
          <Input value={status2Label(props.status)} readOnly />
        </InputGroup>
      </MenuButton>
      {props.isLimit ? (
        <MenuList>
          <MenuItem
            borderBottomWidth="1px"
            onClick={() => props.onChange(CertificationType.VERIFIED)}
          >
            已认证
          </MenuItem>
          <MenuItem
            borderBottomWidth="1px"
            onClick={() => props.onChange(CertificationType.UNVERIFIED)}
          >
            未认证
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem borderBottomWidth="1px" onClick={() => props.onChange()}>
            所有企业认证
          </MenuItem>
          <MenuItem
            borderBottomWidth="1px"
            onClick={() => props.onChange(CertificationType.VERIFIED)}
          >
            已认证
          </MenuItem>
          <MenuItem
            borderBottomWidth="1px"
            onClick={() => props.onChange(CertificationType.UNVERIFIED)}
          >
            未认证
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
};
export default CompanyVerifySelect;
