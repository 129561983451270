import { AxiosError } from 'axios';

import apiCallingInit from '~/httpApi/init';
import { HttpApiErrorCode } from '~/types';

const apiClientInstance = apiCallingInit();

export default async function memberUpdatePassword(input: {
  accessToken: string;
  oldPassword: string;
  newPassword: string;
}): Promise<{
  errorCode?: HttpApiErrorCode;
}> {
  const { accessToken, oldPassword, newPassword } = input;

  try {
    await apiClientInstance.post<any>(
      `/auth/updatepassword`,
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for member refresh token api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }

  return {};
}
