import { Box, Flex, Text } from '@chakra-ui/react';
import { differenceInDays, format, parseISO } from 'date-fns';
import { OrderDetail } from '~/types';
import { DetailBox, DetailBoxHeader, ItemLabel, ItemTextValue } from '.';
import RoomInfoList from './RoomInfoList';
import SubTitle from './SubTitle';

const formatDateRange = (start: string, end: string): string => {
  const startDate = format(parseISO(start), 'yyyy/MM/dd');
  const endDate = format(parseISO(end), 'yyyy/MM/dd');
  return `${startDate} - ${endDate}`;
};

const diffInDays = (start: string, end: string): string => {
  return differenceInDays(parseISO(end), parseISO(start)).toString();
};

const formatBedType = (hotelbedtype: string) => {
  let parsedHotelBedType: string = '';
  try {
    parsedHotelBedType = hotelbedtype ? JSON.parse(hotelbedtype) : [];
  } catch (error) {
    parsedHotelBedType = hotelbedtype;
  }
  if (Array.isArray(parsedHotelBedType)) {
    return parsedHotelBedType?.[0]?.desc;
  } else {
    return parsedHotelBedType;
  }
};

const BoxOrderDetail = ({ orderDetail }: { orderDetail: OrderDetail }) => {
  const { checkindate, checkoutdate, hotelbedtype } = orderDetail;

  return (
    <DetailBox>
      <DetailBoxHeader>
        <Text fontSize="2xl" letterSpacing={0} fontWeight="medium">
          订单详情
        </Text>
      </DetailBoxHeader>
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="酒店名称" />
            <ItemTextValue text={orderDetail.hotelname} />
          </Box>
          <Box flex="2">
            <ItemLabel text="酒店地址" />
            <ItemTextValue text={orderDetail.hoteladdress} />
          </Box>
        </Flex>
      </Box>
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="房型" />
            <ItemTextValue text={orderDetail.hotelroomtype} />
          </Box>
          <Box flex="1">
            <ItemLabel text="床型" />
            <ItemTextValue text={formatBedType(hotelbedtype)} />
          </Box>
          <Box flex="1">
            <ItemLabel text="餐型" />
            <ItemTextValue text={orderDetail.breakfasts} />
          </Box>
        </Flex>
      </Box>
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="房数" />
            <ItemTextValue text={orderDetail?.roomnum?.toString()} />
          </Box>
          <Box flex="1">
            <ItemLabel text="入住日期/退房日期" />
            <ItemTextValue text={formatDateRange(checkindate, checkoutdate)} />
          </Box>
          <Box flex="1">
            <ItemLabel text="入住晚数" />
            <ItemTextValue text={diffInDays(checkindate, checkoutdate)} />
          </Box>
        </Flex>
      </Box>

      <RoomInfoList orderDetail={orderDetail} />

      <SubTitle title="联系信息" />
      <Box py="4">
        <Flex w="full" alignItems="center">
          <Box flex="1">
            <ItemLabel text="联系人名称" />
            <ItemTextValue text={orderDetail.contactname} />
          </Box>
          <Box flex="1">
            <ItemLabel text="手机号" />
            <ItemTextValue text={orderDetail.contactphone} />
          </Box>
          <Box flex="1">
            <ItemLabel text="邮箱" />
            <ItemTextValue text={orderDetail.contactemail} />
          </Box>
        </Flex>
      </Box>
    </DetailBox>
  );
};
export default BoxOrderDetail;
