import apiCallingInit from '~/httpApi/init';

import * as queryString from 'query-string';
import {
  OrderListItem,
  OrderPaymentStatusType,
  OrderStatusType,
  SupplierPaymentType,
  SupplierType,
} from '~/types';

const apiClientInstance = apiCallingInit();

type ListOrdersResponse = {
  count: { count: number };
  orders: OrderListItem[];
};

type ListOrdersInput = {
  token: string;
  keyword?: string;
  createdAtStart?: string;
  createdAtEnd?: string;
  cancellingAtStart?: string;
  cancellingAtEnd?: string;
  checkInDateStart?: string;
  checkInDateEnd?: string;
  checkOutDateStart?: string;
  checkOutDateEnd?: string;
  status?: OrderStatusType;
  paymentStatus?: OrderPaymentStatusType;
  supplierPaymentStatus?: SupplierPaymentType;
  supplier?: SupplierType;
  size: number;
  page: number;
};

// const PAGE_SIZE = 10;

export default async ({ token, ...query }: ListOrdersInput): Promise<ListOrdersResponse> => {
  console.log({ token });

  const queryParameters = queryString.stringify(
    Object.fromEntries(Object.entries(query).filter(([_, value]) => !!value)),
  );

  try {
    const listOrdersResponse = await apiClientInstance.get<ListOrdersResponse>(
      `/order?${queryParameters}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return listOrdersResponse.data;
  } catch (error) {
    console.error('Calling list agencies api endpoint has error', error);
    return {
      orders: [],
      count: { count: 0 },
    };
  }
};
