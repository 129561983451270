import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { STATUS_DISPLAY_MAP } from '~/displayInfo';
import { OrderStatusType } from '~/types';

const RED = {
  bgColor: '#FFE9EA',
  color: '#FF6770',
};

const GRAY = {
  bgColor: '#E9E9E9',
  color: '#939393',
};

const GREEN = {
  bgColor: '#DFF5F1',
  color: '#41C7AF',
};

const getColorTheme = (status: OrderStatusType | string) => {
  if (status === OrderStatusType.PROCESSING || status === OrderStatusType.CANCELLING) {
    return RED;
  }
  if (status === OrderStatusType.SUCCESS) {
    return GREEN;
  }
  return GRAY;
};

const OrderStatusButton = ({ status }: { status: OrderStatusType | string }) => {
  const { bgColor, color } = getColorTheme(status);

  return (
    <Button
      variant="solid"
      size="sm"
      bgColor={bgColor}
      _hover={{ bgColor }}
      _active={{ bgColor }}
      borderRadius="full"
      pr="3"
      pl="4"
    >
      <HStack>
        <Box w="2.5" h="2.5" bgColor={color} borderRadius="full" />
        <Text color={color} fontSize="md" fontWeight="medium">
          {STATUS_DISPLAY_MAP[status] || status}
        </Text>
      </HStack>
    </Button>
  );
};

export default OrderStatusButton;
