import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import SectionContainerFull from '~/components/SectionContainerFull';
import { OrderDetail } from '~/types';

import RoomDetail from './RoomDetail';

type RoomInfoProps = {
  order: OrderDetail;
};

//房間及入住信息
export default function RoomInfo({ order }: RoomInfoProps) {
  const {
    guestnames = '',
    guestnationalities = '',
    adultcount,
    children,
    hotelroomtype,
    hotelbedtype,
  } = order;

  console.table({ hotelbedtype });

  let parsedHotelBedType: string;
  try {
    parsedHotelBedType = hotelbedtype ? JSON.parse(hotelbedtype) : [];
  } catch (error) {
    parsedHotelBedType = hotelbedtype;
  }
  const bedType = !Array.isArray(parsedHotelBedType)
    ? parsedHotelBedType
    : parsedHotelBedType.map(({ desc, count }: any) => `${desc} x ${count}`).join(', ');

  const childrenAry = children ? children.split('|') : null;

  const guestNamesAry = guestnames.split('|');
  const guestnationalitiesAry = guestnationalities.split('|');

  const roomsInfo = guestnames
    ? guestNamesAry.map((guestNames, index) => {
        const guestAry = guestNames.split('&');
        const nationalityAry = guestnationalitiesAry[index].split('&');
        return {
          guests: guestAry.map((guestName, index) => {
            const nameAry = guestName.split('/');
            return {
              name: {
                firstName: nameAry[0],
                lastName: nameAry[1],
              },
              nationality: nationalityAry[index],
            };
          }),
        };
      })
    : [];

  return (
    <SectionContainerFull title="房间及入住信息">
      <SimpleGrid columns={2} align="stretch" spacing={28} p={4} justify="space-between" mb={2}>
        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">间数</Text>
          <Text fontSize="sm">{guestnames && guestnames.split('|').length}间</Text>
          <Text fontSize="sm">入住人数</Text>
          <Text fontSize="sm">
            {adultcount}成人{' '}
            {childrenAry &&
              `${childrenAry.length}儿童(${childrenAry.map((age) => {
                if (parseInt(age) < 0) return null;
                return `${age}岁`;
              })})`}
          </Text>
        </SimpleGrid>
        <SimpleGrid columns={2} align="stretch" spacingX={3} spacingY={5} justify="space-between">
          <Text fontSize="sm">房型</Text>
          <Text fontSize="sm">{hotelroomtype}</Text>
          <Text fontSize="sm">床型</Text>
          <Text fontSize="sm">{bedType}</Text>
        </SimpleGrid>
      </SimpleGrid>
      <VStack spacing={8} align="stretch">
        {roomsInfo.map((roomInfo, index) => (
          <RoomDetail index={index} roomInfo={roomInfo} />
        ))}
      </VStack>
    </SectionContainerFull>
  );
}
