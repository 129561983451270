import {
  OrderPaymentStatusType,
  OrderPaymentType,
  OrderStatusType,
  SupplierPaymentType,
  SupplierType,
  TimeFilter,
} from '~/types';

type DisplayItem = {
  value: any;
  display: string;
};

export const TIME_FILTER_CONTENT: DisplayItem[] = [
  {
    value: TimeFilter.CREATED_AT,
    display: '订单创建时间',
  },
  {
    value: TimeFilter.CANCELLING_AT,
    display: '发起取消时间',
  },
  {
    value: TimeFilter.CHECK_IN,
    display: '入住日期',
  },
  {
    value: TimeFilter.CHECK_OUT,
    display: '离店日期',
  },
];

export const ORDER_STATUS_LIST: DisplayItem[] = [
  {
    display: '待付款',
    value: OrderStatusType.PENDING,
  },
  {
    display: '确认中',
    value: OrderStatusType.PROCESSING,
  },
  {
    display: '已确认',
    value: OrderStatusType.SUCCESS,
  },
  {
    display: '预定失败',
    value: OrderStatusType.FAILED,
  },
  {
    display: '取消中',
    value: OrderStatusType.CANCELLING,
  },
  {
    display: '已取消',
    value: OrderStatusType.CANCELLED,
  },
  {
    display: '已失效',
    value: OrderStatusType.INVALID,
  },
  {
    display: '已出行',
    value: OrderStatusType.USED,
  },
];

export const STATUS_DISPLAY_MAP: Record<string, string> = {
  [OrderStatusType.PENDING]: '待付款',
  [OrderStatusType.PROCESSING]: '待确认',
  [OrderStatusType.SUCCESS]: '已确认',
  [OrderStatusType.FAILED]: '预定失败',
  [OrderStatusType.CANCELLING]: '取消中',
  [OrderStatusType.CANCELLED]: '已取消',
  [OrderStatusType.INVALID]: '已失效',
  [OrderStatusType.USED]: '已出行',
};

export const ORDER_PAYMENT_STATUS_LIST: DisplayItem[] = [
  {
    value: OrderPaymentStatusType.UNPAID,
    display: '待付款',
  },
  {
    value: OrderPaymentStatusType.PAID,
    display: '已付款',
  },
  {
    value: OrderPaymentStatusType.REFUNDING,
    display: '退款中',
  },
  {
    value: OrderPaymentStatusType.REFUNDED,
    display: '已退款',
  },
];

export const ORDER_PAYMENT_STATUS_DISPLAY_MAP: Record<string, string> = {
  [OrderPaymentStatusType.UNPAID]: '待付款',
  [OrderPaymentStatusType.PAID]: '已付款',
  [OrderPaymentStatusType.REFUNDING]: '退款中',
  [OrderPaymentStatusType.REFUNDED]: '已退款',
};

export const ORDER_PAYMENT_TYPE_DISPLAY_MAP: Record<OrderPaymentType, string> = {
  [OrderPaymentType.WECHAT]: '微信',
  [OrderPaymentType.CREDITCARD]: '信用卡',
  [OrderPaymentType.ALIPAY]: '支付宝',
  [OrderPaymentType.BALANCE]: '余额',
  [OrderPaymentType.OTA]: 'OTA',
};

export const SUPPLIER_PAYMENT_STATUS_LIST: DisplayItem[] = [
  {
    value: SupplierPaymentType.PENDING,
    display: '待付款',
  },
  {
    value: SupplierPaymentType.PAID,
    display: '已付款',
  },
  {
    value: SupplierPaymentType.NO_NEED,
    display: '无需付款',
  },
  {
    value: SupplierPaymentType.ALL_REFUNDED,
    display: '已全额退款',
  },
  {
    value: SupplierPaymentType.PARTIALLY_REFUNDED,
    display: '已部分退款',
  },
];

export const SUPPLIER_PAYMENT_STATUS_DISPLAY_MAP: Record<string, string> = {
  [SupplierPaymentType.PENDING]: '待付款',
  [SupplierPaymentType.PAID]: '已付款',
  [SupplierPaymentType.NO_NEED]: '无需付款',
  [SupplierPaymentType.ALL_REFUNDED]: '已全额退款',
  [SupplierPaymentType.PARTIALLY_REFUNDED]: '已部分退款',
};

export const SUPPLIER_LIST: DisplayItem[] = [
  {
    value: SupplierType.DIDA,
    display: '道旅',
  },
  {
    value: SupplierType.RAKUTEN_CHINA,
    display: 'RAKUTEN 中国地区',
  },
  {
    value: SupplierType.RAKUTEN_JAPAN,
    display: 'RAKUTEN 日本直签',
  },
  {
    value: SupplierType.CTRIP_DIST,
    display: '携程',
  },
  {
    value: SupplierType.SHEN_JIE_LV,
    display: '深捷旅',
  },
];

export const SUPPLIER_DISPLAY_MAP: Record<string, string> = {
  [SupplierType.DIDA]: '道旅',
  [SupplierType.RAKUTEN_CHINA]: 'RAKUTEN 中国地区',
  [SupplierType.RAKUTEN_JAPAN]: 'RAKUTEN 日本直签',
  [SupplierType.CTRIP_DIST]: '携程',
  [SupplierType.SHEN_JIE_LV]: '深捷旅',
};
