import { Button, useDisclosure } from '@chakra-ui/react';

import useAuthingClient from '~/hooks/useAuthingClient';
import updateOrder from '~/httpApi/order/updateOrder';
import { OrderDetail, OrderStatusType } from '~/types';

import FailConfirmModal from './FailConfirmModal';

const OrderConfirmFailButton = (props: {
  onModifySuccess: (order: OrderDetail | null) => void;
  orderDetail: OrderDetail;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getIdToken } = useAuthingClient();

  const handleSubmit = async () => {
    const token = await getIdToken();
    const order = await updateOrder({
      token,
      type: '',
      orderId: props.orderDetail.id,
      updateInfo: {
        status: OrderStatusType.FAILED,
      },
    });
    props.onModifySuccess(order);
    onClose();
  };

  return (
    <>
      <Button
        variant="outline"
        borderColor="fill.blue-6"
        color="fill.blue-6"
        px="4"
        size="sm"
        fontSize="xs"
        onClick={onOpen}
      >
        确认失败
      </Button>
      <FailConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={handleSubmit} />
    </>
  );
};

export default OrderConfirmFailButton;
