import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Center,
  HStack,
  Flex,
  Box,
  VStack,
  Text,
  InputGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Image,
  SlideFade,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import Logo from '~/assets/images/loginLogo.png';
import { BtnVariant } from '~/customTheme/components/Button';

import useAuthingClient from '~/hooks/useAuthingClient';

import { Navigate } from 'react-router-dom';

type LoginData = {
  username: string;
  password: string;
};

const usernameError: { [key: string]: string } = {
  NONE: '',
  required: '请输入帐号',
  userNotFound: '无此使用者',
};

const passwordError: { [key: string]: string } = {
  NONE: '',
  required: '请输入密码',
  wrongPassword: '密码错误',
};

const Login = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleSubmit, control, setError, getValues } = useForm<LoginData>();

  const { checkIfLoggedIn } = useAuthingClient();
  const { login } = useAuthingClient();
  const toast = useToast();

  const onSubmit = async ({ username, password }: LoginData) => {
    try {
      await login({
        username,
        password,
      });
      navigate('/agency');
    } catch (error) {
      const { message } = error as { code: number; message: string };
      setError('username', {
        message: '',
      });
      setError('password', {
        message: '',
      });

      toast({
        position: 'top',
        status: 'error',
        title: message,
      });
    }
  };

  if (checkIfLoggedIn()) {
    return <Navigate to="/agency" replace={true} />;
  }

  return (
    <>
      <Center w="100vw" h="100vh">
        <Flex direction="column" align="center" w="500px" bg="white" p={16} pb={8}>
          <Image src={Logo} w="12.75rem" />
          <Box as="form" w="full" mt={6} onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={6} align="stretch" p={6}>
              <HStack align="center" spacing={4}>
                <HStack w="6rem">
                  <Text>用户名</Text>
                </HStack>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputGroup position="relative">
                      <Input
                        {...field}
                        type="username"
                        placeholder="请输入用户名 或 11位手机号"
                        isInvalid={!!error}
                        errorBorderColor="fontColorError"
                      />
                      <SlideFade
                        in={!!error}
                        offsetY="10px"
                        style={{ position: 'absolute', top: '40px' }}
                      >
                        <Text fontSize="xs" color="fontColorError">
                          {usernameError[error?.type || 'NONE']}
                        </Text>
                      </SlideFade>
                    </InputGroup>
                  )}
                />
              </HStack>

              <HStack align="center" spacing={4}>
                <HStack w="6rem">
                  <Text>登陆密码</Text>
                </HStack>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputGroup position="relative">
                      <Input
                        {...field}
                        type="password"
                        placeholder="请输入登录密码"
                        isInvalid={!!error}
                        errorBorderColor="fontColorError"
                      />
                      <SlideFade
                        in={!!error}
                        offsetY="10px"
                        style={{ position: 'absolute', top: '40px' }}
                      >
                        <Text fontSize="xs" color="fontColorError">
                          {passwordError[error?.type || 'NONE']}
                        </Text>
                      </SlideFade>
                    </InputGroup>
                  )}
                />
              </HStack>

              <Button
                type="submit"
                variant={BtnVariant.SOLID}
                w="full"
                bg="admin.primary"
                borderRadius="base"
              >
                登陆
              </Button>
              <HStack justify="flex-end" spacing={3}>
                <Text color="primary" fontSize="sm" _hover={{ cursor: 'pointer' }} onClick={onOpen}>
                  忘记密码
                </Text>
              </HStack>
            </VStack>
          </Box>
        </Flex>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="sm">
          <ModalHeader>
            <Center>忘记密码</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={4}>
            <Center>
              <VStack>
                <Text color="primary">请联系客服，将有专人接洽您的问题</Text>
                <Text color="primary">+86 13810248624</Text>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Login;
