const orderStatus = {
  ALL: {
    text: '全部订单状态',
    bgColor: '',
    color: '',
  },
  PENDING: {
    text: '待付款',
    bgColor: '#FFE9EA',
    color: '#FF6770',
  },
  IN_BOOKING: {
    text: '待确认',
    bgColor: '#FFE9EA',
    color: '#FF6770',
  },
  CANCELLING: {
    text: '取消中',
    bgColor: '#FFE9EA',
    color: '#FF6770',
  },
  SUCCESS: {
    text: '已确认',
    bgColor: '#DFF5F1',
    color: '#41C7AF',
  },
  USED: {
    text: '已完成',
    bgColor: '#DFF5F1',
    color: '#41C7AF',
  },
  FAILED: {
    text: '预定失败',
    bgColor: '#E9E9E9',
    color: '#939393',
  },
  INVALID: {
    text: '已失效',
    bgColor: '#E9E9E9',
    color: '#939393',
  },
  CANCELLED: {
    text: '已取消',
    bgColor: '#E9E9E9',
    color: '#939393',
  },
  CANCEL_FAILED: {
    text: '取消失败',
    bgColor: '#E9E9E9',
    color: '#939393',
  },
};

export type OrderStatusType = keyof typeof orderStatus;

export default orderStatus;
