import {
  Box,
  Heading,
  HStack,
  Text,
  VStack,
  Flex,
  Spacer,
  Icon,
  Link,
  Center,
  Input,
  Button,
  SimpleGrid,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import isEditAtom from '~/recoil/atom/isEdit';

import { AiOutlineLeft } from 'react-icons/ai';
import { BtnVariant } from '~/customTheme/components/Button';

import { useParams, useLocation, Link as ReachLink } from 'react-router-dom';
import useAuthingClient from '~/hooks/useAuthingClient';
import useFetchTimeout from '~/hooks/useFetchTimeout';

import type { OrderDetail } from '~/types';

import getOrder from '~/httpApi/order/getOrder';
import updateOrder from '~/httpApi/order/updateOrder';

import {
  OrderInfo,
  UserInfo,
  SupplierInfo,
  HotelInfo,
  RoomInfo,
  ContactInfo,
  RecordInfo,
} from './components';

import SupplierQuotationInfo from './components/SupplierQuotationInfo';
import AgencyQuotationInfo from './components/AgencyQuotationInfo';
import QuotationDetailInfo from './components/QuotationDetailInfo';
import LoadingModal from '~/components/LoadingModal';
import ErrorModal from '~/components/ErrorModal';
import NoticeModal from '~/components/NoticeModal';

// 訂單詳情頁：包括提供訂單所有資訊並且能夠编辑部分資訊（info）
export default function Order() {
  const [order, setOrder] = useState<OrderDetail | null>(null);

  const [isLoading, setLoading] = useState<boolean>(false);

  const isEdit = useRecoilValue(isEditAtom);

  const params = useParams();
  const { type, orderId } = params;

  const { getIdToken } = useAuthingClient();
  const {
    isOpen: isErrorModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const {
    isOpen: isNoticeModalOpen,
    onOpen: onOpenNoticeModal,
    onClose: onCloseNoticeModal,
  } = useDisclosure();

  useFetchTimeout(isLoading, onOpenErrorModal);

  useBlocker(onOpenNoticeModal, isEdit);

  const getOrderFromApi = async () => {
    setLoading(true);
    try {
      const token = await getIdToken();
      const order = await getOrder({
        token,
        orderId: orderId || '',
      });

      setOrder(order);
    } catch (error) {
      console.error('get order from api has error');
      setOrder(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('effect', orderId);

    if (!orderId) return;
    getOrderFromApi();
  }, [orderId]);

  console.log('order', JSON.stringify(order, null, 2));

  const handleUpdateOrder = async (updateInfo: Partial<OrderDetail>) => {
    // console.log('updateInfo', updateInfo);
    setLoading(true);
    try {
      const token = await getIdToken();
      const order = await updateOrder({
        token,
        type: type || '',
        orderId: orderId || '',
        updateInfo,
      });
      setOrder((prev) => order || prev);
    } catch (error) {
      console.error('update order has error', error);
    }

    setLoading(false);
  };

  return (
    <>
      <VStack p={8} bg="white" align="stretch" spacing={12}>
        {!order && (
          <Center>
            <Spinner size="lg" color="primary" />
          </Center>
        )}
        {order && (
          <>
            <HStack>
              <Center>
                <Icon as={AiOutlineLeft} boxSize={3} color="fontColorDark" />
                <Link as={ReachLink} color="fontColorDark" to="/orders" fontSize="sm">
                  返回上一页
                </Link>
              </Center>
            </HStack>
            <Flex>
              <HStack spacing={4}>
                <Heading size="md">
                  订单 {order && (order?.id?.split('#')?.[1] || order?.id)}
                </Heading>
              </HStack>
              <Spacer />
              <Link
                target="_blank"
                href={'/'}
                color="white"
                bgColor="primary"
                borderRadius="4px"
                fontSize="lg"
                px={8}
                py={3}
              >
                导出
              </Link>
            </Flex>

            <SimpleGrid columns={2} spacing={20}>
              <OrderInfo order={order} onUpdateOrder={handleUpdateOrder} />
              <VStack align="stretch" spacing={20}>
                <SupplierInfo order={order} />
                <UserInfo order={order} />
              </VStack>
            </SimpleGrid>
            <HotelInfo order={order} />
            <RoomInfo order={order} />
            <ContactInfo order={order} />
            <SupplierQuotationInfo order={order} onUpdateOrder={handleUpdateOrder} />
            <AgencyQuotationInfo order={order} />
            <QuotationDetailInfo order={order} />
            <RecordInfo order={order} />
          </>
        )}
      </VStack>
      <ErrorModal isOpen={isErrorModalOpen} onClose={onCloseErrorModal} />
      <NoticeModal
        isOpen={isNoticeModalOpen}
        title="请保存"
        message="进行修改中 不得跳转页面"
        buttonText="返回保存"
        onClose={onCloseNoticeModal}
        onClick={onCloseNoticeModal}
      />
    </>
  );
}
