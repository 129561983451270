import { atom } from 'recoil';

export type TMenuChildren = {
  title: string;
  path: string;
  active: boolean;
  key: string;
};

export type TMenuState = {
  icon: string;
  title: string;
  path: string;
  key: string;
  isOpen: boolean;
  active: boolean;
  children?: TMenuChildren[] | undefined;
};

export const menuState = atom<TMenuState[]>({
  key: 'menuState',
  default: [
    {
      icon: 'merchant',
      title: '首页',
      path: '/agency',
      key: 'home',
      isOpen: false,
      active: false,
    },
    {
      icon: 'merchant',
      title: '企业管理',
      path: '/agency',
      key: 'merchant',
      isOpen: false,
      active: false,
    },
    {
      icon: '',
      title: '订单管理',
      path: '/orders',
      key: 'orders',
      isOpen: false,
      active: false,
    },
    {
      icon: 'order',
      title: '订单管理(新)',
      isOpen: false,
      active: false,
      key: 'order',
      path: '',
      children: [
        {
          title: '酒店',
          path: '/hotel',
          active: false,
          key: '/hotel',
        },
        {
          title: '当地玩乐',
          path: '/localExperiences',
          active: false,
          key: '/localExperiences',
        },
      ],
    },
    {
      icon: 'order',
      title: '主题管理',
      path: '/theme',
      key: 'theme',
      isOpen: false,
      active: false,
    },
  ],
});
