import { Box, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react';

import { SectionContainer } from '~/components/SectionContainer';

import type { OrderDetail } from '~/types';

import { SUPPLIER_DISPLAY_MAP } from '~/displayInfo';

type SupplierInfoProps = {
  order: OrderDetail;
};

//供應商信息
export default function SupplierInfo({ order }: SupplierInfoProps) {
  const { suppliercode } = order;
  return (
    <SectionContainer title="供應商信息">
      <Text fontSize="sm">供应商</Text>
      <Text fontSize="sm">{SUPPLIER_DISPLAY_MAP[suppliercode]}</Text>
      <Text fontSize="sm">供应商马甲</Text>
      <Text fontSize="sm">{suppliercode}</Text>
    </SectionContainer>
  );
}
