import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      color: '#8B8B8B',
      textAlign: 'center',
      borderColor: '#859EB9',
      backgroundColor: '#fff',
      _hover: {
        borderColor: '#859EB9',
      },
      '> option': {
        height: '50px',
        minHeight: '2em',
        paddingTop: '10px',
        borderBottom: '1px solid #E2E8F0',
      },
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: '#859EB9',
        _hover: {
          borderColor: '#859EB9',
        },
      },
    },
  },
};

export default Select;
