import { AxiosError } from "axios";

import apiCallingInit from "~/httpApi/init";
import { HttpApiErrorCode } from "~/types";

const apiClientInstance = apiCallingInit();

export default async function memberLogin(input: {
  account: string;
  password: string;
}): Promise<{
  accessToken?: string;
  refreshToken?: string;
  errorCode?: HttpApiErrorCode;
}> {
  const { account, password } = input;

  try {
    const memberLoginResponse = await apiClientInstance.post<{
      accessToken: string;
      refreshToken: string;
    }>(`/auth/login`, {
      phone: account,
      password: password,
    });

    const { accessToken, refreshToken } = memberLoginResponse.data;
    return { accessToken, refreshToken };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{
      errorCode: string;
      message: string;
    }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error("Request faile for member login api: ", message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }
}
