import { chakra } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type IBadgeProps = PropsWithChildren<{
  backgroundColor: string;
  fontColor: string;
}>;

export default function Badge(props: IBadgeProps) {
  const { children, backgroundColor, fontColor, ...rest } = props;
  return (
    <chakra.span
      {...rest}
      className="my-badge"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.fontColor,
        height: '24px',
        lineHeight: '24px',
        padding: '2px 15px',
        borderRadius: '24px',
        fontWeight: 'bold',
      }}
    >
      {props.children}
    </chakra.span>
  );
}
