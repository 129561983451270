import { Tag } from '@chakra-ui/react';
import { StatusType } from '~/types';

const StatusTag = ({ status }: { status: StatusType }) => {
  if (status === StatusType.ACTIVATED) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="#59CFD5" alignItems="center">
        正常
      </Tag>
    );
  }
  if (status === StatusType.INACTIVE) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="#C4C4C4" alignItems="center">
        未开启
      </Tag>
    );
  }
  if (status === StatusType.LOCKED) {
    return (
      <Tag size="sm" fontSize="xs" variant="solid" bgColor="text.red" alignItems="center">
        已锁定
      </Tag>
    );
  }
  // 默认
  return (
    <Tag size="sm" fontSize="xs" variant="solid" bgColor="#C4C4C4" alignItems="center">
      {status}
    </Tag>
  );
};

export default StatusTag;
