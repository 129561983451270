import { useEffect, useState } from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IoIosArrowUp } from 'react-icons/io';

export default function BackToTopButton(btnProps?: IconButtonProps) {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition === 0 ? null : (
    <IconButton
      aria-label={btnProps?.['aria-label'] || 'back-to-top'}
      variant="outline"
      icon={<IoIosArrowUp />}
      size="md"
      fontSize={30}
      color="white"
      bgColor="#33333333"
      onClick={handleBackToTop}
      borderWidth={0}
      borderRadius={5}
      _hover={{
        bgColor: '#33333333',
      }}
      {...btnProps}
    />
  );
}
